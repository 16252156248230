import React from 'react'
import icoRu from './images/icoRu.png'
import icoEn from './images/icoEn.png'
import icoTr from './images/icoTr.png'
import instagramIco from './images/instagramIco.png'
import telegramIco from './images/telegramIco.png'
import twitterIco from './images/twitterIco.png'
import youtubeIco from './images/youtubeIco.png'
import tiktokIco from './images/tiktokIco.png'
import redditIco from './images/redditIco.png'
const socials = [
  {
    title: 'Ru',
    flag: icoRu,
    socialsIcons: [
      { link: 'https://www.instagram.com', ico: instagramIco },
      { link: 'https://t.me', ico: telegramIco },
      { link: 'https://twitter.com', ico: twitterIco },
      { link: 'https://www.youtube.com', ico: youtubeIco },
      { link: 'https://www.tiktok.com', ico: tiktokIco },
      { link: 'https://www.reddit.com/', ico: redditIco },
    ],
  },
  {
    title: 'En',
    flag: icoEn,
    socialsIcons: [
      { link: 'https://www.instagram.com', ico: instagramIco },
      { link: 'https://t.me', ico: telegramIco },
      { link: 'https://twitter.com', ico: twitterIco },
      { link: 'https://www.youtube.com', ico: youtubeIco },
      { link: 'https://www.tiktok.com', ico: tiktokIco },
      { link: 'https://www.reddit.com', ico: redditIco },
    ],
  },
]
export default function FooterSocials() {
  return (
    <div className='footer__socials socials'>
      <div className='socials__groups'>
        {socials.map((group, index) => (
          <div className='socials__group' key={index}>
            <div className='socials__header'>
              <div className='socials__location location'>
                <div className='location__media'>
                  <img src={group.flag} alt='image description' />
                </div>
                <div className='location__title'>
                  <span className='title__text'> {group.title}</span>
                </div>
              </div>
            </div>
            <div className='socials__main'>
              <div className='socials__list'>
                {group.socialsIcons.map((item, i) => (
                  <div className='socials__item' key={i}>
                    <a href={item.link} className={'socials__link'} target='_blank'>
                      <img src={item.ico} alt='image description' />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
