import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { isDevEnv, hideJivoChat } from 'helpers/helper.js'
import { BrowserRouter } from 'react-router-dom'
import './i18n'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Loader = () => (
  <div>
    <h3>Loading...</h3>
  </div>
)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  // i18n requires a <Suspense> wrapper
  <Suspense fallback={<Loader />}>
    <App />
    <ToastContainer />
  </Suspense>,
)

if (isDevEnv) hideJivoChat()
