import React, { useState, useEffect } from 'react'
import QRCode from 'react-qr-code'
import api from 'api'
import { cssClass, calculateCommission, getCommissionInfoString } from 'helpers/helper'
import { useTranslation } from 'react-i18next'

import baseComponents from '../../../../baseComponents/BaseComponents.module.scss'
import styles from './CryptoDeposit.module.scss'

const getAdapterStyleNames = (adapterCode) => {
  if (adapterCode === 'eth') return [styles.buttonGreen, styles.buttonGreenActive]
  if (adapterCode === 'trx') return [styles.buttonRed, styles.buttonRedActive]
  return [styles.buttonYellow, styles.buttonYellowActive] // bnb and others
}

export default function CryptoDeposit({ currency }) {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)
  const adapters = currency.currencyAdapters.map((_) => _.adapter)
  const [adapter, setAdapter] = useState(adapters[0] ?? {})
  const [depositInfo, setDepositInfo] = useState(null)
  console.log('CryptoDeposit', currency)

  const handleCopy = () => {
    navigator.clipboard.writeText(depositInfo?.address)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2 * 1000)
  }

  useEffect(() => {
    let ignore = false

    setDepositInfo(null)
    if (!currency || !adapter) return
    api.fetchDepositInfo(currency.code, adapter.code).then((data) => {
      console.log('fetched DepositInfo', data)
      if (!ignore) setDepositInfo(data)
    })
    return () => {
      ignore = true
    }
  }, [adapter])

  return (
    <div className={styles.block}>
      <div className={styles.body}>
        <div className={styles.infoBody}>
          <h2 className={styles.title}>{t('DepositTo', { currency: currency.code })}</h2>
          <div>
            <div className={styles.selectTitle}>{t('ChooseNetwork')}</div>
            <div className={styles.buttonBody}>
              {adapters.map((item) => {
                const [className, activeClassName] = getAdapterStyleNames(item.code)
                const isActive = item.code === adapter.code
                return (
                  <button
                    key={item.code}
                    onClick={() => setAdapter(item)}
                    className={cssClass(styles.button, className, isActive ? activeClassName : null)}
                  >
                    {item.name}
                  </button>
                )
              })}
            </div>
          </div>
          <ul className={styles.list}>
            <li className={styles.item}>{t('CryptoDepositInstructions1')}</li>
            <li className={styles.item}>{t('CryptoDepositInstructions2')}</li>
            <li className={styles.item}>{t('CryptoDepositInstructions3')}</li>
          </ul>
          <div className={styles.dangerBody}>
            <div className={styles.danger}>
              {t('CryptoDepositDanger1', { amount: depositInfo?.minAmount || '0' })} {currency.code}.
            </div>
            <div className={styles.danger}>
              {t('CryptoDepositDanger2')}
              {adapter.name} {currency.code}.
            </div>
          </div>
        </div>
        <div className={styles.qrCodeBody}>
          {depositInfo?.address && <QRCode value={depositInfo.address} size={240} className={styles.qrCode} />}
        </div>
      </div>
      <h3 className={styles.address}>{t('Address')}</h3>
      <div className={styles.inputBody}>
        <input type='text' value={depositInfo?.address || ''} className={baseComponents.input} readOnly={true} />
        <button
          onClick={handleCopy}
          className={cssClass(baseComponents.button, baseComponents.buttonWithImagePurple, styles.inputImageBlock)}
        >
          {isCopied ? 'ok' : <img src='/images/icons/copy.svg' className={styles.inputImage} />}
        </button>
      </div>
    </div>
  )
}
