import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../components/form/Checkbox/Checkbox'
import Select from '../../../../components/form/Select/Select'
import Textarea from '../../../../components/form/Textarea/Textarea'

export function Stage3({ onStage3DataSubmit, onButtonNextDisabled, stageData }) {
  const { t } = useTranslation()

  const P2PNoLimit = 'P2PNoLimit'
  const [minDaysSinceRegistrationList, setMinDaysSinceRegistrationList] = useState(
    [...Array(11).keys(), '20', '30'].map(String),
  )
  const [minOrdersRequiredList, setMinOrdersRequiredList] = useState([P2PNoLimit, '5', '10', '15', '20', '25', '30'])
  const [minOrderCompletionRateList, setMinOrderCompletionRateList] = useState([
    P2PNoLimit,
    '95',
    '90',
    '85',
    '80',
    '75',
    '70',
  ])

  const [currentStageIndex, setCurrentStageIndex] = useState(stageData.currentStageIndex)
  const [isKYCPassed, setIsKYCPassed] = useState(stageData.isKYCPassed)

  const [isMinDaysSinceRegistrationSet, setIsMinDaysSinceRegistrationSet] = useState(
    stageData.selectedMinDaysSinceRegistration !== 0,
  )
  const [selectedMinDaysSinceRegistration, setSelectedMinDaysSinceRegistration] = useState(
    stageData.selectedMinDaysSinceRegistration.toString(),
  )

  const [isMinOrdersRequiredSet, setIsMinOrdersRequiredSet] = useState(stageData.selectedMinOrdersRequired !== 0)
  const [selectedMinOrdersRequired, setSelectedMinOrdersRequired] = useState(
    stageData.selectedMinOrdersRequired === 0 ? P2PNoLimit : stageData.selectedMinOrdersRequired.toString(),
  )

  const [isMinOrderCompletionRateSet, setIsMinOrderCompletionRateSet] = useState(
    stageData.selectedMinOrderCompletionRate !== 0,
  )
  const [selectedMinOrderCompletionRate, setSelectedMinOrderCompletionRate] = useState(
    stageData.selectedMinOrderCompletionRate === 0 ? P2PNoLimit : stageData.selectedMinOrderCompletionRate.toString(),
  )

  const [termsTextarea, setTermsTextarea] = useState(stageData.termsTextarea)

  const [isInputValueChanged, setIsInputValueChanged] = useState(false)

  const handleFocus = (event) => {
    event.target.dataset.initialValue = event.target.value
  }

  const handleBlur = (event) => {
    if (event.target.dataset.initialValue !== event.target.value) {
      setIsInputValueChanged(true)
    }
    delete event.target.dataset.initialValue
  }

  const formDataToSubmit = () => {
    const minDaysReg =
      isMinDaysSinceRegistrationSet && selectedMinDaysSinceRegistration !== 0 ? selectedMinDaysSinceRegistration : 0

    const minMinOrders =
      isMinOrdersRequiredSet && selectedMinOrdersRequired !== P2PNoLimit ? selectedMinOrdersRequired : 0

    const minOrderCompletionRate =
      isMinOrderCompletionRateSet && selectedMinOrderCompletionRate !== P2PNoLimit ? selectedMinOrderCompletionRate : 0

    const dataToSubmit = {
      // currentStageIndex,
      isKYCPassed,
      selectedMinDaysSinceRegistration: minDaysReg,
      selectedMinOrdersRequired: minMinOrders,
      selectedMinOrderCompletionRate: minOrderCompletionRate,
      termsTextarea,
    }

    onStage3DataSubmit(dataToSubmit)
  }

  const checkNextButtonDisabled = () => {
    return !termsTextarea || termsTextarea === ''
  }

  useEffect(() => {
    formDataToSubmit()
  }, [
    currentStageIndex,
    isKYCPassed,
    selectedMinDaysSinceRegistration,
    selectedMinOrdersRequired,
    selectedMinOrderCompletionRate,
  ])

  useEffect(() => {
    if (!isInputValueChanged) return
    formDataToSubmit()
    setIsInputValueChanged(false)
  }, [isInputValueChanged])

  useEffect(() => {
    onButtonNextDisabled(checkNextButtonDisabled())
  }, [termsTextarea])

  return (
    <React.Fragment>
      <div className='steps__main'>
        <div className='wrapper'>
          <div className='group  group_secondary'>
            <div className='formular'>
              <div className='row align-center'>
                <div className='col col_12'>
                  <div className='form-item w-100'>
                    <div className='form-item__header secondary-margin'>
                      <span className='form-item__title'>{t('RequirementsForTheCounterparty')}</span>
                    </div>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <div className='row align-center'>
                          <div className='col col_4 col_tab-6 col_mobp-12'>
                            <Checkbox
                              id={'isKYCPassed'}
                              type={'checkbox'}
                              text={t('KYCPassed')}
                              checked={isKYCPassed}
                              checkboxCallback={() => setIsKYCPassed((prev) => !prev)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-item__field'>
                        <div className='row align-center'>
                          <div className='col col_4 col_tab-6 col_mobp-12'>
                            <Checkbox
                              id={'DaysHavePassed'}
                              type={'checkbox'}
                              text={t('DaysHavePassedSecondary')}
                              checked={isMinDaysSinceRegistrationSet}
                              checkboxCallback={() =>
                                setIsMinDaysSinceRegistrationSet((prev) => {
                                  if (prev) {
                                    setSelectedMinDaysSinceRegistration(0)
                                  } else {
                                    setSelectedMinDaysSinceRegistration(minDaysSinceRegistrationList[1])
                                  }
                                  return !prev
                                })
                              }
                            />
                          </div>
                          <div className='col col_2 col_tab-6'>
                            <Select
                              list={minDaysSinceRegistrationList.map((key) => t('P2PDays', { days: key }))}
                              value={t('P2PDays', { days: selectedMinDaysSinceRegistration })}
                              addClasses={['w-100']}
                              filter={true}
                              multiple={false}
                              selectCallback={(value) => {
                                const days = parseInt(value, 10)
                                if (!isNaN(days)) {
                                  setSelectedMinDaysSinceRegistration(days)
                                  setIsMinDaysSinceRegistrationSet(days !== 0)
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-item__field'>
                        <div className='row align-center'>
                          <div className='col col_4 col_tab-6 col_mobp-12'>
                            <Checkbox
                              id={'30DayCompletion'}
                              type={'checkbox'}
                              text={t('ExecutedDealsWithin30Days')}
                              checked={isMinOrdersRequiredSet}
                              checkboxCallback={() =>
                                setIsMinOrdersRequiredSet((prev) => {
                                  if (prev) {
                                    setSelectedMinOrdersRequired(P2PNoLimit)
                                  } else {
                                    setSelectedMinOrdersRequired(minOrdersRequiredList[1])
                                  }
                                  return !prev
                                })
                              }
                            />
                          </div>
                          <div className='col col_2 col_tab-6'>
                            <Select
                              list={minOrdersRequiredList}
                              value={selectedMinOrdersRequired || t(P2PNoLimit)}
                              addClasses={['w-100']}
                              filter={true}
                              multiple={false}
                              selectCallback={(value) => {
                                setSelectedMinOrdersRequired(value)
                                setIsMinOrdersRequiredSet(value !== P2PNoLimit)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-item__field'>
                        <div className='row align-center'>
                          <div className='col col_4 col_tab-6 col_mobp-12'>
                            <Checkbox
                              id={'30DayCompletion'}
                              type={'checkbox'}
                              text={t('ExecutedDealsFor', { days: 30 })}
                              checked={isMinOrderCompletionRateSet}
                              checkboxCallback={() =>
                                setIsMinOrderCompletionRateSet((prev) => {
                                  if (prev) {
                                    setSelectedMinOrderCompletionRate(P2PNoLimit)
                                  } else {
                                    setSelectedMinOrderCompletionRate(minOrderCompletionRateList[1])
                                  }
                                  return !prev
                                })
                              }
                            />
                          </div>
                          <div className='col col_2 col_tab-6'>
                            <Select
                              list={minOrderCompletionRateList}
                              value={selectedMinOrderCompletionRate || t(P2PNoLimit)}
                              addClasses={['w-100']}
                              filter={true}
                              multiple={false}
                              selectCallback={(value) => {
                                setSelectedMinOrderCompletionRate(value)
                                setIsMinOrderCompletionRateSet(value !== P2PNoLimit)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='group'>
            <div className='formular'>
              <div className='row'>
                <div className='col col_12'>
                  <div className='form-item w-100'>
                    <div className='form-item__header'>
                      <span className='form-item__title'>{t('TermsOfTheTransactionRequired')}</span>
                    </div>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Textarea
                          id={'TermsOfTheTransactionRequired'}
                          name={'TermsOfTheTransactionRequired'}
                          value={termsTextarea}
                          setValue={setTermsTextarea}
                          addClasses={['w-100']}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
