import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import api from 'api'
import moment from 'moment/moment'
import { useAuth } from 'auth/auth'
const signalR = require('@microsoft/signalr')
import {
  APPEAL_STATUS,
  APPEAL_TYPES,
  DEAL_STATUS,
  DealStatusesActive,
  DealStatusNumberToTextMap,
  GetAppealType,
  GetDealAppealStatus,
  TabIndex,
} from '../Order/OrderAndDealData'
import { TextMapForInfo, TextMapForAppealInfo } from '../Progress/ProgressData'

let ico = (
  <svg
    className='preview__ico'
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='16'
    viewBox='0 0 14 16'
    fill='none'
  >
    <path d='M13.5652 11.0706C13.0429 10.6773 12.6232 10.1868 12.3355 9.63352C12.0478 9.08023 11.899 8.4775 11.8996 7.8675V6.125C11.8996 3.92562 10.0694 2.105 7.69877 1.8V1.125C7.69877 0.95924 7.625 0.800269 7.4937 0.683058C7.3624 0.565848 7.18432 0.5 6.99863 0.5C6.81294 0.5 6.63486 0.565848 6.50356 0.683058C6.37226 0.800269 6.29849 0.95924 6.29849 1.125V1.8C3.92713 2.105 2.09767 3.92562 2.09767 6.125V7.8675C2.09794 8.47873 1.94842 9.08262 1.6596 9.63681C1.37078 10.191 0.949651 10.6821 0.425741 11.0756C0.236396 11.2213 0.10144 11.4152 0.0389469 11.6313C-0.0235461 11.8473 -0.0105929 12.0753 0.0760719 12.2846C0.162737 12.4939 0.318975 12.6746 0.523865 12.8025C0.728755 12.9303 0.972513 12.9992 1.2225 13H12.7748C13.4504 13 14 12.5094 14 11.9062C14 11.5863 13.8439 11.2838 13.5652 11.0706ZM6.99863 15.5C7.60356 15.4993 8.18971 15.3124 8.65828 14.9708C9.12684 14.6293 9.44917 14.154 9.57093 13.625H4.42632C4.54808 14.154 4.87041 14.6293 5.33898 14.9708C5.80755 15.3124 6.3937 15.4993 6.99863 15.5Z' />
  </svg>
)

export default function P2pNotification() {
  const { t } = useTranslation()
  const auth = useAuth()
  const [signalRTimerId, setSignalRTimerId] = useState(null)
  const history = useHistory()
  const goToOrder = () => {
    history.push({
      pathname: `/${i18n.language}/P2P/orders`,
      state: { shouldUpdate: true },
    })
  }

  const [texts, setTexts] = useState(['', ''])
  const [lastActiveDeal, setLastActiveDeal] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)

  const getInfoText = () => {
    const direction = lastActiveDeal.isDirectionDealBuying ? 'buying' : 'selling'

    const isAppeal =
      lastActiveDeal.status === DEAL_STATUS.AppealedAfterFinish ||
      lastActiveDeal.status === DEAL_STATUS.AppealedForActiveDeal

    const info = isAppeal
      ? TextMapForAppealInfo[direction][lastActiveDeal.status]
      : TextMapForInfo[direction][lastActiveDeal.status]

    return info
  }

  const fetchP2PUserDealsAll = async () => {
    try {
      const deals = await api.fetchP2PUserDealsAll(DealStatusesActive)

      let lastActiveDeal = null
      if (deals.length > 0) {
        const lastDeal = deals.reduce(
          (latest, deal) => (moment(deal.dateCreated).isAfter(moment(latest.dateCreated)) ? deal : latest),
          deals[0],
        )
        if (!lastDeal.dealAppeals || lastDeal.dealAppeals.length === 0) {
          lastActiveDeal = lastDeal
        } else {
          const lastAppeal = lastDeal.dealAppeals
            .filter((appeal) => appeal.status === APPEAL_STATUS.InProgress)
            .sort((a, b) => new Date(b.DateCreated) - new Date(a.DateCreated))[0]

          lastActiveDeal = lastAppeal ? getDealWithAppeal(lastAppeal, lastDeal) : lastDeal
        }
      } else {
        const userAppeals = await api.fetchP2PUserAppeals(APPEAL_STATUS.InProgress)
        if (userAppeals.length > 0) {
          const appeal = userAppeals[0]

          lastActiveDeal = getDealWithAppeal(appeal, appeal.deal)
        }
      }
      setLastActiveDeal(lastActiveDeal)
    } catch (error) {
      console.error('Error fetch deals', error)
    }
  }

  const getDealWithAppeal = (appeal, deal) => {
    const dealWithAppeal = deal
    dealWithAppeal.isAppealed = appeal.appealType === APPEAL_TYPES.appealedForActiveDeal
    dealWithAppeal.isAppealedAfterFinish = appeal.appealType === APPEAL_TYPES.appealedAfterFinish
    dealWithAppeal.status = GetDealAppealStatus(deal)
    return dealWithAppeal
  }

  const configureSignalR = () => {
    const { token_type, access_token } = auth.token || {}
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${api.ApiUrl}/sr/p2p`, { accessTokenFactory: () => access_token })
      .configureLogging(signalR.LogLevel.Information)
      .build()

    hubConnection
      .start({ transport: 'longPolling' })
      .then(() => {
        hubConnection.on('P2PDeal', (data) => {
          if (!location.pathname.toLowerCase().includes('/p2p')) {
            disconnectSignalR() // signalR has stuck, disconnect
            console.log('P2pNotification SignalR has stuck, disconnect')
            return
          }
          fetchP2PUserDealsAll()
        })
        hubConnection.on('P2PChat', () => {})
        hubConnection.on('P2PSellerDealCancelDispute', () => {})

        const keepAlivePeriod = 60 * 1000 // 1 minute
        const timerId = setInterval(function () {
          hubConnection.invoke('KeepAlive', '').catch((err) => console.log('KeepAlive Error: ' + err))
        }, keepAlivePeriod)
        setSignalRTimerId(timerId)
      })
      .catch((err) => {
        console.log('configureSignalR error', err)
      })
  }

  const disconnectSignalR = () => {
    if (signalRTimerId) {
      clearInterval(signalRTimerId)
      setSignalRTimerId(null)
    }
  }

  useEffect(() => {
    fetchP2PUserDealsAll()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      configureSignalR()
    }, 2000)

    return () => {
      disconnectSignalR()
    }
  }, [])

  useEffect(() => {
    if (!lastActiveDeal) return
    let text = getInfoText()
    setTexts(text)
  }, [lastActiveDeal])

  return (
    <div
      className={`p2p__notification notification ${isExpanded ? 'p2p__notification_active' : ''}`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className={lastActiveDeal ? 'notification__preview preview active' : 'notification__preview preview'}>
        <div className={`preview__media ${lastActiveDeal ? 'preview__media--blink' : ''}`}>{ico}</div>
        <div className='preview__title'>
          <span className='preview__text'>{t('P2PNewNotification')}</span>
        </div>
      </div>
      <div className='notification__layout'>
        <div className='notification__header'>
          <div className='wysiwyg'>
            <h3>{t('P2PPendingTasks')}</h3>
          </div>
        </div>
        <div className='notification__main'>
          <div className='notification__list'>
            {lastActiveDeal && texts[0] ? (
              <div className='notification__item' onClick={() => goToOrder()}>
                <span
                  className={`notification__title ${
                    lastActiveDeal?.isDirectionDealBuying ? 'notification__title___buy' : 'notification__title__sell'
                  }`}
                >
                  {t(`P2PDealStatus_${DealStatusNumberToTextMap[lastActiveDeal?.status]}`)}
                </span>
                <span className='notification__text'>{t(texts[0])}</span>
                {/* <span className='notification__text'>{texts[1] != '' && t(texts[1])}</span> */}
              </div>
            ) : (
              <div className='notification__item' onClick={() => goToOrder()}>
                <span> {t(`P2PNoTasks`)} </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
