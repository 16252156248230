import { ProgressTimer } from './ProgressTimer'
import ProgressIcons from './ProgressIcons'
import { TextMapForInfo } from './ProgressData'
import React, { useEffect, useState } from 'react'
import { APPEAL_TYPES, DEAL_STATUS, GetAppealType } from '../Order/OrderAndDealData'
import ProgressAppealInfo from './ProgressInfoAppeal'
import { de } from 'date-fns/locale'

const ProgressInfo = ({ t, deal, onProgressInfo, isDisabledBtnCancel }) => {
  const [texts, setTexts] = useState(['', ''])
  const [showProgressAppealInfo, setShowProgressAppealInfo] = useState(false)
  const { dateCreated, status } = deal || {}
  const { maxTimeMinutes } = deal.order || {}

  const isHidden = status === DEAL_STATUS.Canceled || status === DEAL_STATUS.CanceledCryptoUnfrozen
  const infoIconName =
    status === DEAL_STATUS.Canceled || status === DEAL_STATUS.CanceledCryptoUnfrozen ? 'infoCanceledIco' : 'infoIco'

  const getInfoText = () => {
    const direction = deal.isDirectionDealBuying ? 'buying' : 'selling'
    const info = TextMapForInfo[direction][deal.status]
    return info || ['', '']
  }

  useEffect(() => {
    if (deal.isAppealed || deal.isAppealedAfterFinish) {
      setShowProgressAppealInfo(true)
    } else {
      setShowProgressAppealInfo(false)
      setTexts(getInfoText())
    }
  }, [deal])

  if (showProgressAppealInfo) {
    return (
      <ProgressAppealInfo
        t={t}
        deal={deal}
        isDisabledBtnCancel={isDisabledBtnCancel}
        onProgressAppealInfo={(actionType, payload) => onProgressInfo(actionType, payload)}
      />
    )
  }

  return (
    <React.Fragment>
      {texts[0] === '' ? null : (
        <div className='wrapper'>
          <div className='p2p__info info'>
            <div className='info__media'>
              <ProgressIcons name={infoIconName} />
            </div>
            <div className='info__main'>
              <div className='info__title title'>
                <span className='title__text'>{t(texts[0])}</span>
              </div>
              {texts[1] !== '' && <span className='info__subtitle'>{t(texts[1])}</span>}
            </div>
            {!isHidden && <ProgressTimer initialMinutes={maxTimeMinutes} dateCreated={dateCreated} />}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default ProgressInfo
