import React from 'react'
import logo from './images/logo.png'
import { NavLink } from 'react-router-dom'
export default function FooterLogo() {
  return (
    <div className='footer__logo logo'>
      <NavLink to={'/'} className='logo__link'>
      {/*<img src={logo} alt='image description' />*/}
      </NavLink>
    </div>
  )
}
