import { cssClass } from 'helpers/helper'

import styles from './TableItem.module.scss'
import balance from '../Balance/Balance.module.scss'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'

export default function TableItem({ currency, selected, children }) {
  const rowStyle = selected
    ? cssClass(balance.tableRow, balance.tableCurrencyRow, styles.active)
    : cssClass(balance.tableRow, balance.tableCurrencyRow)

  const digits = currency.digits
  return (
    <>
      <li className={rowStyle}>
        <div className={styles.itemImgBlock}>
          <img className={styles.arrows} src={'/images/icons/arrows.svg'} />
          <CurrencyIcon currencyCode={currency.code} size={30} className={styles.img} />
          <div>
            <div className={styles.itemTitle}>{currency.code}</div>
            <div className={styles.itemDescription}>{currency.value}</div>
          </div>
        </div>
        <span className={cssClass(styles.data, balance.phoneHidden)}>{currency.total.toFixed(digits)}</span>
        <span className={styles.data}>{currency.free.toFixed(digits)}</span>
        <span className={cssClass(styles.data, balance.phoneVertHidden)}>{currency.blocked.toFixed(digits)}</span>
        <div className={styles.depositWithdrawBlock}>{children}</div>
      </li>
    </>
  )
}
