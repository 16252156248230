import { SYSTEM_MESSAGE_TYPE, SystemMessageTypeToText } from 'pages/p2p/Order/OrderAndDealData'
import { TextMapForSystemMessageMegaphone, TextMapForWarningMessage } from '../ProgressData'
import ProgressIcons from '../ProgressIcons'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'

const SystemMessageMegaphone = ({ t, chatMessage, deal }) => {
  const systemMessageType = SystemMessageTypeToText[chatMessage.systemMessageType]
  if (systemMessageType !== SYSTEM_MESSAGE_TYPE.MEGAPHONE) {
    return null
  }
  if (
    (deal.isDirectionDealBuying && chatMessage.isDealCreator) ||
    (!deal.isDirectionDealBuying && chatMessage.isOrderCreator)
  ) {
    return null
  }

  return (
    <React.Fragment>
      <div className='chat__tooltip chat__tooltip_primary tooltip'>
        <div className='tooltip__media'>
          <ProgressIcons name='megaphone' />
        </div>
        <div className='tooltip__main wysiwyg'>
          <p>{t(chatMessage.text)}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

const SystemMessageWarning = ({ t, chatMessage, deal }) => {
  const systemMessageType = SystemMessageTypeToText[chatMessage.systemMessageType]
  if (systemMessageType !== SYSTEM_MESSAGE_TYPE.WARNING || !deal.isDealCreator) {
    return null
  }

  return (
    <React.Fragment>
      <div className='chat__tooltip chat__tooltip_secondary tooltip'>
        <div className='tooltip__media'>
          <ProgressIcons name='exclamationAppealSmallChatIco' />
        </div>
        <div className='tooltip__main wysiwyg'>
          <p>{t(chatMessage.text)}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export { SystemMessageMegaphone, SystemMessageWarning }
