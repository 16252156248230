import React, { useState } from 'react'
import Logo from './Logo/Logo'
import Navigation from './Navigation/Navigation'
import Languages from './Languages/Languages'
import Account from './Account/Account'
export default function Hedear() {
  const [status, setStatus] = useState(false)
  return (
    <header className={`layout__header header ${status ? 'layout__header_menu-active' : ''}`}>
      <div className='container'>
        <Logo />
        <div onClick={() => setStatus(!status)} className='header__burger burger'>
          <div className='burger__item'></div>
          <div className='burger__item'></div>
          <div className='burger__item'></div>
        </div>
        <div className='header__layout'>
          <Navigation />
          <Languages />
          <Account />
        </div>
      </div>
    </header>
  )
}
