const SecurityIcons = ({ name }) => {
  switch (name) {
    case 'copyIcon':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <rect x='9' y='9' width='13' height='13' rx='2' ry='2'></rect>
          <path d='M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1'></path>
        </svg>
      )
    case 'ico01':
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none'>
          <path
            d='M0.5 6C0.5 9.30662 3.19338 12 6.5 12C9.80662 12 12.5 9.30662 12.5 6C12.5 2.69338 9.80662 0 6.5 0C3.19338 0 0.5 2.69338 0.5 6ZM9.63329 5.13329L5.83333 8.94671L3.36671 6.46667L4.3 5.53338L5.83333 7.05329L8.70004 4.2L9.63329 5.13329Z'
            fill='#00D09E'
          />
        </svg>
      )
    case 'ico02':
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none'>
          <path
            d='M6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 9.31371 3.18629 12 6.5 12C9.81371 12 12.5 9.31371 12.5 6C12.5 2.68629 9.81371 0 6.5 0Z'
            fill='#253920'
            fillOpacity='0.5'
          />
          <path
            d='M6.49992 7.99935C6.03968 7.99935 5.66659 8.37244 5.66659 8.83268C5.66659 9.29292 6.03968 9.66602 6.49992 9.66602C6.96016 9.66602 7.33325 9.29292 7.33325 8.83268C7.33325 8.37244 6.96016 7.99935 6.49992 7.99935Z'
            fill='white'
          />
          <path d='M7.16663 7H5.83329V2.33333H7.16663V7Z' fill='white' />
        </svg>
      )
  }
}
export default SecurityIcons
