import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import Modal from 'components/layout/Modal/Modal'
import Checkbox from 'components/form/Checkbox/Checkbox'
import { DEAL_CANCEL_DISPUTE_STATUS } from '../ProgressData'
import Input from 'components/form/Input/Input'

export default function DealCancelDisputeModal({ onConfirmDealCancelDisputeModal, deal, opened, onClose }) {
  const { t } = useTranslation()

  const [selectedCheckbox, setSelectedCheckbox] = useState(null)
  const [status, setStatus] = useState(null)
  const [cause, setCause] = useState(null)
  const [customCauseText, setCustomCauseText] = useState(null)

  const handleCheckboxCreatorAdmitsFault = (cause) => {
    setStatus(DEAL_CANCEL_DISPUTE_STATUS.CreatorAdmitsFault)
    setCause(cause)
  }
  const handleCheckboxCreatorDeniesFault = (cause) => {
    setStatus(DEAL_CANCEL_DISPUTE_STATUS.CreatorDeniesFault)
    setCause(cause)
  }

  const handleConfirm = () => {
    const model = {
      dealId: deal.id,
      cause,
      status,
    }
    onConfirmDealCancelDisputeModal('openCancellationDispute', model)
  }

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('P2PDealDisputeTitle')}</h2>
        </div>
        <div>1. {t('P2PDealDisputeDescription1IfYouHaveAlreadyMade')}</div>
        <div>2. {t('P2PDealDisputeDescription2IfYouCancelOrders')}</div>
        <br />
        <div>
          <strong>{t('P2PDealDisputeBuyerIssue')}</strong>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='formular'>
            <div className='form-item w-100'>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <Checkbox
                    type={'radio'}
                    text={t('P2PDealDisputeBuyerCause1INoLongerWish')}
                    checkboxCallback={() => {
                      handleCheckboxCreatorAdmitsFault('P2PDealDisputeBuyerCause1INoLongerWish')
                      setSelectedCheckbox('P2PDealDisputeBuyerCause1INoLongerWish')
                    }}
                    checked={selectedCheckbox === 'P2PDealDisputeBuyerCause1INoLongerWish'}
                  />
                  <Checkbox
                    type={'radio'}
                    text={t('P2PDealDisputeBuyerCause2IDidntMeetTheCounterparty')}
                    checkboxCallback={() => {
                      handleCheckboxCreatorAdmitsFault('P2PDealDisputeBuyerCause2IDidntMeetTheCounterparty')
                      setSelectedCheckbox('P2PDealDisputeBuyerCause2IDidntMeetTheCounterparty')
                    }}
                    checked={selectedCheckbox === 'P2PDealDisputeBuyerCause2IDidntMeetTheCounterparty'}
                  />
                  <Checkbox
                    type={'radio'}
                    text={t('P2PDealDisputeBuyerCause2IDidntKnowHowToMake')}
                    checkboxCallback={() => {
                      handleCheckboxCreatorAdmitsFault('P2PDealDisputeBuyerCause2IDidntKnowHowToMake')
                      setSelectedCheckbox('P2PDealDisputeBuyerCause2IDidntKnowHowToMake')
                    }}
                    checked={selectedCheckbox === 'P2PDealDisputeBuyerCause2IDidntKnowHowToMake'}
                  />
                  <Checkbox
                    type={'radio'}
                    text={t('P2PDealDisputeBuyerCause4Other')}
                    checkboxCallback={() => {
                      handleCheckboxCreatorAdmitsFault('P2PDealDisputeBuyerCause4Other')
                      setSelectedCheckbox('P2PDealDisputeBuyerCause4Other')
                    }}
                    checked={selectedCheckbox === 'P2PDealDisputeBuyerCause4Other'}
                  />
                  {selectedCheckbox === 'P2PDealDisputeBuyerCause4Other' && (
                    <div>
                      <Input
                        placeholder={t('P2PPleaseSpecify(Optional)')}
                        type='text'
                        value={customCauseText || ''}
                        inputCallback={(value) => {
                          const cause = value || value !== '' ? value : 'P2PDealDisputeBuyerCause4Other'
                          handleCheckboxCreatorAdmitsFault(cause)
                          setCustomCauseText(value)
                        }}
                        addClasses={['w-100']}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <strong>{t('P2PDealDisputeSellerIssue')}</strong>
        </div>
        <br />
        <div>{t('P2PDealDisputeSellerDescription1YourCompletionRate')}</div>
        <div>{t('P2PDealDisputeSellerDescription2UpTo3DailyCancellations')}</div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='formular'>
            <div className='form-item w-100'>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <Checkbox
                    type={'radio'}
                    text={t('P2PDealDisputeSellerCause1ThePaymentFailed')}
                    checkboxCallback={() => {
                      handleCheckboxCreatorDeniesFault('P2PDealDisputeSellerCause1ThePaymentFailed')
                      setSelectedCheckbox('P2PDealDisputeSellerCause1ThePaymentFailed')
                    }}
                    checked={selectedCheckbox === 'P2PDealDisputeSellerCause1ThePaymentFailed'}
                  />
                  <Checkbox
                    type={'radio'}
                    text={t('P2PDealDisputeSellerCause2TheCounterpartyTended')}
                    checkboxCallback={() => {
                      handleCheckboxCreatorDeniesFault('P2PDealDisputeSellerCause2TheCounterpartyTended')
                      setSelectedCheckbox('P2PDealDisputeSellerCause2TheCounterpartyTended')
                    }}
                    checked={selectedCheckbox === 'P2PDealDisputeSellerCause2TheCounterpartyTended'}
                  />
                  <Checkbox
                    type={'radio'}
                    text={t('P2PDealDisputeSellerCause3TheSellerRequested')}
                    checkboxCallback={() => {
                      handleCheckboxCreatorDeniesFault('P2PDealDisputeSellerCause3TheSellerRequested')
                      setSelectedCheckbox('P2PDealDisputeSellerCause3TheSellerRequested')
                    }}
                    checked={selectedCheckbox === 'P2PDealDisputeSellerCause3TheSellerRequested'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal__footer'>
          <div className='formular'>
            <div className='row'>
              <div className='col col_6'>
                <div className='form-item'>
                  <div className='form-item__main'>
                    <div className='form-item__field'>
                      <button
                        className='btn btn_revert w-100'
                        disabled={!selectedCheckbox}
                        onClick={() => handleConfirm()}
                      >
                        <span className='btn__text'>{t('Confirm')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col col_6'>
                <div className='form-item' onClick={() => onClose()}>
                  <div className='form-item__main'>
                    <div className='form-item__field'>
                      <button className='btn w-100'>
                        <span className='btn__text'>{t('Cancel')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
