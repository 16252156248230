import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/form/Button/Button'
import api from 'api'
import Google2FaSettingsModal from './Google2FaSettingsModal'
import SecurityIcons from './SecurityIcons'
import { useVerificationCheck } from '../User/useVerificationCheck'

export default function Security() {
  const { t } = useTranslation()
  const { handleVerificationCheck, goToVerificationPage } = useVerificationCheck()
  const [isGoogle2FAModalOpen, setIsGoogle2FAModalOpen] = useState(false)
  const [isTwoFaEnabled, setIsTwoFaEnabled] = useState(null)
  const [timer, setTimer] = useState(0)

  const checkGoogle2FaStatus = async () => {
    try {
      const result = await api.fetchGoogleAuthStatus()
      setIsTwoFaEnabled(result)
    } catch (error) {
      console.log('checkGoogle2FaStatus error:', error)
    }
  }

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [timer])

  useEffect(() => {
    checkGoogle2FaStatus()
  }, [])

  return (
    <React.Fragment>
      <div className='p2p__security'>
        <h1 className='title'>{t('Security')}</h1>
        <section className='block_wrapper'>
          <div className='text_wrapper'>
            <span className='subtitle'>{t('P2PGoogleAuthentication')}</span>
            <div className='description'>{t('P2PToLoginWithdrawFunds')}</div>
          </div>

          {isTwoFaEnabled ? (
            <div className='status_enabled'>
              <span>
                <SecurityIcons name='ico01' />
              </span>
              <span> {t('P2P2FAEnabled')}</span>
            </div>
          ) : (
            <div className='status_disabled'>
              <span>
                <SecurityIcons name='ico02' />
              </span>
              <span> {t('P2P2FANotConfigured')}</span>
            </div>
          )}

          <div className='layout'>
            <div className='status_wrapper'>
              <div className='button_wrapper'>
                <Button
                  buttonCallback={() => setIsGoogle2FAModalOpen(true)}
                  addClasses={['btn btn_secondary-padding']}
                  text={t(isTwoFaEnabled ? 'P2PDisable' : 'P2PEnable')}
                  disabled={isTwoFaEnabled === null}
                />
              </div>
            </div>
          </div>
        </section>
        <section className='block_wrapper'>
          <div className='text_wrapper'>
            <span className='subtitle'>{t('Verification')}</span>
            <div className='description'>{t('BasicVerification')}</div>
          </div>
          <div className='layout'>
            <div className='status_wrapper'>
              <div className='button_wrapper'>
                <Button
                  buttonCallback={() => goToVerificationPage()}
                  addClasses={['btn btn_secondary-padding']}
                  text={t(!handleVerificationCheck() ? 'PassVerification' : 'KYCPassed')}
                  disabled={handleVerificationCheck()}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      {isGoogle2FAModalOpen && (
        <Google2FaSettingsModal
          t={t}
          opened={isGoogle2FAModalOpen}
          setOpened={setIsGoogle2FAModalOpen}
          isTwoFaEnabled={isTwoFaEnabled}
          onTwoFAUpdate={checkGoogle2FaStatus}
          timer={timer}
          setTimer={setTimer}
        />
      )}
    </React.Fragment>
  )
}
