import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'auth/auth'
import authApi from 'auth/api'

export function useLanguage() {
  const { t, i18n } = useTranslation()
  const auth = useAuth()

  function checkLanguage(oldLanguage) {
    if (window.location.pathname.indexOf(i18n.language) === -1) {
      const newUrl = window.location.href.replace(oldLanguage, i18n.language)
      window.location.href = newUrl
    }
  }

  const changeLanguage = (newLang) => {
    let language = newLang.key
    const oldLanguage = i18n.language
    i18n.changeLanguage(language).then(() => {
      checkLanguage(oldLanguage)
    })
    if (!auth.isSignedIn) return
    authApi.updateLanguage(language).then((res) => {})
  }

  return {
    changeLanguage,
  }
}
