import React, { useState } from 'react'
const Textarea = (props) => {
  const [length, setLength] = useState(0)
  const handleChange = (e) => {
    const newValue = e.target.value.slice(0, 200)
    props.setValue(newValue)
    setLength(newValue.length)
  }

  const handleFocus = (e) => {
    if (props.onFocus) {
      props.onFocus(e)
    }
  }

  const handleBlur = (e) => {
    if (props.onBlur) {
      props.onBlur(e)
    }
  }
  return (
    <React.Fragment>
      <div className='textarea'>
        <textarea
          className={props.addClasses}
          name={props.name}
          id={props.id}
          value={props.value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        ></textarea>

        <span className='textarea__progress'>{length}/200</span>
      </div>
    </React.Fragment>
  )
}
export default Textarea
