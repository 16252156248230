export const useVerificationCheck = () => {
  const handleVerificationCheck = () => {
    return window.isUserVerified ? true : false
  }

  const goToVerificationPage = () => {
    delete window.isUserVerified
    window.location.href = 'https://id.h2k.me/security'
  }

  return {
    handleVerificationCheck,
    goToVerificationPage,
  }
}
