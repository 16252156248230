import React, { useState, useEffect } from 'react'
import api from 'api'

const CurrencyIcon = ({ currencyCode, size = 24, forceReload = false, className = '' }) => {
  const [imageStatus, setImageStatus] = useState('loading')

  const getCacheBreakingUrl = () => {
    if (!currencyCode) return
    const baseUrl = `${api.ApiUrl}/api/currencies/icons/${currencyCode}`
    return forceReload ? `${baseUrl}?t=${new Date().getTime()}` : baseUrl
  }

  const iconUrl = getCacheBreakingUrl()

  useEffect(() => {
    setImageStatus('loading')
    const img = new Image()
    img.onload = () => setImageStatus('loaded')
    img.onerror = () => setImageStatus('error')
    img.src = iconUrl

    return () => {
      img.onload = null
      img.onerror = null
    }
  }, [iconUrl])

  if (!iconUrl) return null

  return (
    <img
      className={className}
      src={iconUrl}
      alt={`${currencyCode} icon`}
      onLoad={() => setImageStatus('loaded')}
      onError={() => setImageStatus('error')}
      style={{ borderRadius: '50%' }}
      width={size}
      height={size}
    />
  )
}

export default React.memo(CurrencyIcon)
