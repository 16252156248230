import { useState } from 'react'
import api from 'api'

const useGoogle2FA = () => {
  const [isLoading, setIsLoading] = useState(false)

  const sendCodeToEmail = async () => {
    setIsLoading(true)
    try {
      await api.sendCodeToEmail()
    } catch (error) {
      console.log('sendCodeToEmail error:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const validateEmailCode = async (emailCode) => {
    setIsLoading(true)

    try {
      await api.validateEmailCode(emailCode, {
        headers: { 'Content-Type': 'application/json' },
      })
      return true
    } catch (error) {
      console.log('fetch2FACode error:', error)
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPreSharedCode = async (codeFromEmail) => {
    if (!codeFromEmail || codeFromEmail.length !== 6) return
    setIsLoading(true)

    const updateG2FaStatusDto = {
      emailCode: codeFromEmail,
    }
    try {
      const preShared = await api.fetchGoogle2FACode(updateG2FaStatusDto)
      return preShared
    } catch (error) {
      console.log('fetch2FACode error:', error)
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const enable2FA = (codeFromEmail, totpCode) => updateGoogleAuthStatus(codeFromEmail, totpCode, 'enable')
  const disable2FA = (codeFromEmail, totpCode) => updateGoogleAuthStatus(codeFromEmail, totpCode, 'disable')

  const updateGoogleAuthStatus = async (codeFromEmail, totpCode, action) => {
    if (!totpCode || totpCode.length !== 6) {
      return
    }
    setIsLoading(true)
    try {
      const updateG2FaStatusDto = {
        totpCode,
        setActive: action === 'enable',
        emailCode: codeFromEmail,
      }
      await api.updateGoogleAuthStatus(updateG2FaStatusDto)
      return true
    } catch (error) {
      console.log(`${action}2FA error:`, error)
      return false
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    sendCodeToEmail,
    validateEmailCode,
    fetchPreSharedCode,
    enable2FA,
    disable2FA,
  }
}

export default useGoogle2FA
