import React from 'react'
import { useTranslation } from 'react-i18next'
import Security from './Security'
import Main from 'pages/common/components/Main/Main.jsx'
import Sidebar from 'baseComponents/Sidebar/Sidebar.jsx'
import styles from '../../pages.module.scss'

export default function SecurityPage() {
  const { t } = useTranslation()
  return (
    <Main>
      <div className={styles.flow}>
        <h2 className={styles.title}>{t('PersonalCabinet')}</h2>
        <div className={styles.wrapper}>
          <Sidebar />
          <div className={styles.content}>
            <Security />
          </div>
        </div>
      </div>
    </Main>
  )
}
