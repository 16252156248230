import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import api from 'api'
import { cssClass, calculateCommission, getCommissionInfoString } from 'helpers/helper'
import CommissionTypes from 'data/CommissionTypes'
import WithdrawalTypeCodes from 'data/WithdrawalTypeCodes'
import styles from './CryptoWithdrawal.module.scss'
import baseComponents from '../../../../baseComponents/BaseComponents.module.scss'
import i18n from 'i18next'
import useModalState from 'pages/p2p/Progress/Modals/useModalState'
import { useVerificationCheck } from 'pages/p2p/User/useVerificationCheck'
import PassVerificationModal from 'pages/p2p/Home/PassVerificationModal'

const getAdapterStyleNames = (adapterCode) => {
  if (adapterCode === 'eth') return [styles.buttonGreen, styles.buttonGreenActive]
  if (adapterCode === 'trx') return [styles.buttonRed, styles.buttonRedActive]
  return [styles.buttonYellow, styles.buttonYellowActive] // bnb and others
}

export default function CryptoWithdrawal({ currency, freeBalance, loadBalance, close }) {
  const { t } = useTranslation()
  const history = useHistory()

  const { modals, openModal, closeModal } = useModalState()
  const { handleVerificationCheck, goToVerificationPage } = useVerificationCheck()

  const adapters = currency.currencyAdapters.map((_) => _.adapter)
  const [adapter, setAdapter] = useState(adapters[0] ?? {})
  const [commissionEstimate, setCommissionEstimate] = useState(null)
  const [amount, setAmount] = useState(null)
  const [address, setAddress] = useState('')
  const [publicID, setPublicID] = useState('')
  const [emailCode, setEmailCode] = useState('')
  const [google2FaCode, setGoogle2FaCode] = useState('')
  const [isEmailBtnDisabled, setEmailDisabled] = useState(false)
  const [commissions, setCommissions] = useState([])
  const [isTwoFaEnabledForUser, setIsTwoFaEnabledForUser] = useState(null)
  const [isTwoFaEnabledForWithdrawal, setIsTwoFaEnabledForWithdrawal] = useState(null)
  const [inputEmailError, setInputEmailError] = useState(false)
  const [inputGoogle2FaError, setInputGoogle2FaError] = useState(false)

  useEffect(() => {
    let ignore = false
    api.fetchCommissions().then((data) => {
      if (ignore) return
      setCommissions(data?.commissions)
    })
    return () => {
      ignore = true
    }
  }, [])

  const [timer, setTimer] = useState(null) // delay commission call
  useEffect(() => {
    let ignore = false
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setCommissionEstimate(null)
    if (!amount) return

    api
      .estimateWithdrawalCommission(amount, currency.code, adapter.code)
      .then((data) => {
        //if (ignore) return
        setCommissionEstimate(data)
      })
      .catch((e) => {
        console.log('estimateWithdrawalCommission error', e)
        setCommissionEstimate(null)
      })

    return () => {
      ignore = true
    }
  }, [amount])

  const withdraw = () => {
    if (!handleVerificationCheck()) {
      openModal('isPassVerificationModalOpen')
      return
    }

    if (!amount || amount <= 0 || address.length < 20 || !emailCode || (isTwoFaEnabledForWithdrawal && !google2FaCode))
      return
    api
      .createWithdrawal({
        withdrawalType: WithdrawalTypeCodes.Crypto,
        address,
        amount,
        currencyCode: currency.code,
        adapterCode: adapter.code,
        authCode: isTwoFaEnabledForWithdrawal ? google2FaCode : null,
        emailAuthCode: emailCode,
      })
      .then((data) => {
        toast.success(t('CreateWithdrawal_Success'))
        console.log('withdrawal done', data)
        loadBalance()
        setAmount(null)
        setAddress('')
        setCommissionEstimate(null)
        close()
      })
      .catch((e) => {
        let errorMessage = 'CreateWithdrawal_Error'
        if (e.response && e.response.status === 401) {
          if (e.response.data === 'WrongGoogleAuthenticatorCode') {
            setInputGoogle2FaError(true)
            errorMessage = 'P2PInvalidCodeGoogle2Fa_Error'
          } else if (e.response.data === 'WrongEmailCode') {
            setInputEmailError(true)
            errorMessage = 'P2PInvalidEmailCode_Error'
          }
        }
        toast.error(t(errorMessage))
      })
  }

  const createWithdrawalModel = () => ({
    withdrawalType: WithdrawalTypeCodes.Crypto,
    publicId: publicID,
    address,
    currencyCode: currency.code,
    adapterCode: adapter.code,
    amount,
    emailAuthCode: emailCode,
  })
  const handleSendEmail = (e) => {
    e.preventDefault()
    setEmailDisabled(true)

    api
      .sendEmailConfirmation(createWithdrawalModel())
      .then(() => {
        toast.success(t('SendEmailConfirmation_Success'))
      })
      .catch(() => {
        toast.error(t('SendEmailConfirmation_Error'))
      })
      .finally(() => {
        setEmailDisabled(false)
      })
  }
  const checkGoogle2FaStatus = async () => {
    try {
      const result = await api.fetchGoogleAuthStatus()
      setIsTwoFaEnabledForUser(result)
    } catch (error) {
      console.log('checkGoogle2FaStatus error:', error)
    }
  }

  const checkGoogle2FaWithdrawalStatus = async () => {
    try {
      const result = await api.fetchGoogle2FaWithdrawalStatus()
      setIsTwoFaEnabledForWithdrawal(result)
      if (!result) return
      checkGoogle2FaStatus()
    } catch (error) {
      console.log('fetchGoogle2FaWithdrawalStatus error:', error)
    }
  }

  useEffect(() => {
    checkGoogle2FaWithdrawalStatus()
  }, [])

  return (
    <div className={styles.block}>
      <div className={styles.body}>
        <div className={styles.infoBody}>
          <h2 className={styles.title}>{t('WithdrawOf', { currency: currency.code })}</h2>
          <p className={styles.description}>{t('WithdrawApplication')}</p>
          <div className={styles.selectTitle}>{t('ChooseNetwork')}</div>
          <div className={styles.buttonBody}>
            {adapters.map((item) => {
              const [className, activeClassName] = getAdapterStyleNames(item.code)
              const isActive = item.code === adapter.code
              return (
                <button
                  key={item.code}
                  onClick={() => setAdapter(item)}
                  className={cssClass(styles.button, className, isActive ? activeClassName : null)}
                >
                  {item.name}
                </button>
              )
            })}
          </div>
          {adapter && adapter.code === 'eth' && (
            <div className={styles.dangerBody}>
              <div className={styles.danger}>{t('WithdrawalsOnlyPossible')}</div>
            </div>
          )}
        </div>
      </div>
      <form className={styles.form}>
        <p className={styles.formTitle}>{t('AvailableForWithdrawal')}</p>
        <input
          className={cssClass(baseComponents.input, styles.input, styles.firstInput)}
          value={freeBalance.toFixedTrimmed(currency.digits)}
          readOnly={true}
        ></input>
        <p className={styles.formTitle}>{t('WithdrawalAddress')}</p>
        <input
          className={cssClass(baseComponents.input, styles.input)}
          placeholder={t('RecipientAddress')}
          value={address}
          onChange={(e) => setAddress(e.target.value.replace(/[^a-zA-Z0-9]/g, ''))}
        ></input>
        <div className={cssClass(styles.summWrapper)}>
          <p className={cssClass(styles.formTitle, styles.summTitle)}>{t('withdrawalAmount')}</p>
          <p onClick={() => history.push('/commissions')} className={cssClass(styles.formTitle, styles.commission)}>
            {t('Commission')}
          </p>
          <input
            type='number'
            className={cssClass(baseComponents.input, styles.input, styles.summInput)}
            placeholder={t('minimalWithdraw')}
            value={amount || ''}
            onChange={(e) => setAmount(e.target.value)}
          ></input>
          <button
            onClick={(e) => {
              setAmount(freeBalance)
            }}
            type='button'
            className={cssClass(baseComponents.buttonColored, baseComponents.buttonGreen, styles.summButton)}
          >
            {t('WithdrawAll')}
          </button>
          <input
            className={cssClass(baseComponents.input, styles.commissionDisabledInput)}
            style={{ overflow: 'visible' }}
            readOnly={true}
            placeholder={getCommissionInfoString(commissions, CommissionTypes.Withdrawal, currency.code, adapter.code)}
          ></input>
        </div>

        <p className={styles.formTitle}>{t('PaymentAmount')}</p>
        <input
          className={cssClass(baseComponents.input, styles.input)}
          readOnly={true}
          value={commissionEstimate?.amountAfterCommission || ''}
        ></input>

        <p className={styles.formTitle}>{t('EmailCode')}</p>
        <div className={styles.inputButtonWrapper}>
          <input
            className={cssClass(
              baseComponents.input,
              styles.input,
              styles.fourthInput,
              inputEmailError && styles.inputError,
            )}
            placeholder=''
            value={emailCode}
            onChange={(e) => {
              setInputEmailError(false)
              setEmailCode(e.target.value)
            }}
          />
          <button
            onClick={handleSendEmail}
            className={cssClass(baseComponents.buttonColored, baseComponents.buttonGreen)}
            disabled={isEmailBtnDisabled}
          >
            {t('SendEmail')}
          </button>
        </div>
        {isTwoFaEnabledForWithdrawal && (
          <>
            <p className={styles.formTitle}>{t('P2PEnterGoogleAuthenticatorCode')}</p>
            <div className={styles.inputButtonWrapper}>
              <input
                className={cssClass(
                  baseComponents.input,
                  styles.input,
                  styles.fourthInput,
                  inputGoogle2FaError && styles.inputError,
                )}
                placeholder=''
                value={google2FaCode}
                onChange={(e) => {
                  setInputGoogle2FaError(false)
                  setGoogle2FaCode(e.target.value)
                }}
                disabled={!isTwoFaEnabledForUser}
              />
              {!isTwoFaEnabledForUser && (
                <>
                  <button
                    onClick={() => history.push(`/${i18n.language}/security`)}
                    className={cssClass(baseComponents.buttonColored, baseComponents.buttonGreen)}
                    disabled={isTwoFaEnabledForUser}
                  >
                    {t('P2PEnable')}
                  </button>
                </>
              )}
            </div>
          </>
        )}

        <button
          type='button'
          className={cssClass(baseComponents.button, styles.sendButton)}
          onClick={() => {
            setInputGoogle2FaError(google2FaCode ? false : true)
            setInputEmailError(emailCode ? false : true)
            withdraw()
          }}
          // disabled={!google2FaCode || !emailCode}
        >
          {t('Send')}
        </button>
      </form>
      <PassVerificationModal
        opened={modals.isPassVerificationModalOpen}
        onClose={() => closeModal('isPassVerificationModalOpen')}
        onConfirmPassVerification={goToVerificationPage}
      />
    </div>
  )
}
