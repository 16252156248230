import React, { useEffect, useState } from 'react'
import Input from 'components/form/Input/Input'
import Modal from 'components/layout/Modal/Modal'
import QRCode from 'react-qr-code'
import api from 'api'
import { useHistory } from 'react-router-dom'
import i18n from 'i18next'

export default function Google2FaModal({ t, opened, setOpened }) {
  const [totpCode, setTotpCode] = useState(null)
  const [preShared, setPreShared] = useState(null)
  const [isCodeFailed, setIsCodeFailed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isTwoFaEnabled, setIsTwoFaEnabled] = useState(null)
  const history = useHistory()

  const goToSecurity = async () => {
    history.push({
      pathname: `/${i18n.language}/security`,
      state: { shouldUpdate: true },
    })
  }

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={() => setOpened((prev) => setOpened(false))}>
      <div className='p2p__security_modal'>
        <div className='twoFA_posting__title'>{t('P2PTwoFactorAuthenticationRequired')}</div>
        <div className='p2p__security_container'>
          <div className='twoFA_posting__step'>{t('P2PToPerformThisActionYouNeed')}</div>
          <div className='twoFA_posting__description'>{t('P2PPleaseLinkYourAccountToGoogle')}</div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row justify-center'>
            <div className='col col_6 col_mob-12'>
              <div className='form-item' onClick={() => goToSecurity()}>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className={`btn btn_secondary w-100`} disabled={isLoading}>
                      <span className='btn__text'>{t('P2PSetUp2FA')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='form-item' onClick={() => setOpened(false)}>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100'>
                      <span className='btn__text'>{t('Close')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
