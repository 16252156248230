import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'auth/auth'
import authApi from 'auth/api'
import icoAccount from './images/icoAccount.png'

export default function HeaderAccount() {
  const { t } = useTranslation()
  const auth = useAuth()

  const [user, setUser] = useState(null)

  useEffect(() => {
    let ignore = false // flag to prevent race conditions
    if (auth.token && !user) {
      authApi.fetchH2kUser().then((data) => {
        if (!ignore) setUser(data)
      })
    }
    return () => {
      ignore = true
    }
  }, [auth.token])

  return (
    <div className='header__account account'>
      {auth.token ? (
        <div className='account__link'>
          <NavLink to={`/${i18n.language}/finance`} className='account__link account__link_login'>
            <div className='account__title title'>
              <span className='title__text'>{user?.email}</span>
            </div>
            <div className='account__media'>
              <img src={icoAccount} alt='image description' />
            </div>
          </NavLink>
          <div className='account__logout'>
            <button onClick={auth.logout}>{t('Logout')}</button>
          </div>
        </div>
      ) : (
        <div className='account__link account__link_registration' onClick={() => auth.signin()}>
          <button className='btn'>
            <span className='btn__text'>{t('SignIn')}</span>
          </button>
        </div>
      )}
    </div>
  )
}
