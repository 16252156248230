import React, { useContext, createContext, useState, useEffect, useRef } from 'react'
import axios from 'axios'
import apiAuth from './api'
import api from '../api'
import { debugAccessToken } from 'config.js'
import { USER_VERIFICATION_STATUS } from 'pages/verification/Verification/VerificationData'

axios.defaults.withCredentials = true

const TOKEN_DATA_NAME = 'tokenData'
const TOKEN_EXPIRATION = 'tokenExpiration'
const REFRESH_TOKEN_EXPIRATION = 'refreshTokenExpiration'

const useProvideAuth = () => {
  const [token, setToken] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const signin = () => {
    try {
      removeTokenData(setToken, setIsLoading)

      const clientId = 'p2p'
      const redirectUri = encodeURI('https://p2p.h2k.me/en/after-login')

      const authUrl = `https://id.h2k.me/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=read`
      window.location.href = authUrl
    } catch (error) {
      console.error('Error signing in:', error)
    }
  }

  const logout = async (cb) => {
    console.log(' --- logout')
    try {
      await apiAuth.h2kLogout()
      removeTokenData(setToken, setIsLoading)
      if (typeof cb === 'function') cb()
    } catch (error) {
      console.error('Error h2kLogout:', error)
    }
  }

  const getAccessToken = async (code) => {
    try {
      const tokenObj = await apiAuth.h2kGetAccessToken(code)
      saveToken(tokenObj, setToken, setIsLoading)
      return tokenObj
    } catch (error) {
      console.error('Error h2kGetAccessToken:', error)
    }
  }

  const h2kUpdateAccessToken = async () => {
    try {
      const tokenObj = await apiAuth.h2kUpdateAccessToken()
      saveToken(tokenObj, setToken, setIsLoading)
      return tokenObj
    } catch (error) {
      console.error('Error h2kUpdateAccessToken:', error)
      logout()
    }
  }
  const checkTokenExpiration = () => {
    const tokenExpiration = localStorage.getItem(TOKEN_EXPIRATION)
    const refreshTokenExpiration = localStorage.getItem(REFRESH_TOKEN_EXPIRATION)
    if (!tokenExpiration || !refreshTokenExpiration) return

    const accessUntilExpiration = getTimeUntilExpiration(tokenExpiration)
    const refreshUntilExpiration = getTimeUntilExpiration(refreshTokenExpiration)
    const fiveMinutes = 5 * 60 * 1000 // 5 minutes in milliseconds

    if (accessUntilExpiration <= fiveMinutes && refreshUntilExpiration > 0) {
      h2kUpdateAccessToken()
    } else if (refreshUntilExpiration <= 0) {
      logout()
    }
  }
  useEffect(() => {
    checkTokenExpiration()
    const interval = setInterval(checkTokenExpiration, 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    checkForTokenAvailability(setToken, setIsLoading)
  }, [])

  useEffect(() => {
    if (!debugAccessToken || token) return
    saveToken(debugAccessToken, setToken, setIsLoading)
  }, [])

  useEffect(() => {
    if (token === null) return
    api
      .fetchUserVerificationStatus()
      .then((data) => {
        const isVerified = data === USER_VERIFICATION_STATUS.GREEN ? true : false
        window.isUserVerified = isVerified
      })
      .catch((error) => {
        console.error('Error fetchVerification:', error)
      })
  }, [token])

  return {
    token,
    isSignedIn: token != null,
    signin,
    logout,
    getAccessToken,
    isLoading,
  }
}

/* https://usehooks.com/useAuth/ */
const authContext = createContext()

function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

function useAuth() {
  return useContext(authContext)
}

export { ProvideAuth, useAuth }

const setTokenHeaders = (token) => {
  const headers = axios.defaults.headers
  const { token_type, access_token } = token
  headers.common['Authorization'] = `${token_type} ${access_token}`
  headers.common['jsapi'] = true
}

const removeTokenData = (setToken, setIsLoading) => {
  localStorage.removeItem(TOKEN_DATA_NAME)
  localStorage.removeItem(TOKEN_EXPIRATION)
  localStorage.removeItem(REFRESH_TOKEN_EXPIRATION)
  setToken(null)
  setIsLoading(false)
}

const getExpirationDate = (tokenDate) => {
  if (!tokenDate) return null
  const [date, time] = tokenDate.split(' ')
  const [month, day, year] = date.split('/')
  return new Date(`${year}-${month}-${day}T${time}Z`)
}

const saveToken = (tokenObj, setToken, setIsLoading) => {
  if (!tokenObj) {
    removeTokenData(setToken, setIsLoading)
    return
  }
  const token = {
    token_type: tokenObj.token_type,
    access_token: tokenObj.access_token,
  }
  setTokenHeaders(token)
  setToken(token)
  localStorage.setItem(TOKEN_DATA_NAME, JSON.stringify(token))
  updateTokenExpiration(tokenObj)
  setIsLoading(false)
}

const checkForTokenAvailability = (setToken, setIsLoading) => {
  const tokenJson = localStorage.getItem(TOKEN_DATA_NAME)
  if (!tokenJson) {
    removeTokenData(setToken, setIsLoading)
    return
  }
  const token = JSON.parse(tokenJson)
  if (token && token.access_token?.trim() !== '') {
    setTokenHeaders(token)
    setToken(token)
    setIsLoading(false)
  } else {
    removeTokenData(setToken, setIsLoading)
  }
}

const updateTokenExpiration = (tokenObj) => {
  if (!tokenObj) return
  if (!tokenObj || debugAccessToken) return
  localStorage.setItem(TOKEN_EXPIRATION, getExpirationDate(tokenObj.expires_in).toISOString())
  localStorage.setItem(REFRESH_TOKEN_EXPIRATION, getExpirationDate(tokenObj.refresh_token_expires_in).toISOString())
}

const getTimeUntilExpiration = (expirationDate) => {
  if (!expirationDate) return null
  return new Date(expirationDate).getTime() - Date.now()
}
