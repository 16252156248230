import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import Button from 'components/form/Button/Button'
import { toast } from 'react-toastify'
import api from '../../../api'

export default function UserBlockList() {
  const { t } = useTranslation()
  const [userBlockList, setUserBlockList] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const removeUserBlock = async (unblockedUserId) => {
    setIsDisabledBtn(true)
    api
      .removeP2PUserBlock(unblockedUserId)
      .then(() => {
        setUserBlockList(userBlockList.filter((userBlocked) => userBlocked.blockedUserId !== unblockedUserId))
        toast.success(t('ActionWasSuccessful'))
      })
      .catch(() => {
        toast.error(t('ErrorTryLater'))
      })
      .finally(() => setIsDisabledBtn(false))
  }

  useEffect(() => {
    setIsLoading(true)
    const fetchUserBlockList = async () => {
      try {
        const userBlockList = await api.fetchP2PUserBlockList()
        setUserBlockList(userBlockList)
      } catch (error) {
        console.error('Error Fetch User Block List:', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchUserBlockList()
  }, [])

  if (isLoading) {
    return (
      <React.Fragment>
        <div className='user__blacklist blacklist'>
          <div>Loading...</div>
        </div>
      </React.Fragment>
    )
  }
  if (userBlockList != null && !isLoading && userBlockList.length === 0) {
    return (
      <React.Fragment>
        <div className='user__blacklist blacklist'>
          <div>{t('BlackListIsEmpty')}</div>
        </div>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <div className='user__blacklist blacklist'>
        <div className='blacklist__main'>
          <div className='blacklist__list'>
            <RenderBlockList
              t={t}
              userBlockList={userBlockList}
              isDisabledBtn={isDisabledBtn}
              onUnblock={(unblockedUserId) => removeUserBlock(unblockedUserId)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const RenderBlockList = ({ t, userBlockList, isDisabledBtn, onUnblock }) => {
  return (
    <React.Fragment>
      {userBlockList?.map((userBlocked) => (
        <div key={userBlocked.blockedUserId} className='blacklist__item'>
          <div className='row'>
            <div className='col col_9 col_mob-12'>
              <div className='blacklist__data data'>
                <div className='data__list'>
                  <div className='row'>
                    <div className='col col_2 col_mob-5'>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('Nickname')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>{userBlocked.userNickname}</span>
                        </div>
                      </div>
                    </div>
                    <div className='col col_7 col_mob-12'>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('ReasonForBlocking')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>{userBlocked.comment}</span>
                        </div>
                      </div>
                    </div>
                    <div className='col col_3 col_mob-7'>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('Time')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>
                            {moment(userBlocked.blockingDate).format('YYYY-MM-DD HH:mm')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_3 col_mob-12'>
              <div className='blacklist__action'>
                <Button
                  addClasses={['btn btn_secondary-padding']}
                  text={t('Unblock')}
                  buttonCallback={() => onUnblock(userBlocked.blockedUserId)}
                  disabled={isDisabledBtn}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}
