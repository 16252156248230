import Footer from '../Footer/Footer.jsx'
import Header from '../Header/Header.jsx'
import '../../../../style/main.scss'
export default function Main({ children }) {
  return (
    <>
      <Header />
      <main className='layout__main'>{children}</main>
      <Footer />
    </>
  )
}
