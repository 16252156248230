import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import icoRu from './images/icoRu.png'
import icoEn from './images/icoEn.png'
import { useLanguage } from './useLanguage'

const langArray = [
  { key: 'ru', title: 'Ru', ico: icoRu },
  { key: 'en', title: 'En', ico: icoEn },
]
const arrow = (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M5.46492 8.54533L12.0057 15.086L18.5464 8.54533C18.9369 8.15481 19.5701 8.15481 19.9606 8.54533C20.3511 8.93586 20.3511 9.56902 19.9606 9.95954L12.7128 17.2073C12.5252 17.3949 12.2709 17.5002 12.0057 17.5002C11.7405 17.5002 11.4861 17.3949 11.2986 17.2073L4.0507 9.95954C4.00188 9.91073 3.95918 9.85812 3.92257 9.80267C3.66629 9.41452 3.70899 8.88704 4.0507 8.54533C4.44122 8.15481 5.0744 8.15481 5.46492 8.54533Z'
      fill='#253920'
    />
  </svg>
)

export default function HeaderLanguages() {
  const { t, i18n } = useTranslation()
  const [lang, setLang] = useState(langArray.filter((el) => el.key === i18n.language)[0])
  const [expand, setExpand] = useState(false)
  const { changeLanguage } = useLanguage()

  return (
    <div className={`header__languages languages ${expand ? 'header__languages__active' : ''}`}>
      <div className='languages__header' onClick={() => setExpand((prev) => !prev)}>
        <div className='languages__media'>
          <img src={lang.ico} alt='image description' />
        </div>
        <div className='languages__action'>{arrow}</div>
      </div>
      {expand ? (
        <div className='languages__main'>
          <div className='languages__list'>
            {langArray.map((item, i) => (
              <React.Fragment key={item.key}>
                {item.key !== lang.key ? (
                  <div
                    onClick={() => {
                      changeLanguage(item)
                      setExpand(false)
                    }}
                    className='languages__item'
                  >
                    <div className='languages__media'>
                      <img src={item.ico} alt='image description' />
                    </div>
                    <div className='languages__title'>
                      <span className='languages__text'>{item.key}</span>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}
