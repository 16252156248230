import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import api from 'api'
import Modal from '../../../../components/layout/Modal/Modal'
import Select from '../../../../components/form/Select/Select'
import Input from '../../../../components/form/Input/Input'

export default function ModalPayment({ opened, setOpened, requisiteForUpdating, allPaymentMethods }) {
  const { t } = useTranslation()

  const [displayedPaymentCurrencies, setDisplayedPaymentCurrencies] = useState([])
  const [selectedPaymentCurrency, setSelectedPaymentCurrency] = useState('')

  const [paymentSystemsList, setPaymentSystemsList] = useState([])
  const [selectedPaymentSystemId, setSelectedPaymentSystemId] = useState(undefined)

  const [selectedName, setSelectedName] = useState('')
  const [selectedCardNumber, setSelectedCardNumber] = useState('')
  const [selectedBankBranch, setSelectedBankBranch] = useState('')
  const [selectedBankName, setSelectedBankName] = useState('')

  const getRequisiteDto = () => {
    const requisiteDto = {
      paymentSystemId: selectedPaymentSystemId,
      paymentMethodCurrencyCode: selectedPaymentCurrency,
      name: selectedName,
      cardNumber: selectedCardNumber,
      bankBranch: selectedBankBranch,
      bankName: selectedBankName,
    }
    return requisiteDto
  }
  const clearRequisite = () => {
    setSelectedPaymentCurrency('')
    setSelectedPaymentSystemId(null)
    setSelectedName('')
    setSelectedCardNumber('')
    setSelectedBankBranch('')
    setSelectedBankName('')
  }

  const saveRequisite = () => {
    if (selectedPaymentSystemId == null || selectedCardNumber.trim() === '' || selectedName.trim() === '') return
    api
      .createP2PRequisite(getRequisiteDto())
      .then((data) => {
        toast.success(t('ActionWasSuccessful'))

        // close and reset data
        clearRequisite()
        setOpened(false)
      })
      .catch((err) => {
        toast.error(t('ErrorTryLater'))
      })
  }

  const updateRequisite = () => {
    if (selectedPaymentSystemId == null || selectedCardNumber.trim() === '' || selectedName.trim() === '') return
    api
      .updateP2PRequisite(requisiteForUpdating.id, getRequisiteDto())
      .then((data) => {
        toast.success(t('ActionWasSuccessful'))

        // close and reset data
        clearRequisite()
        setOpened(false)
      })
      .catch((err) => {
        toast.error(t('ErrorTryLater'))
      })
  }
  const processPaymentMethods = (allPaymentMethods) => {
    const uniqueCurrencies = [...new Set(allPaymentMethods.map((item) => item.currencyCode))]
    const uniquePaymentSystems = [
      ...new Map(allPaymentMethods.map((item) => [item.paymentSystem.id, item.paymentSystem])).values(),
    ]

    return { uniqueCurrencies, uniquePaymentSystems }
  }

  useEffect(() => {
    if (!opened || !allPaymentMethods) return

    const { uniqueCurrencies, uniquePaymentSystems } = processPaymentMethods(allPaymentMethods)
    setDisplayedPaymentCurrencies(['', ...uniqueCurrencies])
    setPaymentSystemsList(uniquePaymentSystems)
  }, [opened, allPaymentMethods])

  useEffect(() => {
    if (!allPaymentMethods || requisiteForUpdating) return
    setPaymentSystemsList([])
    const uniquePaymentSystems = [
      ...new Map(
        allPaymentMethods
          .filter((ps) => !selectedPaymentCurrency || ps.currencyCode === selectedPaymentCurrency)
          .map((item) => [item.paymentSystem.id, item.paymentSystem]),
      ).values(),
    ]
    setPaymentSystemsList(uniquePaymentSystems)

    // if selected payment system is not available for the selected currency, reset it
    if (selectedPaymentSystemId != null && uniquePaymentSystems.find((ps) => ps.id === selectedPaymentSystemId) == null)
      setSelectedPaymentSystemId(null)
  }, [selectedPaymentCurrency])

  useEffect(() => {
    if (!allPaymentMethods || requisiteForUpdating) return
    const uniqueCurrencies = [...new Set(allPaymentMethods.map((item) => item.currencyCode))]
    if (!selectedPaymentSystemId) {
      setDisplayedPaymentCurrencies('', uniqueCurrencies)
      return
    }

    const filteredPaymentMethods = allPaymentMethods.filter((ps) => ps.paymentSystem.id === selectedPaymentSystemId)
    setDisplayedPaymentCurrencies(['', ...filteredPaymentMethods.map((item) => item.currencyCode)])
  }, [selectedPaymentSystemId])

  useEffect(() => {
    if (!requisiteForUpdating || !paymentSystemsList.length === 0) return
    setSelectedPaymentCurrency(requisiteForUpdating.paymentMethodCurrencyCode)
    setSelectedPaymentSystemId(paymentSystemsList?.find((ps) => ps.id == requisiteForUpdating.paymentSystemId)?.id)
    setSelectedName(requisiteForUpdating.ownerName || '')
    setSelectedCardNumber(requisiteForUpdating.cardNumber || '')
    setSelectedBankBranch(requisiteForUpdating.bankBranch || '')
    setSelectedBankName(requisiteForUpdating.bankName || '')
  }, [requisiteForUpdating, paymentSystemsList])

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    } else {
      document.body.style.overflow = 'unset'
      document.body.style.position = 'static'
      document.body.style.width = 'auto'
    }

    return () => {
      document.body.style.overflow = 'unset'
      document.body.style.position = 'static'
      document.body.style.width = 'auto'
    }
  }, [opened])

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={() => setOpened((prev) => setOpened(false))}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('AddPaymentMethod')}</h2>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#ebfbf7' }}>
          <div className='wysiwyg'>
            <p>
              <strong>{t('ToComplyWithLegalRequirements')}</strong>
            </p>
          </div>
        </div>
        <div className='formular'>
          <div className='form-item'>
            <div className='form-item__header'>
              <span className='form-item__title'>{t('PaymentActive')}</span>
            </div>
            <div className='form-item__main'>
              <div className='form-item__field'>
                <Select
                  list={displayedPaymentCurrencies}
                  value={selectedPaymentCurrency}
                  filter={false}
                  addClasses={['w-100']}
                  selectCallback={(value) => {
                    setSelectedPaymentCurrency(value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='form-item'>
            <div className='form-item__header'>
              <span className='form-item__title'>{t('P2pPaymentSystem')}</span>
            </div>
            <div className='form-item__main'>
              <div className='form-item__field'>
                <Select
                  list={paymentSystemsList.map((ps) => ps.name)}
                  value={
                    selectedPaymentSystemId
                      ? paymentSystemsList.find((ps) => ps.id == selectedPaymentSystemId)?.name
                      : t('P2pPaymentSystem')
                  }
                  filter={true}
                  addClasses={['w-100']}
                  selectCallback={(value) => {
                    const paymentSystem = paymentSystemsList.find((ps) => ps.name === value)
                    setSelectedPaymentSystemId(paymentSystem.id)
                  }}
                />
              </div>
            </div>
            <div className='form-item__footer'>
              <div className='form-item__data data'>
                <div className='data__item data__item_before'>
                  {selectedPaymentSystemId === null && (
                    <span className='data__text'>
                      {t('PaymentUsingFiatFunds')} <br />
                    </span>
                  )}
                  <span className='data__text'>{t('PleaseNoteThatCrossBorderTransfersMayBeDelayed')}</span>
                </div>
              </div>
            </div>
          </div>
          <React.Fragment>
            <div className='form-item'>
              <div className='form-item__header'>
                <span className='form-item__title'>
                  {t('PleaseEnterAName')} <span className='form-item__sup'>*</span>
                </span>
              </div>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <Input
                    value={selectedName}
                    inputCallback={(value) => setSelectedName(value)}
                    placeholder={t('PleaseEnterAName')}
                    addClasses={['w-100']}
                  />
                </div>
              </div>
            </div>
            <div className='form-item'>
              <div className='form-item__header'>
                <span className='form-item__title'>
                  {t('BankAccountNumber')} <span className='form-item__sup'>*</span>
                </span>
              </div>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <Input
                    value={selectedCardNumber}
                    inputCallback={(value) => setSelectedCardNumber(value)}
                    placeholder={t('PleaseEnterYourAccountNumber')}
                    addClasses={['w-100']}
                  />
                </div>
              </div>
            </div>
            <div className='form-item'>
              <div className='form-item__header'>
                <span className='form-item__title'>{t('BankBranch')}</span>
              </div>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <Input
                    value={selectedBankBranch}
                    inputCallback={(value) => setSelectedBankBranch(value)}
                    placeholder={t('PleaseEnterBankBranch')}
                    addClasses={['w-100']}
                  />
                </div>
              </div>
            </div>
            <div className='form-item'>
              <div className='form-item__header'>
                <span className='form-item__title'>{t('NameOfTheBank')}</span>
              </div>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <Input
                    value={selectedBankName}
                    inputCallback={(value) => setSelectedBankName(value)}
                    placeholder={t('PleaseEnterBankName')}
                    addClasses={['w-100']}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
          {selectedPaymentSystemId === 99999 /* TODO implement after banks excel file */ && (
            <React.Fragment>
              <div className='form-item'>
                <div className='form-item__header'>
                  <span className='form-item__title'>
                    {t('PleaseEnterAName')} <span className='form-item__sup'>*</span>
                  </span>
                </div>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <Input
                      placeholder={t('PleaseEnterAName')}
                      inputCallback={() => console.log('Callback')}
                      addClasses={['w-100']}
                    />
                  </div>
                </div>
              </div>
              <div className='form-item'>
                <div className='form-item__header'>
                  <span className='form-item__title'>
                    {t('PaymentRequisites')} <span className='form-item__sup'>*</span>
                  </span>
                </div>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <Input
                      placeholder={t('PleaseEnterPaymentDetails')}
                      inputCallback={() => console.log('Callback')}
                      addClasses={['w-100']}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row'>
            <div className='col col_6 col_mob-12'>
              <div
                className='form-item'
                onClick={() => {
                  clearRequisite()
                  setOpened(false)
                }}
              >
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn btn_revert w-100'>
                      <span className='btn__text'>{t('Cancel')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button
                      className='btn w-100'
                      onClick={() => {
                        !requisiteForUpdating ? saveRequisite() : updateRequisite()
                      }}
                    >
                      <span className='btn__text'>{t('Confirm')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
