import { BuySellTabIndex } from './PostingData'

const InitializeStageData = (order, stageData, t) => {
  if (!order) {
    return {
      isEnableSelection: true, //Allowed when creating a new order and disabled when editing an order
      currentStageIndex: stageData.currentStageIndex || 0,
      selectedBuySellTabIndex: stageData.selectedBuySellTabIndex || BuySellTabIndex.Buy,
      currencyPairs: stageData.currencyPairs || [],
      selectedPair: stageData.selectedPair || null,
      selectedExchangeAsset: stageData.selectedExchangeAsset || 'USDT',
      selectedPaymentAsset: stageData.selectedPaymentAsset || '',
      userSelectedTradeRate: stageData.userSelectedTradeRate || 0,
      balanceOfSelectedExchangeAsset: stageData.balanceOfSelectedExchangeAsset || null,
      balanceOfSelectedPaymentAsset: stageData.balanceOfSelectedPaymentAsset || null,
      selectedPaymentRequisites: stageData.selectedPaymentRequisites || [],
      limitMin: stageData.limitMin || 0,
      limitMax: stageData.limitMax || 2000,
      selectedPaymentDuration: stageData.selectedPaymentDuration || 30,
      amountOfSelectedExchangeAsset: stageData.amountOfSelectedExchangeAsset || 0,
      amountOfSelectedPaymentAsset: stageData.amountOfSelectedPaymentAsset || 0,
      commission: stageData.commission || null,
      commissionAmount: stageData.commissionAmount || 0,
      isKYCPassed: stageData.isKYCPassed || false,
      selectedMinDaysSinceRegistration: stageData.selectedMinDaysSinceRegistration || 0,
      selectedMinOrdersRequired: stageData.selectedMinOrdersRequired || 0,
      selectedMinOrderCompletionRate: stageData.selectedMinOrderCompletionRate || 0,
      termsTextarea: stageData.termsTextarea || '',
      isPaymentMethodsModalOpen: stageData.isPaymentMethodsModalOpen || false,
    }
  }

  return {
    isEnableSelection: false,
    currentStageIndex: 0,
    selectedBuySellTabIndex: order.isBuyingOnExchange ? BuySellTabIndex.Buy : BuySellTabIndex.Sell,
    currencyPairs: [],
    selectedPair: null,
    selectedExchangeAsset: order.exchangeCurrencyCode,
    selectedPaymentAsset: order.paymentCurrencyCode,
    userSelectedTradeRate: order.price,
    balanceOfSelectedExchangeAsset: null,
    balanceOfSelectedPaymentAsset: null,
    selectedPaymentRequisites: order?.requisites.map((method) => ({ ...method, selected: true })) || [],
    limitMin: order.minDealAmount,
    limitMax: order.maxDealAmount,
    selectedPaymentDuration: order.maxTimeMinutes,
    amountOfSelectedExchangeAsset: order.totalExchangeAmount,
    amountOfSelectedPaymentAsset: order.totalExchangeAmount * order.price,
    commission: null,
    commissionAmount: 0,
    isKYCPassed: order.isKYCPassed,
    selectedMinDaysSinceRegistration: order.minDaysSinceRegistration,
    selectedMinOrdersRequired: order.minOrdersRequired,
    selectedMinOrderCompletionRate: order.minOrderCompletionRate,
    termsTextarea: order.description,
    isPaymentMethodsModalOpen: false,
  }
}
const MapToCreateOrderModel = (stageData) => {
  return {
    IsBuyingOnExchange: stageData.selectedBuySellTabIndex === BuySellTabIndex.Buy,
    ExchangeCurrencyCode: stageData.selectedExchangeAsset,
    PaymentCurrencyCode: stageData.selectedPaymentAsset,
    Price: stageData.userSelectedTradeRate,
    TotalExchangeAmount: stageData.amountOfSelectedExchangeAsset,
    MinDealAmount: stageData.limitMin,
    MaxDealAmount: stageData.limitMax,
    Description: stageData.termsTextarea,
    MaxTimeMinutes: stageData.selectedPaymentDuration,
    IsKYCPassed: stageData.isKYCPassed,
    MinDaysSinceRegistration: stageData.selectedMinDaysSinceRegistration,
    MinOrdersRequired: stageData.selectedMinOrdersRequired,
    MinOrderCompletionRate: stageData.selectedMinOrderCompletionRate,
    RequisiteIds: stageData.selectedPaymentRequisites.map((pm) => pm.id),
  }
}
export { InitializeStageData, MapToCreateOrderModel }
