import { DEAL_STATUS, APPEAL_TYPES } from '../Order/OrderAndDealData'

export const StageIndex = {
  WaitPaid: 0,
  WaitConfirm: 1,
  Successfully: 2,
  Canceled: 3,
}

export const StatusToStageIndex = {
  [DEAL_STATUS.CreatedAwaitingPaymentSystem]: 0,
  [DEAL_STATUS.PaymentSystemSent]: 1,
  [DEAL_STATUS.PaymentSystemConfirmed]: 2,
  [DEAL_STATUS.CreatedAndBlockedForExchange]: 2,
  [DEAL_STATUS.CryptoReleased]: 2,
  [DEAL_STATUS.Canceled]: 3,
  [DEAL_STATUS.CanceledCryptoUnfrozen]: 3,
  [DEAL_STATUS.AppealedForActiveDeal]: 4,
  [DEAL_STATUS.AppealedAfterFinish]: 4,
}

export const TextMapForBottomActions = {
  buying: {
    [DEAL_STATUS.CreatedAwaitingPaymentSystem]: ['AfterTransferOfFunds', ''],
    [DEAL_STATUS.PaymentSystemSent]: ['', ''],
    [DEAL_STATUS.PaymentSystemConfirmed]: ['P2PUSDTHasArrivedInYourAssetActive', ''],
    [DEAL_STATUS.CreatedAndBlockedForExchange]: ['P2PUSDTHasArrivedInYourAssetActive', ''],
    [DEAL_STATUS.CryptoReleased]: ['P2PUSDTHasArrivedInYourAssetActive', ''],
  },
  selling: {
    [DEAL_STATUS.CreatedAwaitingPaymentSystem]: ['', ''],
    [DEAL_STATUS.PaymentSystemSent]: ['ConfirmPaymentSecondary', 'WhenYouClickOnPaymentReceived'],
    [DEAL_STATUS.PaymentSystemConfirmed]: ['P2PTheBuyerReceivedActive', ''],
    [DEAL_STATUS.CreatedAndBlockedForExchange]: ['P2PTheBuyerReceivedActive', ''],
    [DEAL_STATUS.CryptoReleased]: ['P2PTheBuyerReceivedActive', ''],
  },
}

export const TextMapForInfo = {
  buying: {
    [DEAL_STATUS.CreatedAwaitingPaymentSystem]: [
      'TransferFunds',
      'ExchangeOnlySupportsVerifiedPaymentMethodsUsingYourRealName',
    ],
    [DEAL_STATUS.PaymentSystemSent]: [
      'TransferOfCryptocurrencyByTheSeller',
      'PaymentIsExpectedToBeCompletedInApproximately',
    ],
    [DEAL_STATUS.CryptoReleased]: ['', ''],
    [DEAL_STATUS.Canceled]: ['YourOrderHasBeenCanceled', 'CancelingAnOrderDoesNotAffectTheExtentOfItsCompletion'],
    [DEAL_STATUS.CanceledCryptoUnfrozen]: [
      'YourOrderHasBeenCanceled',
      'CancelingAnOrderDoesNotAffectTheExtentOfItsCompletion',
    ],
  },
  selling: {
    [DEAL_STATUS.CreatedAwaitingPaymentSystem]: [
      'BuyerPaymentExpected',
      'PaymentIsExpectedToBeCompletedInApproximately',
    ],
    [DEAL_STATUS.PaymentSystemSent]: ['MakeSureYouHaveSuccessfullyReceivedThePayment', 'CoinsWillBeReleasedThrough'],
    [DEAL_STATUS.CryptoReleased]: ['', ''],
    [DEAL_STATUS.Canceled]: ['YourOrderHasBeenCanceled', 'CancelingAnOrderDoesNotAffectTheExtentOfItsCompletion'],
    [DEAL_STATUS.CanceledCryptoUnfrozen]: [
      'YourOrderHasBeenCanceled',
      'CancelingAnOrderDoesNotAffectTheExtentOfItsCompletion',
    ],
  },
}

export const TextMapForAppealInfo = {
  buying: {
    [DEAL_STATUS.AppealedForActiveDeal]: ['AppealIsPending', ''],
    [DEAL_STATUS.AppealedAfterFinish]: ['AppealIsPending', ''],
  },
  selling: {
    [DEAL_STATUS.AppealedForActiveDeal]: ['AppealIsPending', ''],
    [DEAL_STATUS.AppealedAfterFinish]: ['AppealIsPending', ''],
  },
}

export const TextMapForSystemMessageMegaphone = {
  buying: {
    [DEAL_STATUS.CreatedAwaitingPaymentSystem]: ['P2PBuyerDealCreatorDealSubmittedAwaitingPayment'],
    [DEAL_STATUS.PaymentSystemSent]: ['P2PBuyerPaymentInProgress'],
    [DEAL_STATUS.PaymentSystemConfirmed]: ['P2PBuyerCoinsReleaseConfirmed'],
    [DEAL_STATUS.CreatedAndBlockedForExchange]: ['P2PBuyerCoinsReleaseConfirmed'],
    [DEAL_STATUS.Canceled]: ['', ''],
  },
  selling: {
    [DEAL_STATUS.CreatedAwaitingPaymentSystem]: ['P2PSellerOrderCreatorDealReceivedAwaitingPayment'],
    [DEAL_STATUS.PaymentSystemSent]: ['P2PSellerPaymentReceived'],
    [DEAL_STATUS.PaymentSystemConfirmed]: ['P2PSellerAssetsReleased'],
    [DEAL_STATUS.CreatedAndBlockedForExchange]: ['P2PSellerAssetsReleased'],
    [DEAL_STATUS.Canceled]: [''],
  },
}

export const TextMapForWarningMessage = {
  1: 'P2PForDealCreatorFakeSupportWarning',
  2: 'P2PForDealCreatorFraudSuspicionAdvice',
}

export const BuyerFAQStage1 = [
  { title: 'P2PBuyerPaymentMethodGuidanceStage1', text: 'P2PBuyerPaymentMethodGuidanceStage1Text' },
  { title: 'P2PBuyerOffPlatformPaymentRisksStage1', text: 'P2PBuyerOffPlatformPaymentRisksStage1Text' },
  { title: 'P2PBuyerPostPaymentActionStage1', text: 'P2PBuyerPostPaymentActionStage1Text' },
  { title: 'P2PBuyerPaymentFailureActionStage1', text: 'P2PBuyerPaymentFailureActionStage1Text' },
  { title: 'P2PBuyerAdditionalVerificationFeesQueryStage1', text: 'P2PBuyerAdditionalVerificationFeesQueryStage1Text' },
]
export const BuyerFAQStage2 = [
  { title: 'P2PBuyerOrderCancellationQueryStage2', text: 'P2PBuyerCancellationImpactWarningStage2Text' },
  { title: 'P2PBuyerOrderCancellationImpactStage2', text: 'P2PBuyerOrderCancellationImpactStage2Text' },
]
export const BuyerFAQStage3 = [
  { title: 'P2PBuyerViewAssetsAfterCompletionStage3', text: 'P2PBuyerAssetsViewingLocationStage3Text' },
  { title: 'P2PBuyerViewOrderGuideStage3', text: 'P2PBuyerOrderListViewingMethodStage3Text' },
]
//canceled stage
export const BuyerFAQStage4 = [
  { title: 'P2PBuyerWhyDidMyOrderGetCancelStage4', text: 'P2PBuyerIfYouFailToClickOnCancelStage4Text' },
  { title: 'P2PBuyerIVeCompletedThePaymentButCancelStage4', text: 'P2PBuyerWeRecommendThatYouCancelStage4Text' },
  { title: 'P2PBuyerHowManyCancelationsCancelStage4', text: 'P2PBuyerPleaseNoteThatUpCancelStage4Text' },
]
export const SellerFAQStage1 = [
  { title: 'P2PSellerActionIfNoPaymentStage1', text: 'P2PSellerActionIfNoPaymentStage1Text' },
  { title: 'P2PSellerPaymentVerificationTipsStage1', text: 'P2PSellerPaymentVerificationTipsStage1Text' },
]
export const SellerFAQStage2 = [
  { title: 'P2PSellerCoinReleaseUrgedStage2', text: 'P2PSellerCoinReleaseUrgedStage2Text' },
  { title: 'P2PSellerNoPaymentReceivedStage2', text: 'P2PSellerNoPaymentReceivedStage2Text' },
]
export const SellerFAQStage3 = [
  { title: 'P2PSellerViewAssetsAfterCompletionStage3', text: 'P2PSellerAssetsViewingPostTransactionStage3Text' },
  { title: 'P2PSellerViewOrderGuideStage3', text: 'P2PSellerOrderListNavigationStage3Text' },
]
//canceled stage
export const SellerFAQStage4 = [
  { title: 'P2PSellerWhyDidMyOrderCancelStage4', text: 'P2PSellerIfYouFailToClickOnCancelStage4Text' },
  { title: 'P2PSellerCanICancelAnOrderCancelStage4', text: 'P2PSellerCurrentlyOnlyTheBuyerCancelStage4Text' },
  { title: 'P2PSellerIVeReceivedThePaymentCancelStage4', text: 'P2PSellerYouMayReachOutToCancelStage4Text' },
]

export const DEAL_CANCEL_DISPUTE_STATUS = {
  CreatorAdmitsFault: 10,
  CreatorDeniesFault: 20,
  RespondentAdmitsFault: 30,
  RespondentDeniesFault: 40,
}
