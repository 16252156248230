import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import Modal from 'components/layout/Modal/Modal'
import Checkbox from 'components/form/Checkbox/Checkbox'
import Textarea from 'components/form/Textarea/Textarea'
import Icon from '../ProgressIcons'

export default function DealCommentModal({ onConfirmDealCommentModal, opened, onClose, commentData }) {
  const { t } = useTranslation()

  const [isDisabledConfirmBtn, setIsDisabledConfirmBtn] = useState(true)

  const [feedback, setFeedback] = useState({
    isPositive: null,
    comment: '',
    isAnonymously: false,
  })

  useEffect(() => {
    setFeedback({
      isPositive: commentData?.isPositive,
      comment: commentData?.comment || '',
      isAnonymously: commentData?.isAnonymously,
    })
  }, [commentData])

  useEffect(() => {
    const { isPositive, comment } = feedback
    const isDisabled = isPositive === undefined || (!isPositive && (comment === null || comment.trim() === ''))
    setIsDisabledConfirmBtn(isDisabled)
  }, [feedback])

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('LeaveFeedback')}</h2>
          <p>
            <strong>{t('WhatWasYourExperienceWithThisBuyer')}</strong>
          </p>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: 'rgba(0,208,158,.08)' }}>
          <div className='wysiwyg'>
            <p>
              <strong>{t('PleaseLeaveAnHonestReviewThatAccuratelyReflectsourActualSituation')}</strong>
            </p>
          </div>
        </div>
        <div className='formular'>
          <div className='row'>
            <FeedbackOption isPositive={true} setFeedback={setFeedback} feedback={feedback} t={t} />
            <FeedbackOption isPositive={false} setFeedback={setFeedback} feedback={feedback} t={t} />
          </div>
          <FeedbackComment comment={feedback.comment} setFeedback={setFeedback} />
          <FeedbackAnonymouslyCheckbox isAnonymously={feedback.isAnonymously} setFeedback={setFeedback} t={t} />
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular m-0'>
          <div className='form-item'>
            <div className='form-item__main'>
              <div className='form-item__field'>
                <button
                  className='btn w-100'
                  disabled={isDisabledConfirmBtn}
                  onClick={() => {
                    onConfirmDealCommentModal(commentData ? 'updateDealComment' : 'createDealComment', feedback)
                  }}
                >
                  <span className='btn__text'>{t('Confirm')} </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

function FeedbackOption({ isPositive, setFeedback, feedback, t }) {
  const iconName = isPositive ? 'upIco' : 'downIco'
  const feedbackText = t(isPositive ? 'Fine' : 'Badly')

  const handleChange = () => {
    setFeedback((prevState) => ({
      ...prevState,
      isPositive: isPositive,
    }))
  }

  return (
    <div className='col col_6 col_mob-12'>
      <div className='form-item'>
        <div className='form-item__main'>
          <div className='form-item__field'>
            <div className={`form-item__switch form-item__switch_${isPositive ? 'up' : 'down'} switch`}>
              <label className='switch__label'>
                <input
                  className='switch__input'
                  type='radio'
                  name='reviewModal'
                  checked={isPositive === feedback.isPositive}
                  onChange={handleChange}
                />
                <div className='switch__layout'>
                  <div className='switch__media'>
                    <Icon name={iconName} />
                  </div>
                  <div className='switch__title'>
                    <span className='switch__text'>{feedbackText}</span>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function FeedbackComment({ comment, setFeedback }) {
  return (
    <div className='form-item'>
      <div className='form-item__main'>
        <div className='form-item__field'>
          <Textarea
            id={'TermsOfTheTransactionRequired'}
            name={'TermsOfTheTransactionRequired'}
            value={comment}
            setValue={(value) => setFeedback((prevState) => ({ ...prevState, comment: value }))}
            addClasses={['w-100']}
          />
        </div>
      </div>
    </div>
  )
}
function FeedbackAnonymouslyCheckbox({ isAnonymously, setFeedback, t }) {
  return (
    <div className='form-item'>
      <div className='form-item__main'>
        <div className='form-item__field'>
          <Checkbox
            type={'checkbox'}
            text={t(`Anonymously`)}
            prompt={t(`ByCheckingThisBoxYouWillReceiveAnAnonymousRating`)}
            checkboxCallback={(checked) => setFeedback((prevState) => ({ ...prevState, isAnonymously: checked }))}
          />
        </div>
      </div>
    </div>
  )
}
