import React, { useState, useEffect } from 'react'
import Modal from 'components/layout/Modal/Modal'
import SubmitAndContactButtons from './SubmitAndContactButtons'
import { useTranslation } from 'react-i18next'

export default function HelpNotPaymentOrNotCoinsModal({
  onConfirmHelpNotPaymentOrNotCoinsModal,
  opened,
  onClose,
  deal,
}) {
  if (!deal) return null

  const { t } = useTranslation()

  const paymentAmount = deal?.paymentAmount
  const paymentCurrencyCode = deal?.order?.paymentCurrencyCode
  const bankName = deal?.requisite?.bankName
  const name = deal?.requisite?.paymentSystem?.name

  const { isDirectionDealBuying, order } = deal

  const cause = isDirectionDealBuying
    ? 'P2PCauseICompletedThePaymentButTheCounterpartyDidNotReleaseTheCoins'
    : 'P2PCauseIHaveNotReceivedASinglePaymentFromTheCounterparty'

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('GetHelp')}</h2>
          {isDirectionDealBuying ? (
            <p>
              <strong>{t('P2PCauseICompletedThePaymentButTheCounterpartyDidNotReleaseTheCoins')}</strong>
              <br />
              <small className='transparentHalf'>{t('TryToResolveTheIssueByFollowingTheseSteps')}</small>
            </p>
          ) : (
            <p>
              <strong>{t('P2PCauseIHaveNotReceivedASinglePaymentFromTheCounterparty')}</strong>
              <br />
              <small className='transparentHalf'>{t('TryToResolveTheIssueByFollowingTheseSteps')}</small>
            </p>
          )}
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='wysiwyg'>
            {isDirectionDealBuying ? (
              <ol>
                <li>
                  <strong>{t('MakeSureYouPayTheOrderAmountUsingTheSelle')}</strong>
                </li>
                <li>
                  <strong>{t('GiveTheMerchantSomeTimeToReviewYourPayment')}</strong>
                  <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#ffffff' }}>
                    <div className='data'>
                      <div className='data__list data__list_row'>
                        <div className='data__item'>
                          <div className='data__header'>
                            <span className='data__title'>{t('Volume')}</span>
                          </div>
                          <div className='data__main'>
                            <span className='data__text'>
                              {paymentAmount} {paymentCurrencyCode}
                            </span>
                          </div>
                        </div>
                        <div className='data__item'>
                          {!order?.areBothCurrenciesExchange && (
                            <React.Fragment>
                              <div className='data__header'>
                                <span className='data__title'>{t('ConverterPaymentMethod')}</span>
                              </div>
                              <div className='data__main'>
                                <span className='data__text'>
                                  {name} ({bankName})
                                </span>
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <strong>{t('CommunicateWithTheSellerAndProvideProof')}</strong>
                </li>
                <li>
                  <strong>{t('RestAssuredThatTheSellerCryptocurrency')}</strong>
                </li>
              </ol>
            ) : (
              <ol>
                <li>
                  <strong>{t('TryToResolveTheIssueByFollowingTheseSteps')}</strong>
                </li>
                <li>
                  <strong>{t('ContactTheBuyerViaChatAndAskHimForPaymentConfirmation')}</strong>
                </li>
              </ol>
            )}
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='wysiwyg text-center transparentHalf'>
          <p>
            <strong>{t('IfTheIssueCannotBeResolvedYouCanFileAnAppeal')}</strong>
          </p>
        </div>
        <SubmitAndContactButtons
          onContact={() => {
            onClose()
            onConfirmHelpNotPaymentOrNotCoinsModal('onContact')
          }}
          onSubmitAnAppeal={() => {
            onClose()
            onConfirmHelpNotPaymentOrNotCoinsModal('onSubmitAnAppeal', cause)
          }}
          onClose={onClose}
          deal={deal}
        />
      </div>
    </Modal>
  )
}
