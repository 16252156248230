import api from 'api'

const Base64ToBlob = (base64Data, extension) => {
  const actualBase64 = base64Data.split(',')[1] ? base64Data.split(',')[1] : base64Data
  const byteString = atob(actualBase64)
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  const extensionToMimeType = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    pdf: 'application/pdf',
    txt: 'text/plain',
  }

  const mimeType = extensionToMimeType[extension.toLowerCase()] || 'application/octet-stream'

  return new Blob([ab], { type: mimeType })
}

const fileCache = new Map()

const LoadFile = async (chatFileId) => {
  try {
    if (fileCache.has(chatFileId)) {
      return fileCache.get(chatFileId)
    }

    const chatFile = await api.fetchP2PChatFile(chatFileId)
    if (!chatFile || !chatFile.data) {
      throw new Error('File data is missing')
    }

    const blob = Base64ToBlob(chatFile.data, chatFile.extension)

    let fileName = chatFile.fileName
    if (chatFile.extension && !fileName.toLowerCase().endsWith(chatFile.extension.toLowerCase())) {
      fileName = `${fileName}.${chatFile.extension}`
    }

    const fileData = {
      blob,
      fileName,
      extension: chatFile.extension,
    }

    fileCache.set(chatFileId, fileData)

    return fileData
  } catch (error) {
    console.error('Error loading chat file', error)
    throw error
  }
}

const DownloadFile = async (chatFileId) => {
  try {
    const fileData = await LoadFile(chatFileId)

    const url = window.URL.createObjectURL(fileData.blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileData.fileName)
    document.body.appendChild(link)
    link.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }, 100)
  } catch (error) {
    console.error('Error downloading file', error)
  }
}

const GetFilePreviewUrl = async (chatFileId) => {
  try {
    const fileData = await LoadFile(chatFileId)
    return {
      url: URL.createObjectURL(fileData.blob),
      extension: fileData.extension,
      fileName: fileData.fileName,
    }
  } catch (error) {
    console.error('Error creating preview URL', error)
    return null
  }
}

export { LoadFile, DownloadFile, GetFilePreviewUrl }
