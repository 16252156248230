import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

import authApi from 'auth/api'
import { useAuth } from 'auth/auth'

import Main from '../../common/components/Main/Main'
import styles from '../email-confirmation/EmailPage.module.scss'
import { useLanguage } from 'pages/common/components/Header/Languages/useLanguage'

const AfterLoginPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const auth = useAuth()
  const { changeLanguage } = useLanguage()

  useEffect(() => {
    const getTokenFromUrl = async () => {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true })
      const code = params.code
      if (code) {
        const token = await auth.getAccessToken(code)
        if (token) changeLanguage(i18n.language)
        history.push(`/${i18n.language}`)
      }
    }
    getTokenFromUrl()
  }, [])

  return (
    <Main>
      <div className={styles.wrapper}>
        <span>Redirecting...</span>
      </div>
    </Main>
  )
}

export default AfterLoginPage
