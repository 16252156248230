import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const OrderIcons = ({ name, textToCopy = null }) => {
  const { t } = useTranslation()
  const [showTooltip, setShowTooltip] = useState(false)
  const handleCopy = () => {
    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setShowTooltip(true)
          setTimeout(() => {
            setShowTooltip(false)
          }, 600) // Уведомление будет показано на 2 секунды
        })
        .catch((err) => {
          console.error('Error copy text', err)
        })
    }
  }

  switch (name) {
    case 'closeIco':
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='26' height='27' viewBox='0 0 26 27' fill='none'>
          <path d='M25 1.5L1 25.5L25 1.5ZM1 1.5L25 25.5L1 1.5Z' fill='#0039A6' />
          <path
            d='M25 1.5L1 25.5M1 1.5L25 25.5'
            stroke='#FF004D'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )
    case 'filterIco':
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
          <rect width='32' height='32' rx='16' fill='#253920' />
          <path
            d='M14.5764 19.684L15.7524 20.5984C16.0102 20.7992 16.0395 21.1512 15.8191 21.3856C15.7668 21.4412 15.7027 21.4869 15.6307 21.52C15.5586 21.5532 15.4799 21.5731 15.3992 21.5787C15.3185 21.5843 15.2374 21.5754 15.1606 21.5525C15.0837 21.5297 15.0127 21.4933 14.9515 21.4456L13.5605 20.364C13.4929 20.3114 13.4387 20.2463 13.4016 20.1731C13.3645 20.0999 13.3453 20.0204 13.3454 19.94V14.3504L8.15266 8.924C7.80512 8.5624 8.08955 8 8.61753 8H23.3833C23.9112 8 24.1939 8.5632 23.8481 8.924L18.6483 14.3512V23.4416C18.6483 23.7504 18.3728 24 18.0332 24C17.9521 23.9997 17.8718 23.985 17.797 23.9568C17.7222 23.9285 17.6543 23.8873 17.5972 23.8355C17.5401 23.7836 17.4948 23.7221 17.4641 23.6546C17.4334 23.587 17.4178 23.5146 17.4181 23.4416V14.1424C17.418 14.0079 17.4716 13.8778 17.5692 13.776L22.034 9.116H9.96504L14.4253 13.776C14.523 13.8778 14.5766 14.0079 14.5764 14.1424V19.684Z'
            fill='white'
          />
        </svg>
      )
    case 'copyIco':
      return (
        <div style={{ position: 'relative' }}>
          {showTooltip && (
            <div
              style={{
                position: 'absolute',
                top: '30px',
                left: '50%',
                transform: 'translateX(-50%)',
                background: 'black',
                color: 'white',
                padding: '4px 8px',
                borderRadius: '4px',
                zIndex: '1000',
              }}
            >
              {t('P2PCopied')}
            </div>
          )}
          <div onClick={handleCopy} style={{ cursor: 'pointer' }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
              <path
                d='M1.88235 16.5C1.36471 16.5 0.921413 16.3432 0.552472 16.0296C0.183531 15.716 -0.000625853 15.3395 1.59792e-06 14.9V3.7H1.88235V14.9H12.2353V16.5H1.88235ZM5.64706 13.3C5.12941 13.3 4.68612 13.1432 4.31718 12.8296C3.94824 12.516 3.76408 12.1395 3.76471 11.7V2.1C3.76471 1.66 3.94918 1.2832 4.31812 0.969601C4.68706 0.656001 5.13004 0.499468 5.64706 0.500001H14.1176C14.6353 0.500001 15.0786 0.656801 15.4475 0.970401C15.8165 1.284 16.0006 1.66053 16 2.1V11.7C16 12.14 15.8155 12.5168 15.4466 12.8304C15.0776 13.144 14.6347 13.3005 14.1176 13.3H5.64706Z'
                fill='#253920'
                fillOpacity='0.5'
              />
            </svg>
          </div>
        </div>
      )

    case 'chatIco':
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 16 15' fill='none'>
          <path
            d='M7 1C3.13359 1 0 3.34883 0 6.25C0 8.30078 1.57227 10.0727 3.8582 10.9367L2.33242 15L6.72109 11.4891C6.81406 11.4918 6.9043 11.5 7 11.5C10.8664 11.5 14 9.15117 14 6.25C14 3.34883 10.8664 1 7 1ZM3.5 7.41758C2.85742 7.41758 2.33242 6.89531 2.33242 6.25C2.33242 5.60469 2.85469 5.08242 3.5 5.08242C4.14258 5.08242 4.66758 5.60469 4.66758 6.25C4.66758 6.89531 4.14258 7.41758 3.5 7.41758ZM7 7.41758C6.35742 7.41758 5.83242 6.89531 5.83242 6.25C5.83242 5.60469 6.35469 5.08242 7 5.08242C7.64258 5.08242 8.16758 5.60469 8.16758 6.25C8.16758 6.89531 7.64258 7.41758 7 7.41758ZM10.5 7.41758C9.85742 7.41758 9.33242 6.89531 9.33242 6.25C9.33242 5.60469 9.85469 5.08242 10.5 5.08242C11.1426 5.08242 11.6676 5.60469 11.6676 6.25C11.6676 6.89531 11.1426 7.41758 10.5 7.41758Z'
            fill='#253920'
          />
          <circle cx='13.5' cy='2.5' r='2.5' fill='#00D09E' />
        </svg>
      )
    case 'downloadIco':
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <g clipPath='url(#clip0_123_32324)'>
            <path
              d='M6.72254 12.5674L11.5752 16.7497C11.6895 16.8483 11.8406 16.9032 11.9976 16.9032C12.1546 16.9032 12.3057 16.8483 12.42 16.7497L17.2727 12.5674C17.5208 12.3537 17.5329 11.996 17.2996 11.7687C17.0663 11.5413 16.6762 11.5303 16.4281 11.744L12.6141 15.0308V0.565166C12.6141 0.253025 12.3382 0 11.9976 0C11.657 0 11.381 0.253025 11.381 0.565166V15.0308L7.56715 11.7442C7.31912 11.5303 6.92897 11.5413 6.6956 11.7687C6.46243 11.9963 6.4745 12.3537 6.72254 12.5674Z'
              fill='#253920'
            />
            <path
              d='M20.9173 6H17.791C17.4504 6 17.1744 6.24005 17.1744 6.53619C17.1744 6.83239 17.4504 7.07239 17.791 7.07239H20.9173C21.9384 7.07346 22.7656 7.79292 22.7669 8.68097V21.319C22.7656 22.2071 21.9384 22.9265 20.9173 22.9276H3.08272C2.06155 22.9265 1.23432 22.2071 1.23309 21.319V8.68097C1.23432 7.79292 2.0616 7.07346 3.08272 7.07239H6.20923C6.54956 7.07239 6.82577 6.83239 6.82577 6.53619C6.82577 6.24005 6.54956 6 6.20923 6H3.08272C1.38094 6.00172 0.00191129 7.20107 0 8.68097V21.319C0.00191129 22.799 1.38094 23.9983 3.08272 24H20.9173C22.619 23.9983 23.998 22.799 24 21.319V8.68097C23.998 7.20107 22.619 6.00172 20.9173 6Z'
              fill='#253920'
            />
          </g>
          <defs>
            <clipPath id='clip0_123_32324'>
              <rect width='24' height='24' fill='white' />
            </clipPath>
          </defs>
        </svg>
      )
    case 'editIco':
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <path
            d='M0 0H20.2609V3.2H0V0ZM0 9.6H13.9294V12.8H0V9.6ZM0 19.2H8.86413V22.4H0V19.2ZM22.5402 14.24L23.8065 12.48C23.9311 12.304 24 12.0761 24 11.84C24 11.6039 23.9311 11.376 23.8065 11.2L21.5272 8.32C21.3879 8.16257 21.2075 8.07554 21.0207 8.07554C20.8338 8.07554 20.6534 8.16257 20.5141 8.32L19.2478 9.92L22.5402 14.24ZM18.3614 11.04L11.3967 19.68V24H14.6891L21.7804 15.2L18.3614 10.88V11.04Z'
            fill='#253920'
          />
        </svg>
      )
    default:
      return null
  }
}
export default OrderIcons
