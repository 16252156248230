import React, { useEffect, useState } from 'react'
import Modal from 'components/layout/Modal/Modal'
import Email2FaVerification from './Email2FaVerification'
import EnableGoogle2Fa from './EnableGoogle2Fa'
import DisableGoogle2Fa from './DisableGoogle2Fa'
import { useTranslation } from 'react-i18next'

const TwoFaStage = {
  ConfirmationByMailCode: 0,
  EnableConfirmationByTotpCode: 1,
  DisableConfirmationByTotpCode: 2,
}
export default function Google2FaSettingsModal({ opened, setOpened, isTwoFaEnabled, onTwoFAUpdate, timer, setTimer }) {
  const { t } = useTranslation()

  const [codeFromEmail, setCodeFromEmail] = useState(null)
  const [currentTwoFaStage, setCurrentTwoFaStage] = useState(0)

  const handleOnClose = (isUpdate2FAData) => {
    if (isUpdate2FAData) onTwoFAUpdate()
    setCodeFromEmail(null)
    setOpened(false)
  }

  useEffect(() => {
    if (!opened) return
  }, [])

  useEffect(() => {
    if (!opened) return

    if (!codeFromEmail) setCurrentTwoFaStage(TwoFaStage.ConfirmationByMailCode)
    else if (!isTwoFaEnabled) setCurrentTwoFaStage(TwoFaStage.EnableConfirmationByTotpCode)
    else if (isTwoFaEnabled) setCurrentTwoFaStage(TwoFaStage.DisableConfirmationByTotpCode)
  }, [codeFromEmail, isTwoFaEnabled])

  useEffect(() => {
    if (!opened) return
  }, [])

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={() => setOpened((prev) => handleOnClose())}>
      {currentTwoFaStage === TwoFaStage.ConfirmationByMailCode && (
        <Email2FaVerification
          t={t}
          timer={timer}
          setTimer={setTimer}
          onClose={(isUpdate2FaData = false) => handleOnClose(isUpdate2FaData)}
          setCodeFromEmail={setCodeFromEmail}
        />
      )}
      {currentTwoFaStage === TwoFaStage.EnableConfirmationByTotpCode && (
        <EnableGoogle2Fa
          t={t}
          onClose={(isUpdate2FaData = false) => handleOnClose(isUpdate2FaData)}
          codeFromEmail={codeFromEmail}
        />
      )}
      {currentTwoFaStage === TwoFaStage.DisableConfirmationByTotpCode && (
        <DisableGoogle2Fa
          t={t}
          onClose={(isUpdate2FaData = false) => handleOnClose(isUpdate2FaData)}
          codeFromEmail={codeFromEmail}
        />
      )}
    </Modal>
  )
}
