import { useTranslation } from 'react-i18next'
import React from 'react'
import Modal from 'components/layout/Modal/Modal'
import SubmitAndContactButtons from './SubmitAndContactButtons'

export default function HelpAmountLessOrMoreModal({ onConfirmHelpAmountLessOrMoreModal, opened, onClose, deal }) {
  if (!deal) return null

  const { t } = useTranslation()
  const isDirectionDealBuying = deal.isDirectionDealBuying
  const cause = isDirectionDealBuying ? 'P2PCauseTheCounterpartyPaidLessThanRequired' : 'P2PCauseIPaidMoreThanRequired'

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('GetHelp')}</h2>
          {isDirectionDealBuying ? (
            <p>
              <strong>{t('P2PCauseIPaidMoreThanRequired')}</strong>
              <br />
              <small className='transparentHalf'>{t('TryToResolveTheIssueByFollowingTheseSteps')}</small>
            </p>
          ) : (
            <p>
              <strong>{t('P2PCauseTheCounterpartyPaidLessThanRequired')}</strong>
              <br />
              <small className='transparentHalf'>{t('TryToResolveTheIssueByFollowingTheseSteps')}</small>
            </p>
          )}
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='wysiwyg'>
            {isDirectionDealBuying ? (
              <ol>
                <li>
                  <strong>{t('PlaceAnewOrderWithTheOVERPAIDAmountFromTheSameSeller')}</strong>
                </li>
                <li>
                  <strong>{t('IfYouAreUnableToPlaceAnewOrder')}</strong>
                </li>
              </ol>
            ) : (
              <ol>
                <li>
                  <strong>{t('SaveAscreenshotOfTheTransactionRecord')}</strong>
                </li>
                <li>
                  <strong>{t('ContactTheBuyerViaChatAndAskHimToPayTheRemainingAmount')}</strong>
                </li>
                <li>
                  <strong>{t('AlternativelyReturnThePaymentReceivedToTheBuyerAndAskHimToCancelTheOrder')}</strong>
                </li>
              </ol>
            )}
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='wysiwyg text-center transparentHalf'>
          {isDirectionDealBuying ? (
            <p>
              <strong>{t('PleaseNoteThatThereIsNoGuarantee')}</strong>
            </p>
          ) : (
            <p>
              <strong>{t('IfTheIssueCannotBeResolvedYouCanFileAnAppeal')}</strong>
            </p>
          )}
        </div>
        <SubmitAndContactButtons
          onContact={() => {
            onClose()
            onConfirmHelpAmountLessOrMoreModal('onContact')
          }}
          onSubmitAnAppeal={() => {
            onClose()
            onConfirmHelpAmountLessOrMoreModal('onSubmitAnAppeal', cause)
          }}
          onClose={onClose}
          deal={deal}
        />
      </div>
    </Modal>
  )
}
