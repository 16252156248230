import Input from 'components/form/Input/Input'
import React, { useEffect, useState } from 'react'
import useGoogle2FA from './useGoogle2FA'

export default function DisableGoogle2Fa({ t, onClose, codeFromEmail }) {
  const [totpCode, setTotpCode] = useState(null)
  const [isCodeFailed, setIsCodeFailed] = useState(false)
  const { isLoading, disable2FA } = useGoogle2FA()

  const handleTotpCode = (value) => {
    setTotpCode(value)
    setIsCodeFailed(false)
  }
  const handleConfirm = async () => {
    if (!totpCode || totpCode.length !== 6) {
      setIsCodeFailed(true)
      return
    }
    const result = await disable2FA(codeFromEmail, totpCode)
    if (!result) {
      setIsCodeFailed(true)
      return
    }
    onClose(true)
  }
  return (
    <React.Fragment>
      <div className='p2p__security_modal'>
        <div className='twoFA__title'>{t('P2P2FAAreYouSureYouWant')}</div>
        <div className=' p2p__security_container'>
          <div className='twoFA__step'>{t('P2P2FANote')}</div>
          <div className='twoFA__description'>{t('P2P2FAToEnsureTheSecurity')}</div>
          <div className='twoFA__step'>{t('P2PGoogleVerificationCode')}</div>
          <Input
            type='text'
            value={totpCode || ''}
            inputCallback={(e) => handleTotpCode(e)}
            addClasses={['w-100']}
            placeholder={t('P2PEnterGoogleAuthenticatorCode')}
          />
        </div>
        {isCodeFailed && <div className='error_message'>{t('P2P2FAVerificationCodeFailed')}</div>}
      </div>

      <div className='modal__footer'>
        <div className='formular'>
          <div className='row justify-center'>
            <div className='col col_6 col_mob-12'>
              <div className='form-item' onClick={() => handleConfirm()}>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className={`btn btn_tertiary w-100`} disabled={isLoading}>
                      <span className='btn__text'>{t('Delete')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='form-item' onClick={() => onClose()}>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100'>
                      <span className='btn__text'>{t('Close')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
