import React, { useEffect, useState } from 'react'
import ProgressIcons from '../ProgressIcons'
import { GetFilePreviewUrl } from './FileDownloader'

const FileTypes = {
  Image: 'image',
  Video: 'video',
  Other: 'other',
}

export default function FilePreview({ chatFile }) {
  const [previewData, setPreviewData] = useState(null)
  const [fileType, setFileType] = useState(null)

  const getFileType = (extension) => {
    const imageTypes = ['.jpg', '.jpeg', '.png', '.gif', '.webp']
    const videoTypes = ['.mp4', '.mov', '.avi', '.wmv']

    const ext = extension.toLowerCase()
    if (imageTypes.some((type) => ext.endsWith(type))) return FileTypes.Image
    if (videoTypes.some((type) => ext.endsWith(type))) return FileTypes.Video
    return FileTypes.Other
  }

  const openInNewWindow = () => {
    const img = new Image()
    img.src = previewData.url

    img.onload = () => {
      const width = img.width
      const height = img.height
      const newWindow = window.open()
      newWindow.document.write(
        `<img src="${previewData.url}" alt="${chatFile.fileName}" style=width=${width},height=${height}>`,
      )
    }
  }

  useEffect(() => {
    let ignore = false

    const loadPreview = async () => {
      if (!chatFile || !chatFile.id || !chatFile.extension || ignore) return

      try {
        const preview = await GetFilePreviewUrl(chatFile.id)

        setPreviewData(preview)
        setFileType(getFileType(chatFile.extension))
      } catch (error) {
        console.error('Error loading preview:', error)
      }
    }

    loadPreview()

    return () => {
      ignore = true
      if (previewData && previewData.url) {
        URL.revokeObjectURL(previewData.url)
      }
    }
  }, [chatFile])

  if (!previewData || !previewData.url || fileType === FileTypes.Other) {
    return null
  }

  const commonProps = {
    className: 'preview-media',
    title: chatFile.fileName,
  }

  if (fileType === FileTypes.Image) {
    return (
      <div className='preview-container' onClick={openInNewWindow}>
        <img {...commonProps} src={previewData.url} alt={chatFile.fileName} />
      </div>
    )
  }

  if (fileType === FileTypes.Video) {
    return (
      <div className='preview-container'>
        <video {...commonProps} src={previewData.url} controls controlsList='' />
      </div>
    )
  }

  return null
}
