import { useHistory } from 'react-router-dom'
import Main from 'pages/common/components/Main/Main'
import { useTranslation } from 'react-i18next'

import baseComponents from '../../baseComponents/BaseComponents.module.scss'
import styles from './Page404.module.scss'

export default function Page404() {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <Main>
      <div className={styles.planets}>
        <h3>{t('Oops')}</h3>
        <img src='/images/404.svg' alt='404' />
        <button
          className={baseComponents.button}
          onClick={() => {
            history.push('/')
          }}
        >
          {t('BackToMainPage')}
        </button>
      </div>
    </Main>
  )
}
