import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Input from 'components/form/Input/Input'
import Select from 'components/form/Select/Select'
import { findCommission } from 'helpers/helper'
import CommissionTypes from 'data/CommissionTypes'
import {
  BuySellTabIndex,
  DigitType,
  GetCommissionAmount,
  GetRoundedValue,
  HandleExchangeAssetChange,
  HandlePaymentAssetChange as HandlePaymentAssetChange,
} from '../PostingData'

export function Stage1({ onStage1DataSubmit, onButtonNextDisabled, stageData, commissionsObj }) {
  const { t } = useTranslation()
  const [selectedExchangeAsset, setSelectedExchangeAsset] = useState(stageData.selectedExchangeAsset)
  const [selectedPaymentAsset, setSelectedPaymentAsset] = useState(stageData.selectedPaymentAsset)
  const [selectedPair, setSelectedPair] = useState(stageData.selectedPair)
  const [selectedBuySellTabIndex, setSelectedBuySellTabIndex] = useState(stageData.selectedBuySellTabIndex)
  const [userSelectedTradeRate, setUserSelectedTradeRate] = useState(stageData.userSelectedTradeRate)
  const [amountOfSelectedExchangeAsset, setAmountOfSelectedExchangeAsset] = useState(
    stageData.amountOfSelectedExchangeAsset,
  )
  const [amountOfSelectedPaymentAsset, setAmountOfSelectedPaymentAsset] = useState(
    stageData.amountOfSelectedPaymentAsset,
  )
  const [isInputValueChanged, setIsInputValueChanged] = useState(false)

  const [commission, setCommission] = useState(stageData.commission)
  const [commissionAmount, setCommissionAmount] = useState(stageData.commissionAmount)

  const currencyPairCode = `${selectedPaymentAsset}_${selectedExchangeAsset}`

  const handleFocus = (event) => {
    event.target.dataset.initialValue = event.target.value
  }

  const handleBlur = (event) => {
    if (event.target.dataset.initialValue !== event.target.value) {
      setIsInputValueChanged(true)
    }
    delete event.target.dataset.initialValue
  }

  const shouldDisableNextButton = () => {
    const isBuying = stageData.selectedBuySellTabIndex === BuySellTabIndex.Buy
    const exchangeBalance = parseFloat(stageData.balanceOfSelectedExchangeAsset)
    const paymentBalance = parseFloat(stageData.balanceOfSelectedPaymentAsset)
    const shouldCheckExchangeBalance =
      stageData.selectedBuySellTabIndex === BuySellTabIndex.Sell ||
      stageData?.selectedPair?.currencyTo?.availableOnExchange
    const shouldCheckPaymentBalance = isBuying && stageData.selectedPair?.currencyTo?.availableOnExchange
    const exchangeAssetAmount = parseFloat(amountOfSelectedExchangeAsset)
    const comissionAmount = GetCommissionAmount(exchangeAssetAmount, commission)

    const isTradeRateInvalid = parseFloat(userSelectedTradeRate) === 0
    const isExchangeAmountInvalid = exchangeAssetAmount === 0
    const isPaymentAmountInvalid = parseFloat(amountOfSelectedPaymentAsset) === 0
    const isPaymentAssetInvalid = !selectedPaymentAsset || selectedPaymentAsset === ''
    const isExchangeBalanceNotAvailable = shouldCheckExchangeBalance && exchangeBalance === null
    const isPaymentBalanceNotAvailable = shouldCheckPaymentBalance && paymentBalance === null
    const isExchangeAmountExceedsBalance = shouldCheckExchangeBalance && exchangeAssetAmount > exchangeBalance
    const isPaymentAmountExceedsBalance =
      shouldCheckPaymentBalance && parseFloat(amountOfSelectedPaymentAsset) > paymentBalance
    const isCommissionExceedsAmount = exchangeAssetAmount - comissionAmount <= 0
    const isSumWithCommExceedsBalance = !isBuying && exchangeAssetAmount + comissionAmount > exchangeBalance

    return (
      isTradeRateInvalid ||
      isExchangeAmountInvalid ||
      isPaymentAmountInvalid ||
      isPaymentAssetInvalid ||
      isExchangeBalanceNotAvailable ||
      isPaymentBalanceNotAvailable ||
      isExchangeAmountExceedsBalance ||
      isPaymentAmountExceedsBalance ||
      isCommissionExceedsAmount ||
      isSumWithCommExceedsBalance
    )
  }

  const formDataToSubmit = () => {
    const dataToSubmit = {
      // currentStageIndex,
      selectedExchangeAsset,
      selectedPaymentAsset,
      selectedPair,
      selectedBuySellTabIndex,
      userSelectedTradeRate,
      amountOfSelectedExchangeAsset,
      amountOfSelectedPaymentAsset,
      commission,
      commissionAmount,
    }
    onStage1DataSubmit(dataToSubmit)
  }

  const getCommissionStr = () => {
    if (!commission) return `${t('Loading')}...`
    if (!parseFloat(amountOfSelectedExchangeAsset)) return ''
    const commAmount = GetCommissionAmount(amountOfSelectedExchangeAsset, commission)
    const isBuy = selectedBuySellTabIndex === BuySellTabIndex.Buy
    const finalAmount = isBuy
      ? parseFloat(amountOfSelectedExchangeAsset) - commAmount
      : parseFloat(amountOfSelectedExchangeAsset) + commAmount

    return `${finalAmount} ${selectedExchangeAsset}`
  }

  useEffect(() => {
    formDataToSubmit()
  }, [
    stageData.selectedBuySellTabIndex,
    selectedExchangeAsset,
    selectedPaymentAsset,
    selectedBuySellTabIndex,
    selectedPair,
    commission,
    commissionAmount,
    userSelectedTradeRate,
  ])

  useEffect(() => {
    if (!isInputValueChanged) return
    formDataToSubmit()
    setIsInputValueChanged(false)
  }, [isInputValueChanged])

  useEffect(() => {
    onButtonNextDisabled(shouldDisableNextButton())
  }, [
    stageData.selectedBuySellTabIndex,
    selectedExchangeAsset,
    selectedPaymentAsset,
    amountOfSelectedExchangeAsset,
    amountOfSelectedPaymentAsset,
    userSelectedTradeRate,
    stageData.balanceOfSelectedExchangeAsset,
    stageData.balanceOfSelectedPaymentAsset,
    selectedPair,
  ])

  useEffect(() => {
    // find commission object
    if (commissionsObj == null) return
    const type = selectedBuySellTabIndex === BuySellTabIndex.Buy ? CommissionTypes.P2pBuy : CommissionTypes.P2pSell
    const comm = findCommission(commissionsObj.commissions, type, currencyPairCode)
    setCommission(comm)
  }, [commissionsObj, selectedBuySellTabIndex, selectedExchangeAsset, selectedPaymentAsset])

  useEffect(() => {
    if (!selectedExchangeAsset || !selectedPaymentAsset || !stageData.selectedPair) return
    HandleExchangeAssetChange(amountOfSelectedExchangeAsset, stageData)
    HandlePaymentAssetChange(amountOfSelectedPaymentAsset, stageData)
    setUserSelectedTradeRate(GetRoundedValue(userSelectedTradeRate, DigitType.Price, stageData))
  }, [selectedExchangeAsset, selectedPaymentAsset, stageData.selectedPair])

  useEffect(() => {
    if (!selectedExchangeAsset || !selectedPaymentAsset || stageData.currencyPairs.length === 0) return
    const pair = stageData.currencyPairs.find(
      (pair) => pair.code === `${selectedPaymentAsset}_${selectedExchangeAsset}`,
    )
    setSelectedPair(pair)
  }, [selectedExchangeAsset, selectedPaymentAsset])

  return (
    <React.Fragment>
      <div className='steps__main'>
        <div className=' tabs'>
          <BuySellTabsHeader
            t={t}
            selectedBuySellTabIndex={selectedBuySellTabIndex}
            setSelectedBuySellTabIndex={setSelectedBuySellTabIndex}
            isEnableSelection={stageData.isEnableSelection}
          />
          <div className=' tabs__main'>
            <div className=' tabs__list'>
              <div className=' tabs__item'>
                <div className='wrapper'>
                  <div className='group'>
                    <div className='group__main'>
                      <div className='formular'>
                        <SelectedExchangeAsset
                          t={t}
                          selectedExchangeAsset={selectedExchangeAsset}
                          setSelectedExchangeAsset={setSelectedExchangeAsset}
                          selectedBuySellTabIndex={selectedBuySellTabIndex}
                          stageData={stageData}
                        />
                        <SelectedPaymentAsset
                          t={t}
                          selectedExchangeAsset={selectedExchangeAsset}
                          selectedPaymentAsset={selectedPaymentAsset}
                          setSelectedPaymentAsset={setSelectedPaymentAsset}
                          selectedBuySellTabIndex={selectedBuySellTabIndex}
                          stageData={stageData}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='group'>
                    <div className='group__main'>
                      <div className='formular'>
                        <div className='row'>
                          <div className='col col_4 col_desktop-6 col_tab-8 col_mob-12'>
                            <div className='row'>
                              <div className='col col_6'>
                                <div className='form-item'>
                                  <div className='form-item__header'>
                                    <span className='form-item__title'>{t('YourCourse')}</span>
                                  </div>
                                  <div className='form-item__main'>
                                    <div className='form-item__field'>
                                      <Input
                                        defaultValue='0'
                                        after={selectedPaymentAsset}
                                        value={userSelectedTradeRate}
                                        pattern={'[0-9]+([.,][0-9]{1,2})?'}
                                        validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                                        replaceSymbols={[
                                          { from: /,/, to: '.' },
                                          { from: /^(00)/, to: '0.0' },
                                        ]}
                                        inputCallback={(value) => {
                                          const rate = GetRoundedValue(value, DigitType.Price, stageData)
                                          setUserSelectedTradeRate(rate)
                                          var roundedOtcAssetAmount = HandlePaymentAssetChange(
                                            rate * amountOfSelectedExchangeAsset,
                                            stageData,
                                          )
                                          setAmountOfSelectedPaymentAsset(roundedOtcAssetAmount)
                                        }}
                                        addClass
                                        addClasses={['w-100']}
                                        onFocus={handleFocus}
                                        onBlur={(e) => {
                                          handleBlur(e)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col col_4 col_desktop-6 col_tab-8 col_mob-12'>
                            <div className='row'>
                              <div className='col col_6'>
                                <div className='form-item'>
                                  <div className='form-item__main'>
                                    <div className='form-item__field'>
                                      <Input
                                        defaultValue='0'
                                        after={selectedExchangeAsset}
                                        value={amountOfSelectedExchangeAsset}
                                        pattern={'[0-9]+([.,][0-9]{1,2})?'}
                                        validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                                        replaceSymbols={[
                                          { from: /,/, to: '.' },
                                          { from: /^(00)/, to: '0.0' },
                                        ]}
                                        inputCallback={(value) => {
                                          var roundedValue = HandleExchangeAssetChange(value, stageData)
                                          setAmountOfSelectedExchangeAsset(roundedValue)

                                          var roundedOtcAssetAmount = HandlePaymentAssetChange(
                                            roundedValue * userSelectedTradeRate,
                                            stageData,
                                          )
                                          setAmountOfSelectedPaymentAsset(roundedOtcAssetAmount)
                                        }}
                                        addClasses={['w-100']}
                                        onFocus={handleFocus}
                                        onBlur={(e) => {
                                          handleBlur(e)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_6'>
                                <div className='form-item'>
                                  <div className='form-item__main'>
                                    <div className='form-item__field'>
                                      <Input
                                        defaultValue='0'
                                        after={selectedPaymentAsset}
                                        value={amountOfSelectedPaymentAsset}
                                        pattern={'[0-9]+([.,][0-9]{1,2})?'}
                                        validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                                        replaceSymbols={[
                                          { from: /,/, to: '.' },
                                          { from: /^(00)/, to: '0.0' },
                                        ]}
                                        inputCallback={(value) => {
                                          var roundedValue = HandlePaymentAssetChange(value, stageData)
                                          setAmountOfSelectedPaymentAsset(roundedValue)

                                          var roundedExchangeAssetAmount = HandleExchangeAssetChange(
                                            roundedValue / userSelectedTradeRate,
                                            stageData,
                                          )
                                          setAmountOfSelectedExchangeAsset(roundedExchangeAssetAmount)
                                        }}
                                        addClasses={['w-100']}
                                        onFocus={handleFocus}
                                        onBlur={(e) => {
                                          handleBlur(e)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='row'>
                              <div className='col col_8 col_desktop-8 col_tab-8 col_mob-8'>
                                <div className='form-item'>
                                  <div className='form-item__footer'>
                                    <div className='form-item__data data'>
                                      <div className='data__item data__item_before'>
                                        <span className='data__text'>
                                          {t('Commission')} {commission?.percent}% ({t('Min')}. {commission?.minimum}{' '}
                                          {selectedExchangeAsset})
                                        </span>
                                      </div>
                                    </div>
                                    <div className='form-item__data data'>
                                      <div className='data__item data__item_before'>
                                        <span className='data__text' style={{ whiteSpace: 'nowrap' }}>
                                          {selectedBuySellTabIndex === BuySellTabIndex.Buy
                                            ? t('P2POrderAfterSubtractedCommission')
                                            : t('P2POrderAfterAddedCommission')}
                                          :
                                        </span>
                                      </div>
                                      <div className='data__item data__item_after'>
                                        <span className='data__text'>{getCommissionStr()}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const BuySellTabsHeader = ({ t, selectedBuySellTabIndex, setSelectedBuySellTabIndex, isEnableSelection }) => {
  const buySellTabs = ['WantToBuy', 'WantToSell']

  return (
    <div className=' tabs__header'>
      <div className='row'>
        <div className='col col_5 col_mob-8'>
          <div className=' tabs__list'>
            {buySellTabs.map((element, index) => {
              return (
                <button
                  className={`tabs__item w-100  ${
                    element === buySellTabs[selectedBuySellTabIndex] && 'tabs__item_active'
                  } ${element === 'WantToBuy' && 'tabs__item_buy'} ${element === 'WantToSell' && 'tabs__item_sell'}`}
                  onClick={() => setSelectedBuySellTabIndex(index)}
                  key={index}
                  disabled={!isEnableSelection}
                >
                  <span className='tabs__title'>{t(element)}</span>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
const SelectedExchangeAsset = ({
  t,
  selectedExchangeAsset,
  setSelectedExchangeAsset,
  selectedBuySellTabIndex,
  stageData,
}) => {
  const filteredExchangeAssetList =
    stageData.currencyPairs.Length == 0
      ? ['Loading']
      : Array.from(new Set(stageData.currencyPairs.map((pair) => pair.currencyFrom.code)))
  const exchangeAssets = stageData.isEnableSelection ? filteredExchangeAssetList : [selectedExchangeAsset]
  return (
    <div className='row'>
      <div className='col col_2 col_desktop-3 col_tab-4 col_mob-6'>
        <div className='form-item'>
          <div className='form-item__header'>
            {selectedBuySellTabIndex === BuySellTabIndex.Buy && (
              <span className='form-item__title'>{t('ReceiveActive')}</span>
            )}
            {selectedBuySellTabIndex === BuySellTabIndex.Sell && (
              <span className='form-item__title'>{t('GivesActive')}</span>
            )}
          </div>
          <div className='form-item__main'>
            <div className='form-item__field'>
              <Select
                list={exchangeAssets}
                value={selectedExchangeAsset}
                addClasses={['w-100']}
                filter={true}
                image={true}
                multiple={false}
                selectCallback={(value) => {
                  setSelectedExchangeAsset(value)
                }}
                disabled={!stageData.isEnableSelection}
              />
            </div>
          </div>
          <div className='form-item__data data'>
            <div className='data__item data__item_before'>
              <span className='data__text'>{t('Balance')}:</span>
            </div>
            <div className='data__item data__item_after'>
              <span className='data__text'>
                {stageData.balanceOfSelectedExchangeAsset !== null
                  ? `${stageData.balanceOfSelectedExchangeAsset} ${selectedExchangeAsset} `
                  : `${t('Loading')}... `}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const SelectedPaymentAsset = ({
  t,
  selectedExchangeAsset,
  selectedPaymentAsset,
  setSelectedPaymentAsset,
  selectedBuySellTabIndex,
  stageData,
}) => {
  const filteredOtcAssetList =
    stageData.currencyPairs.Length == 0
      ? ['Loading']
      : Array.from(
          new Set(
            stageData.currencyPairs
              .filter((pair) => !selectedExchangeAsset || pair.currencyFrom.code === selectedExchangeAsset)
              .map((pair) => pair.currencyTo.code),
          ),
        )
  return (
    <div className='row'>
      <div className='col col_2  col_desktop-3 col_tab-4 col_mob-6'>
        <div className='form-item'>
          <div className='form-item__header'>
            {selectedBuySellTabIndex === BuySellTabIndex.Buy && (
              <span className='form-item__title'>{t('GivesActive')}</span>
            )}
            {selectedBuySellTabIndex === BuySellTabIndex.Sell && (
              <span className='form-item__title'>{t('ReceiveActive')}</span>
            )}
          </div>
          <div className='form-item__main'>
            <div className='form-item__field'>
              <Select
                list={filteredOtcAssetList}
                value={selectedPaymentAsset}
                addClasses={['w-100']}
                filter={true}
                image={true}
                multiple={false}
                selectCallback={(value) => {
                  setSelectedPaymentAsset(value)
                }}
                disabled={!stageData.isEnableSelection}
              />
            </div>
          </div>
          {stageData.balanceOfSelectedPaymentAsset !== null && (
            <div className='form-item__data data'>
              <div className='data__item data__item_before'>
                <span className='data__text'>{t('Balance')}:</span>
              </div>
              <div className='data__item data__item_after'>
                <span className='data__text'>
                  {`${stageData.balanceOfSelectedPaymentAsset} ${selectedPaymentAsset}`}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
