import { Route, Redirect } from 'react-router-dom'
import { useAuth } from './auth'

export default function PrivateRoute({ component: Component, render, ...rest }) {
  let auth = useAuth()
  console.log(` --- PrivateRoute '${auth.isSignedIn}'`)

  if (!auth.isSignedIn && auth.isLoading) {
    return (
      <div>
        <h3>Loading...</h3>
      </div>
    )
  }

  const renderContent = (props) => {
    if (auth.isSignedIn) {
      return render ? render(props) : <Component {...props} />
    }
    return auth.signin()
  }
  return <Route {...rest} render={renderContent} />
}
