import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { toast } from 'react-toastify'

import { useAuth } from 'auth/auth'
import api from 'api'

import Modal from 'components/layout/Modal/Modal'
import Input from 'components/form/Input/Input'
import Select from 'components/form/Select/Select'
import { generateUUID } from 'three/src/math/MathUtils'

let check = (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
    <path
      d='M0 6C0 9.30662 2.69338 12 6 12C9.30662 12 12 9.30662 12 6C12 2.69338 9.30662 0 6 0C2.69338 0 0 2.69338 0 6ZM9.13329 5.13329L5.33333 8.94671L2.86671 6.46667L3.8 5.53338L5.33333 7.05329L8.20004 4.2L9.13329 5.13329Z'
      fill='#00D09E'
    />
  </svg>
)

export const GetOpenDealLimitKey = (key) => {
  switch (key) {
    case 'canOpenDealWithinTotalLimits':
      return `P2PCanOpenDealWithinTotalLimits`
  }
}
export default function ModalBuySell({ opened, setOpened, isDealUserBuy, order, balances, currencyPairs }) {
  const { t } = useTranslation()
  const history = useHistory()
  const auth = useAuth()

  const isBuy = isDealUserBuy

  const [requisites, setRequisites] = useState([])
  const [requisiteId, setRequisiteId] = useState(null)
  const [amountOfSelectedExchangeAsset, setAmountOfSelectedExchangeAsset] = useState('')
  const [amountOfSelectedPaymentAsset, setAmountOfSelectedPaymentAsset] = useState('')
  const [isDisabledBtn, setIsDisabledBtn] = useState(true)
  const [maxAvailableOrderExchangeAmount, setMaxAvailableOrderExchangeAmount] = useState(0)

  const [toastId, setToastId] = useState(null)
  const [messagesAboutLimits, setMessagesAboutLimits] = useState(null)

  useEffect(() => {
    let ignore = false
    if (order != null && auth.isSignedIn) {
      if (isBuy) {
        const orderRequisites = order?.requisites
        setRequisites(orderRequisites)
        if (orderRequisites?.length > 0) setRequisiteId(orderRequisites[0].id)
      } else {
        // load user requisites
        api.fetchP2PRequisites().then((requisitesData) => {
          if (ignore) return
          const orderPaymentSystemIds = order?.requisites.map((requisite) => requisite.paymentSystem.id)

          const allowedRequisites = requisitesData.filter((requisite) =>
            orderPaymentSystemIds.includes(requisite.paymentSystem.id),
          )
          setRequisites(allowedRequisites)
          if (allowedRequisites.length > 0) {
            setRequisiteId(allowedRequisites[0].id)
          }
        })
      }
    }
    setMaxAvailableOrderExchangeAmount(
      Math.min(order?.totalExchangeAmount - order?.filledExchangeAmount, order?.maxDealAmount),
    )
    return () => {
      ignore = true
    }
  }, [order])

  useEffect(() => {
    if (order == null) return
    setAmountOfSelectedExchangeAsset(order.minDealAmount)
    setAmountOfSelectedPaymentAsset(order.minDealAmount * order.price)
  }, [order])

  const paymentMethodColor = isBuy ? 'rgba(0,208,158,.08)' : '#ffebf1'

  const selectedPair = currencyPairs?.find(
    (pair) =>
      pair.currencyFrom.code === order?.exchangeCurrencyCode && pair.currencyTo.code === order?.paymentCurrencyCode,
  )
  const isPaymentCurrencyOnExchange = selectedPair?.currencyTo?.availableOnExchange
  const balanceOfSelectedCurrencies = order && {
    exchangeCurrency:
      (selectedPair?.currencyFrom?.availableOnExchange &&
        balances?.find((balance) => balance.currencyCode === selectedPair?.currencyFrom?.code)?.amount) ||
      0,
    paymentCurrency: isPaymentCurrencyOnExchange
      ? balances?.find((balance) => balance.currencyCode === selectedPair?.currencyTo?.code)?.amount || 0
      : null,
  }

  const digitsAmount = selectedPair?.digitsAmountP2P ?? 4

  const setAmount = (value, isExchangeAmount) => {
    if (isExchangeAmount) {
      handleExchangeAssetChange(value)
      handlePaymentAssetChange(value * order?.price)
    } else {
      handleExchangeAssetChange(value / order?.price)
      handlePaymentAssetChange(value)
    }
  }

  const setMaxAmount = () => {
    if (isBuy) return // only when selling on exchange can specify max crypto amount
    setAmountOfSelectedExchangeAsset(balanceOfSelectedCurrencies?.exchangeCurrency)
    setAmountOfSelectedPaymentAsset(
      (balanceOfSelectedCurrencies?.exchangeCurrency / order.price).toFixedTrimmed(digitsAmount),
    )
  }

  const createDeal = async () => {
    if (
      !auth.isSignedIn ||
      !order ||
      (!order.areBothCurrenciesExchange && !requisiteId) ||
      amountOfSelectedExchangeAsset <= 0
    )
      return

    const dealCreateDto = {
      orderId: order.id,
      requisiteId: requisiteId,
      exchangeAmount: amountOfSelectedExchangeAsset,
    }
    setIsDisabledBtn(true)

    api
      .createP2PDeal(dealCreateDto)
      .then((data) => {
        toast.success(t('ActionWasSuccessful'))
        history.push(`/${i18n.language}/P2P/progress/${data.id}`)
      })
      .catch((err) => {
        toast.error(t('ErrorTryLater'))
        setIsDisabledBtn(false)
      })
  }
  const handleExchangeAssetChange = (value) => {
    let maxValue =
      balanceOfSelectedCurrencies.exchangeCurrency && value > balanceOfSelectedCurrencies.exchangeCurrency
        ? balanceOfSelectedCurrencies.exchangeCurrency
        : value
    maxValue = maxValue > maxAvailableOrderExchangeAmount ? maxAvailableOrderExchangeAmount : maxValue

    const finalValue = getRoundedValue(maxValue)
    return finalValue
  }
  const handlePaymentAssetChange = (value) => {
    const rate = order?.price
    const maxPaymentAmount = maxAvailableOrderExchangeAmount * rate
    const paymentBalance = balanceOfSelectedCurrencies.paymentCurrency

    let maxValue = value

    if (isBuy) {
      maxValue = maxValue > maxPaymentAmount ? maxPaymentAmount : maxValue
    } else {
      const calculatedValue = maxValue / rate
      maxValue = calculatedValue > maxAvailableOrderExchangeAmount ? maxPaymentAmount : maxValue

      if (isPaymentCurrencyOnExchange && paymentBalance !== null) {
        maxValue = calculatedValue > paymentBalance ? paymentBalance * rate : maxValue
      }
    }

    const finalValue = getRoundedValue(maxValue)
    return finalValue
  }

  const getRoundedValue = (value) => {
    if (isFinite(value) && !isNaN(value)) {
      const [integerPart, decimalPart] = value.toString().split('.')
      if (decimalPart && decimalPart.length > digitsAmount) {
        return parseFloat(value).toFixed(digitsAmount)
      }

      return value
    }

    return 0
  }

  useEffect(() => {
    const hasValidData =
      order &&
      amountOfSelectedExchangeAsset &&
      amountOfSelectedPaymentAsset &&
      !isNaN(Number(amountOfSelectedExchangeAsset)) &&
      !isNaN(Number(amountOfSelectedPaymentAsset)) &&
      selectedPair

    if (!hasValidData) {
      setIsDisabledBtn(true)
      return
    }

    const exchangeAmount = parseFloat(amountOfSelectedExchangeAsset)
    const paymentAmount = parseFloat(amountOfSelectedPaymentAsset)

    const isExchangeAmountInvalid =
      exchangeAmount > maxAvailableOrderExchangeAmount || exchangeAmount < order?.minDealAmount

    const isExchangeBalanceExceeded = !isBuy && exchangeAmount > balanceOfSelectedCurrencies?.exchangeCurrency

    const isPaymentAmountExceeded =
      isPaymentCurrencyOnExchange && balanceOfSelectedCurrencies?.paymentCurrency < paymentAmount

    const result = isExchangeAmountInvalid || isExchangeBalanceExceeded || isPaymentAmountExceeded

    setIsDisabledBtn(result)
  }, [
    amountOfSelectedExchangeAsset,
    amountOfSelectedPaymentAsset,
    order,
    selectedPair,
    isBuy,
    balanceOfSelectedCurrencies,
    isPaymentCurrencyOnExchange,
  ])

  useEffect(() => {
    if (!opened) return
    api.fetchP2PUserDealOpeningLimits().then((limits) => {
      if (limits.canOpenDealWithinTotalLimits) return
      setMessagesAboutLimits(GetOpenDealLimitKey('canOpenDealWithinTotalLimits'))
    })
  }, [opened])

  useEffect(() => {
    if (messagesAboutLimits) {
      const id = toast.error(t(messagesAboutLimits), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        // closeButton: false,
        closeOnClick: false,
        // draggable: false,
      })
      setToastId(id)
      setIsDisabledBtn(true)
    } else if (!messagesAboutLimits) {
      toast.dismiss(toastId)
      setToastId(null)
      setIsDisabledBtn(false)
    }
  }, [messagesAboutLimits])

  return (
    <Modal opened={opened} blockStyle={'modal768'} modalCallback={() => setOpened((prev) => setOpened(false))}>
      <div className='modal__main'>
        <div className='modal__trading trading'>
          <div className='row'>
            <div className='col col_6 col_mob-12'>
              <div className='trading__information information'>
                <div className='information__about about'>
                  <div className='about__header'>
                    <div className='information__avatar avatar'>
                      <span className='avatar__text h2'>{order?.userNickname?.slice(0, 1) || ""}</span>
                    </div>
                    <div className='about__data'>
                      <div className='information__title'>
                        <h2>
                          <strong>{order?.userNickname}</strong>
                        </h2>
                      </div>
                      <div className='information__subtitle'>
                        <div className='wysiwyg'>
                          <p>
                            <small className='transparentHalf'>
                              {order?.userInfo?.completedRecentDealsCount} {t('Deal')} |{' '}
                              {(order?.userInfo?.completedRecentDealsPercentage * 100).toFixed(2)}% {t('done')}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='about__protection protection'>
                    <div className='protection__list'>
                      <div className='protection__item'>
                        {check}
                        <span className='protection__text'>Email</span>
                      </div>
                      <div className='protection__item'>
                        {check}
                        <span className='protection__text'>SMS</span>
                      </div>
                      <div className='protection__item'>
                        {check}
                        <span className='protection__text'>{t('IdentityVerification')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='information__main'>
                  <div className='data'>
                    <div className='data__list data__list_row'>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('Price')}</span>
                        </div>
                        <div className='data__main'>
                          <span className={`data__text h2 ${isBuy ? 'colorSecondary500' : 'colorTertiary600'}`}>
                            {order?.price} {order?.paymentCurrencyCode}
                          </span>
                        </div>
                      </div>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('Available')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>
                            {(order?.totalExchangeAmount - order?.filledExchangeAmount).toFixed(2)}{' '}
                            {order?.exchangeCurrencyCode}
                          </span>
                        </div>
                      </div>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('Limits')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>
                            {order?.minDealAmount} - {order?.maxDealAmount}
                          </span>
                        </div>
                      </div>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('PaymentTerm')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>
                            {order?.maxTimeMinutes} {t('minutes')}
                          </span>
                        </div>
                      </div>
                      {!order?.areBothCurrenciesExchange && (
                        <div className='data__item'>
                          <div className='data__header'>
                            <span className='data__title'>{t('ConverterPaymentMethod')}</span>
                          </div>
                          <div className='data__main'>
                            <div className='tags tags_secondary'>
                              <div className='tags__list justify-end'>
                                {order?.requisites.map((r, index) => (
                                  <div className='tags__item' key={index}>
                                    <span className='tags__text' style={{ backgroundColor: paymentMethodColor }}>
                                      {r.paymentSystem.name}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='data'>
                    <div className='data__list data__list_column'>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('TermsOfTheTransaction')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>{order?.description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='trading__formular'>
                <div className='formular'>
                  <div className='formular__main'>
                    <div className='form-item'>
                      <div className='form-item__header'>
                        <span className='form-item__title'>{t(isBuy ? 'IWantToBuy' : 'IWantToSell')}</span>
                      </div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            value={amountOfSelectedExchangeAsset}
                            replaceSymbols={[
                              { from: /,/, to: '.' },
                              { from: /^(00)/, to: '0.0' },
                            ]}
                            inputCallback={(value) => {
                              const roundedValue = handleExchangeAssetChange(value)
                              setAmountOfSelectedExchangeAsset(roundedValue)

                              var roundedOtcAssetAmount = handlePaymentAssetChange(roundedValue * order?.price)
                              setAmountOfSelectedPaymentAsset(roundedOtcAssetAmount)
                            }}
                            onBlur={(e) => setAmount(e.target.value, true)}
                            after={
                              `<span className='colorPrimary500'>${order?.exchangeCurrencyCode}</span>` +
                              `<span className='colorQuartyty500'>${isBuy ? '' : t('All')}</span>`
                            }
                            onClickAfter={() => setMaxAmount()}
                            placeholder={'0.00'}
                            addClasses={['w-100']}
                          />
                        </div>
                      </div>
                      <div className='form-item__footer'>
                        <div className='form-item__data data'>
                          <div className='data__item data__item_after'>
                            <span className='data__text'>
                              {t('Balance')}: {balanceOfSelectedCurrencies?.exchangeCurrency}{' '}
                              {order?.exchangeCurrencyCode}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-item__header'>
                        <span className='form-item__title'>{t(isBuy ? 'IGiveWay' : 'IWillGet')}</span>
                      </div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            value={amountOfSelectedPaymentAsset}
                            replaceSymbols={[
                              { from: /,/, to: '.' },
                              { from: /^(00)/, to: '0.0' },
                            ]}
                            inputCallback={(value) => {
                              const roundedValue = handlePaymentAssetChange(value)
                              setAmountOfSelectedPaymentAsset(roundedValue)

                              var roundedExchangeAssetAmount = handleExchangeAssetChange(roundedValue / order?.price)
                              setAmountOfSelectedExchangeAsset(roundedExchangeAssetAmount)
                            }}
                            onBlur={(e) => setAmount(e.target.value, false)}
                            after={`<span className='colorPrimary500'>${order?.paymentCurrencyCode}</span>`}
                            placeholder={'0.00'}
                            addClasses={['w-100']}
                          />
                        </div>
                      </div>
                      <div className='form-item__footer'>
                        <div className='form-item__data data'>
                          <div className='data__item data__item_after'>
                            <span className='data__text'>
                              {balanceOfSelectedCurrencies?.paymentCurrency !== null &&
                                `${t('Balance')}: ${balanceOfSelectedCurrencies?.paymentCurrency} ${
                                  order?.paymentCurrencyCode
                                }`}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='form-item__footer'>
                        <div className='form-item__data data'>
                          <div className='data__item data__item_after'>
                            <span className='data__text'>
                              {t('Commission')}: 0% ~0.00000000 {order?.exchangeCurrencyCode}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!order?.areBothCurrenciesExchange && (
                      <div className='form-item'>
                        <div className='form-item__header'>
                          <span className='form-item__title'>{t('ConverterPaymentMethod')}</span>
                        </div>
                        <div className='form-item__main'>
                          <div className='form-item__field'>
                            <Select
                              list={requisites.map((r) => `${r.paymentSystem.name} ${r.cardNumber}`)}
                              value={
                                requisites
                                  .filter((r) => r.id === requisiteId)
                                  .map((r) => `${r.paymentSystem.name} ${r.cardNumber}`)[0]
                              }
                              addClasses={['w-100']}
                              localizeOptions={false}
                              filter={true}
                              tagsColor={paymentMethodColor}
                              multiple={false}
                              selectCallback={(value) => {
                                const psText = value.split(' ')
                                const requisite = requisites.find((r) => r.cardNumber === psText[psText.length - 1])
                                setRequisiteId(requisite.id)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='formular__footer'>
                    <div className='form-item'>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <button
                            className={`btn w-100 ${isBuy ? 'btn_secondary' : 'btn_tertiary'}`}
                            onClick={() => createDeal()}
                            disabled={isDisabledBtn}
                          >
                            <span className='btn__text'>
                              {t(isBuy ? 'Buy' : 'Sell')} {order?.exchangeCurrencyCode}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <button
                            className='btn btn_revert w-100'
                            onClick={() => {
                              setMessagesAboutLimits(null)
                              setOpened((prevState) => !prevState)
                            }}
                          >
                            <span className='btn__text'>{t('Close')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
