import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import Icon from './OrderIcons'
import { DealStatusNumberToTextMap, GetDealAppealStatus } from './OrderAndDealData'
import padStart from 'lodash/padStart'
import i18n from 'i18next'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const RenderMyDeal = ({ deals }) => {
  const { t } = useTranslation()

  const history = useHistory()
  const goToDealProccess = (deal) => {
    history.push({
      pathname: `/${i18n.language}/P2P/progress/${deal.id}`,
    })
  }

  if (!deals || deals.length === 0) {
    return <div className='empty_space'>{t('P2PTheresNothingHereYet')}</div>
  }

  return (
    <React.Fragment>
      <div>
        {deals
          .slice()
          .sort((a, b) => moment(b.dateCreated).diff(moment(a.dateCreated)))
          .map((deal) => {
            const {
              price,
              exchangeAmount,
              paymentAmount,
              status,
              dateCreated,
              order,
              displayId,
              isDirectionDealBuying,
              isDealCreator,
              isAppealed,
              isAppealedAfterFinish,
            } = deal

            const numberDealStatus = isAppealed || isAppealedAfterFinish ? GetDealAppealStatus(deal) : status
            const textDealStatus = t(`P2PDealStatus_${DealStatusNumberToTextMap[numberDealStatus]}`)

            const formattedDateCreated = dateCreated ? moment(dateCreated).format('YYYY-MM-DD HH:mm:ss') : ''

            const isBuying = isDirectionDealBuying ? 'Buy' : 'Sell'
            const isBuyingColor = isDirectionDealBuying ? 'colorSecondary500' : 'colorTertiary500'
            const counterparty = isDealCreator ? order.userNickname : deal.userNickname
            return (
              <div key={deal.id} className='wrapper'>
                <div className='group'>
                  <div className='group__main group__main_transparent'>
                    <div className='datatext datatext'>
                      <div className='datatext__list'>
                        <div className='datatext__item'>
                          <div className='datatext__title'>
                            <span className={`datatext__text ${isBuyingColor} h2`}> {t(isBuying)}</span>
                          </div>
                        </div>
                        <div className='datatext__item'>
                          <div className='datatext__title'>
                            <span className='datatext__text small colorPrimary300 h4'>{formattedDateCreated}</span>
                          </div>
                        </div>
                        <div className='datatext__item ml-m-auto'>
                          <div className='datatext__title'>
                            <span className='datatext__text small colorPrimary300 h4'>
                              № {padStart(displayId.toString(), 15, '0')}
                            </span>
                          </div>
                          <div className='datatext__media'>
                            <Icon name='copyIco' textToCopy={padStart(displayId.toString(), 15, '0')} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='group'>
                  <div className='group__main group__main_transparent'>
                    <div className='row'>
                      <div className='col col_6'>
                        <div className='data'>
                          <div className='data__list data__list_column'>
                            <div className='row'>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('Pair')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='data__text'>
                                      {order.exchangeCurrencyCode}/{order.paymentCurrencyCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('Volume')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='data__text'>
                                      {exchangeAmount} {order.exchangeCurrencyCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('Counterparty')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='value__text'>{counterparty}</span>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('Rate')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='data__text'>
                                      {price} {order.exchangeCurrencyCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('P2PAmount')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='value__text'>
                                      {paymentAmount} {order.paymentCurrencyCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('Status')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='data__text colorQuartyty500'>{textDealStatus}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col col_6'>
                        <div className='p2p__chat p2p__chat_second chat'>
                          <div className='chat__widget'>
                            <span className='btn' onClick={() => goToDealProccess(deal)}>
                              <span className='btn__text'>{t('Details')}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </React.Fragment>
  )
}
export default RenderMyDeal
