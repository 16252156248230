import { useTranslation } from 'react-i18next'
import React from 'react'

export default function P2pBanner({ selectedExchangeCurrency, selectedPaymentCurrency }) {
  const { t } = useTranslation()
  return (
    <div className='p2p__banner banner'>
      <div className='banner__layout'>
        <div className='container'>
          <div className='banner__header'>
            <div className='wysiwyg'>
              <h2>
                {t('UseP2PToBuyAndSellUSDForRUB', {
                  exchangeCurrency: selectedExchangeCurrency,
                  otcCurrency: selectedPaymentCurrency,
                })}
              </h2>
            </div>
          </div>
          <div className='banner__main'>
            <div className='wysiwyg'>
              <p>{t('ConvenientPaymentMethods')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='banner__media media'>
        <div className='media__item media__item_before'></div>
        <div className='media__item media__item_after'></div>
      </div>
    </div>
  )
}
