import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { ShouldButtonBeDisabled, ProgressTimer } from '../ProgressTimer'
import Button from 'components/form/Button/Button'

const timeUntilUnlocking = 10

export default function SubmitAndContactButtons({ onClose, onContact, onSubmitAnAppeal, deal }) {
  if (!deal) return null

  const { t } = useTranslation()

  const dateCreated = deal.dateCreated
  const [isAppellationButtonDisabled, setIsAppellationButtonDisabled] = useState(true)

  useEffect(() => {
    const updateButtonDisabledState = () => {
      const disabled = ShouldButtonBeDisabled(dateCreated, timeUntilUnlocking)
      setIsAppellationButtonDisabled(disabled)
    }

    updateButtonDisabledState()
    const intervalId = setInterval(updateButtonDisabledState, 1000)

    return () => clearInterval(intervalId)
  }, [dateCreated, timeUntilUnlocking])

  return (
    <>
      <div className='formular m-0'>
        <div className='row'>
          <div className='col col_6'>
            <div className='form-item'>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <Button
                    buttonCallback={() => {
                      onSubmitAnAppeal()
                    }}
                    addClasses={['btn', 'w-100']}
                    text={isAppellationButtonDisabled ? t('FileAnAppealVia') : t('SubmitAnAppeal')}
                    disabled={isAppellationButtonDisabled}
                  >
                    {isAppellationButtonDisabled && (
                      <ProgressTimer initialMinutes={timeUntilUnlocking} dateCreated={dateCreated} />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='col col_6'>
            <div className='form-item'>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <button className='btn w-100' onClick={onContact}>
                    <span className='btn__text'>{t('ContactBuyer')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='wysiwyg text-center transparentHalf'>
        <p>
          <span className='link' onClick={onClose}>
            {t('Back')}
          </span>
        </p>
      </div>
    </>
  )
}
