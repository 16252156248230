import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Modal from '../../../../components/layout/Modal/Modal'
import UserIcons from '../UserIcons'
import { Icons } from 'react-toastify'
import api from 'api'

export default function MoreDataModal({ opened, setOpened, userInfo, ratingDescription }) {
  const { t } = useTranslation()
  const {
    userNickname = '',
    recentDealsCount = 0,
    completedRecentDealsCount = 0,
    allCompletedDealsCount = 0,
    completedBuysCount = 0,
    completedSellsCount = 0,
    completedRecentDealsPercentage = 0,
    likesCount = 0,
    dislikesCount = 0,
    ratingBasedOnReviews = 0,
    ratingBasedOnDealsAfterDisputes = 0,
    averageTransferTimeMins = 0,
    averagePaymentTimeMins = 0,
    accountAgeDays = 0,
    daysSinceFirstDeal = 0,
    recentTradingVolume = 0,
    totalTradingVolume = 0,
  } = userInfo || {}

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={() => setOpened((prev) => setOpened(false))}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('MoreData')}</h2>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='modal__database database'>
            <div className='database__list'>
              <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>{t('ExecutedDealsWithin30Days')}</span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>
                      {completedRecentDealsCount} {t('Deal')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>{t('AllExecutedDeals')}</span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>
                      {' '}
                      {allCompletedDealsCount} {t('Deal')}
                    </span>
                  </div>
                  <div className='database__data data'>
                    <div className='data__list'>
                      <div className='data__item '>
                        <div className='data__header'>
                          <span className='data__text'>Buy: {completedBuysCount}</span>
                        </div>
                      </div>
                      <div className='data__item data__item_secondary'>
                        <div className='data__header'>
                          <span className='data__text'>Sell: {completedSellsCount}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>{t('30DayCompletion')}</span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>{(completedRecentDealsPercentage * 100).toFixed(2)}%</span>
                  </div>
                </div>
              </div>
              <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>
                      {t(`P2PCurrentRating`)} - {t(`P2P${ratingDescription}`)} (%)
                    </span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>{(ratingBasedOnDealsAfterDisputes * 100).toFixed(2)}%</span>
                  </div>
                  <div className='database__data data'>
                    <div className='data__list'>
                      <div className='data__item '>
                        <div className='data__header'>
                          <span className='data__text'>
                            <UserIcons name={'ThumbUpIco'} /> {likesCount}
                          </span>
                        </div>
                      </div>
                      <div className='data__item data__item_secondary'>
                        <div className='data__header'>
                          <span className='data__text'>
                            <UserIcons name={'ThumbDownIco'} /> {dislikesCount}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>{t('VerifiedName')}</span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>{userNickname}</span>
                  </div>
                </div>
              </div>
              <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>{t('AvgTransferTime')}</span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>
                      {averageTransferTimeMins} {t('min')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>{t('AveragePaymentTime')}</span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>
                      {averagePaymentTimeMins} {t('min')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>{t('DaysSinceAccountCreation')}</span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>
                      {accountAgeDays} {t('day')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>{t('DaysSinceFirstTrade')}</span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>
                      {daysSinceFirstDeal} {t('day')}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>{t('TradingVolumeFor30Days')}</span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>{recentTradingVolume} USDT</span>
                  </div>
                </div>
              </div>
              <div className='database__item'>
                <div className='database__key'>
                  <div className='database__title title'>
                    <span className='title__text'>{t('NoteTotalTradingVolume')}</span>
                  </div>
                </div>
                <div className='database__value value'>
                  <div className='value__title title'>
                    <span className='title__text'>{totalTradingVolume} USDT</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row justify-center'>
            <div className='col col_6 col_mob-12'>
              <div className='form-item' onClick={() => setOpened(false)}>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100'>
                      <span className='btn__text'>{t('Ok')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
