import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import UserIcons from './UserIcons'
import moment from 'moment/moment'
import api from '../../../api'

export default function UserReviews() {
  const { t } = useTranslation()
  const [dealComments, setDealComments] = useState([])

  const [ratingPercentage, setRatingPercentage] = useState(0)
  const [likesCount, setLikesCount] = useState(0)
  const [dislikesCount, setDislikesCount] = useState(0)
  const [ratingDescription, setRatingDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const fetchReviews = async () => {
      try {
        const dealComments = await api.fetchP2PDealComments(null, false, true)
        setDealComments(dealComments)
      } catch (error) {
        console.error('Error Fetch Deal Comments:', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchReviews()
  }, [])

  useEffect(() => {
    const total = dealComments.reduce(
      (acc, comment) => {
        comment.isPositive ? acc.likes++ : acc.dislikes++
        return acc
      },
      { likes: 0, dislikes: 0 },
    )
    const ratingPercentage = dealComments.length > 0 ? (total.likes / dealComments.length) * 100 : 100
    const ratingDescription =
      ratingPercentage <= 80 ? 'Low' : ratingPercentage > 80 && ratingPercentage <= 90 ? 'Mid' : 'High'

    setLikesCount(total.likes)
    setDislikesCount(total.dislikes)
    setRatingPercentage(ratingPercentage.toFixed(2))
    setRatingDescription(ratingDescription)
  }, [dealComments])

  if (isLoading) {
    return (
      <React.Fragment>
        <div className='user__testimonials testimonials'>
          <div>Loading...</div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className='user__testimonials testimonials'>
        <div className='testimonials__header'>
          <div className='testimonials__rating rating'>
            <div className='rating__title title'>
              <span className='title__text'>
                {t(`P2PRatingBasedOnReviews`)} - {t(`P2P${ratingDescription}`)}
              </span>
            </div>
            <div className='rating__value value'>
              <span className='data__text'>{ratingPercentage}%</span>
            </div>
          </div>
          <div className='testimonials__statistics statistics'>
            <div className='statistics__list'>
              <div className='statistics__item statistics__item_like'>
                <span className='statistics__text'>
                  {t('Like')} {likesCount}
                </span>
              </div>
              <div className='statistics__item statistics__item_dislike'>
                <span className='statistics__text'>
                  {t('Dislike')} {dislikesCount}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='testimonials__main'>
          <div className='testimonials__list'>
            <RenderReviews t={t} dealComments={dealComments} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const RenderReviews = ({ t, dealComments }) => {
  return (
    <React.Fragment>
      {dealComments.map((comment) => (
        <div key={comment.id} className='testimonials__item testimonial'>
          <div className='row'>
            <div className='col col_6 col_mob-12'>
              <div className='testimonial__header'>
                <div className='testimonial__avatar'>
                  <UserIcons name={'avatar'} />
                </div>
                <div className='testimonial__data'>
                  <div className='testimonial__name name'>
                    <span className='name__text'>{comment.userNickname}</span>
                  </div>
                  <div className='testimonial__date date'>
                    <span className='date__text'>{moment(comment.dateCreated).format('YYYY-MM-DD HH:mm')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='testimonial__main'>
                <div className='testimonial__media'>
                  <UserIcons name={comment.isPositive ? 'ThumbUpIco' : 'ThumbDownIco'} />
                </div>
                <div className='testimonial__content'>
                  <div className='wysiwyg'>
                    <p>{comment.comment}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}
