import React, { useEffect, useState } from 'react'
import { calculateCommission } from 'helpers/helper'
import CommissionTypes from 'data/CommissionTypes'
import api from 'api'

const ProgressTradeDetails = ({ t, deal }) => {
  const { price, paymentAmount, exchangeAmount, order } = deal || {}
  const { paymentCurrencyCode, exchangeCurrencyCode } = order || {}
  const [commissionAmount, setCommissionAmount] = useState(null)
  const [commissionPercent, setCommissionPercent] = useState(null)
  const [commissionMinimum, setCommissionMinimum] = useState(null)

  useEffect(() => {
    let ignore = false
    api.fetchCommissions().then((data) => {
      if (ignore) return
      if (data == null || data.commissions == null || deal == null) return

      const type = order.isBuyingOnExchange ? CommissionTypes.P2pBuy : CommissionTypes.P2pSell
      const { percent, minimum } = calculateCommission(data.commissions, exchangeAmount, type, order.currencyPairCode)
      const commAmount = order.totalFeeAmount * (deal.exchangeAmount / order.totalExchangeAmount)
      setCommissionAmount(commAmount)
      setCommissionPercent(percent)
      setCommissionMinimum(minimum)
    })
    return () => {
      ignore = true
    }
  }, [])

  // only show commission for order creator
  let showCommission = deal.isOrderCreator

  return (
    <React.Fragment>
      <div className='group'>
        <div className='wysiwyg'>
          <h2>{t('OrderInformation')}</h2>
        </div>
        <div className='group__main'>
          <div className='data'>
            <div className='data__list data__list_column'>
              <div className='row'>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Price')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {price} {paymentCurrencyCode}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Volume')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text colorQuartyty500'>
                        {paymentAmount} {paymentCurrencyCode}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Amount')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {exchangeAmount} {exchangeCurrencyCode}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_3 col_mob-6'>
                  {showCommission && (
                    <div className='data__item'>
                      <div className='data__header'>
                        <span className='data__title'>{t('Commission')}</span>
                      </div>
                      <div className='data__main'>
                        <span className='data__text'>
                          {' '}
                          {commissionAmount ?? ''} {exchangeCurrencyCode}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgressTradeDetails
