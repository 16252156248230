import React, { useState, useEffect, useMemo } from 'react'
import TableItem from '../TableItem/TableItem'
import BalanceBlock from 'baseComponents/BalanceBlock/BalanceBlock'
import CryptoDeposit from '../CryptoDeposit/CryptoDeposit'
import CryptoWithdrawal from '../CryptoWithdrawal/CryptoWithdrawal'
import CashDeposit from '../CashDeposit/CashDeposit'
import CashWithdrawal from '../CashWithdrawal/CashWithdrawal'

import { cssClass, isProdEnv } from 'helpers/helper'
import api from 'api'
import { useTranslation } from 'react-i18next'
import Modal from 'baseComponents/Modal/Modal'
import { useLocation } from 'react-router-dom'
import pages from '../../../pages.module.scss'
import styles from './Balance.module.scss'

export default function Balance() {
  const { t } = useTranslation()

  const [params, setParams] = useState(useLocation().params)
  const [currentCurrency, setCurrentCurrency] = useState('')
  const [currentCurrencyData, setCurrentCurrencyData] = useState(null)
  const [method, setMethod] = useState('')
  const [balance, setBalance] = useState(null)

  const loadBalance = (ignore = false) => {
    api.fetchBalance().then((data) => {
      console.log('fetched balance', data)
      if (!ignore) setBalance(data)
    })
  }

  useEffect(() => {
    let ignore = false
    loadBalance(ignore)
    return () => {
      ignore = true
    }
  }, [])

  let balanceData = []
  if (balance) {
    balanceData = balance.currencies.map((item) => {
      item.free = balance.balances.find((_) => _.currencyCode == item.code)?.amount ?? 0
      item.blocked = balance.balancesBlockedInOrder.find((_) => _.currencyCode == item.code)?.amount ?? 0
      item.total = item.free + item.blocked
      return item
    })
    // let balanceDataOrdered = []
    // // add other currencies with balance > 0
    // for (let item of balanceData) if (item.total > 0) balanceDataOrdered.push(item)

    balanceData = balanceData
      .filter((_) => _ != null)
      .sort((a, b) => {
        if (a.code < b.code) return -1
        if (a.code > b.code) return 1
        return 0
      })
  }

  useEffect(() => {
    if (params !== undefined && !balance) {
      setCurrentCurrency(params.currency)
      setMethod(params.method)
      setCurrentCurrencyData(true)
    } else {
      setCurrentCurrencyData(balance?.currencies?.find((_) => _.code == currentCurrency))
    }
  }, [currentCurrency, method])
  return (
    <section className={pages.blockWrapper}>
      <h1 className={styles.title}>{t('MainAccount')}</h1>
      {/* <BalanceBlock /> */}
      <div className={styles.table}>
        <div className={cssClass(styles.tableRow, styles.tableTitleRow)}>
          <span className={styles.tableFirstTitle}>{t('Currency')}</span>
          <span className={styles.phoneHidden}>{t('Total')}</span>
          <span>{t('Available')}</span>
          <span className={styles.phoneVertHidden}>{t('InTheOrder')}</span>
          <span className={styles.titleDo}>{t('Action')}</span>
        </div>
        {balanceData.map((currency, i) => {
          const code = currency.code

          if (isProdEnv && code.includes('TST'))
            // hide test currencies in prod
            return <React.Fragment key={`balance_${code}`}></React.Fragment>

          return (
            <React.Fragment key={`balance_${code}`}>
              <TableItem selected={currentCurrency === code} currency={currency}>
                <Button
                  onClick={() => {
                    setCurrentCurrency(code)
                    setMethod('deposit')
                  }}
                  isActive={currentCurrency === code && method === 'deposit'}
                >
                  <p>{t('Deposit')}</p>
                  {/* <img src='/images/buttons/deposit.svg' alt='Deposit' /> */}
                </Button>
                <Button
                  onClick={() => {
                    setCurrentCurrency(code)
                    setMethod('withdrawal')
                  }}
                  isActive={currentCurrency === code && method === 'withdrawal'}
                >
                  <p>{t('Withdraw')}</p>
                  {/* <img src='/images/buttons/withdrawal.svg' alt='Withdrawal' /> */}
                </Button>
              </TableItem>
              {currentCurrencyData != null && (
                <React.Fragment>
                  {currentCurrency === code && method === 'deposit' && !currentCurrencyData.isFiat ? (
                    <CryptoDeposit
                      currency={balance?.currencies?.find((_) => _.code == code)}
                      close={() => setMethod('')}
                    />
                  ) : null}
                  {currentCurrency === code && method === 'withdrawal' && !currentCurrencyData.isFiat ? (
                    <CryptoWithdrawal
                      currency={balance?.currencies?.find((_) => _.code == code)}
                      freeBalance={currency.free}
                      loadBalance={loadBalance}
                      close={() => setMethod('')}
                    />
                  ) : null}
                  {currentCurrency === code && method === 'deposit' && currentCurrencyData.isFiat ? (
                    <CashDeposit
                      currency={balance?.currencies?.find((_) => _.code == code)}
                      close={() => setMethod('')}
                    />
                  ) : null}
                  {currentCurrency === code && method === 'withdrawal' && currentCurrencyData.isFiat ? (
                    <CashWithdrawal
                      currency={balance?.currencies?.find((_) => _.code == code)}
                      close={() => setMethod('')}
                    />
                  ) : null}
                </React.Fragment>
              )}
            </React.Fragment>
          )
        })}
      </div>
    </section>
  )
}

const Button = ({ children, isActive, onClick }) => {
  return (
    <>
      <div className={styles.buttonWrapper}>
        <button tabIndex='-1' onClick={() => onClick()} className={styles.button}>
          {children}
        </button>
        {isActive ? <img className={styles.triangle} src={'/images/icons/triangle.svg'} alt={'Указатель'} /> : null}
      </div>
    </>
  )
}
