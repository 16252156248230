import React, { useEffect, useState } from 'react'
import Input from 'components/form/Input/Input'
import Button from 'components/form/Button/Button'
import useGoogle2FA from './useGoogle2FA'

export default function Email2FaVerification({ t, timer, setTimer, setCodeFromEmail, onClose }) {
  const [emailCode, setEmailCode] = useState(null)
  const [isCodeFailed, setIsCodeFailed] = useState(false)

  const { isLoading, sendCodeToEmail, validateEmailCode } = useGoogle2FA()

  const handleEmailCodeChange = (code) => {
    setEmailCode(code)
    setIsCodeFailed(false)
  }

  const handleSendCodeToEmail = async () => {
    setTimer(60)
    await sendCodeToEmail()
    setIsCodeFailed(false)
    setCodeFromEmail(null)
  }

  const handleConfirm = async () => {
    if (!emailCode || emailCode.length !== 6) {
      setIsCodeFailed(true)
      return
    }
    const result = await validateEmailCode(emailCode)
    if (!result) {
      setIsCodeFailed(true)
      return
    }

    setCodeFromEmail(emailCode)
  }
  return (
    <div className='p2p__security_email_modal '>
      <div className='twoFA_email__title'>{t('P2PEmailVerification')}</div>
      <div className='p2p__security_container'>
        <div className='twoFA_email__step'>{t('P2PYouNeedToEnterASecurityCode')}</div>
        <div className='twoFA_email__description'>{t('P2PTheCodeWillBeSentTo')}</div>
        <div className='twoFA_email__step'>{t('P2PEmailVerificationCode')}</div>

        <div className='twoFA_email__code'>
          <Input
            type='text'
            value={emailCode || ''}
            inputCallback={(e) => handleEmailCodeChange(e)}
            addClasses={['w-100']}
            placeholder={t('P2PEnterEmailVerificationCode')}
          />
          <Button
            buttonCallback={() => handleSendCodeToEmail()}
            addClasses={[`btn btn_secondary-padding twoFA_email__btn ${timer > 0 && 'twoFA_email__btn_timer'}`]}
            text={`${t('GetCode')} ${timer > 0 ? `${timer} ${t('P2PSeconds')}` : ''}`}
            disabled={timer !== 0 || isLoading}
          />
        </div>
      </div>
      {isCodeFailed && <div className='twoFA_email__error_message'>{t('P2P2FAVerificationCodeFailed')}</div>}

      <div className='modal__footer'>
        <div className='formular'>
          <div className='row justify-center'>
            <div className='col col_6 col_mob-12'>
              <div className='form-item' onClick={() => handleConfirm()}>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className={`btn btn_secondary w-100`} disabled={isLoading}>
                      <span className='btn__text'>{t('Confirm')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='form-item' onClick={() => onClose()}>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100'>
                      <span className='btn__text'>{t('Close')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
