import Input from 'components/form/Input/Input'
import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import SecurityIcons from './SecurityIcons'
import useGoogle2FA from './useGoogle2FA'
import authApi from 'auth/api'

export default function EnableGoogle2Fa({ t, onClose, codeFromEmail }) {
  const { isLoading, enable2FA, fetchPreSharedCode } = useGoogle2FA()
  const [isClicked, setIsClicked] = useState(false)
  const [totpCode, setTotpCode] = useState(null)
  const [preShared, setPreShared] = useState(null)
  const [isTotpCodeFailed, setIsTotpCodeFailed] = useState(null)
  const [userEmail, setUserEmail] = useState('')

  const handleTotpCode = (value) => {
    setTotpCode(value)
    setIsTotpCodeFailed(false)
  }

  const handleConfirm = async () => {
    if (!totpCode || totpCode.length !== 6) {
      setIsTotpCodeFailed(true)
      return
    }
    const result = await enable2FA(codeFromEmail, totpCode)
    if (!result) {
      setIsTotpCodeFailed(true)
      return
    }
    onClose(true)
  }

  const handleCopyClick = () => {
    if (preShared) {
      navigator.clipboard
        .writeText(preShared)
        .then(() => {
          setIsClicked(true)
          setTimeout(() => setIsClicked(false), 500)
          console.log('Text copied to clipboard')
        })
        .catch((err) => {
          console.error('Error copying text: ', err)
        })
    }
  }

  useEffect(() => {
    if (!codeFromEmail) return
    const getPreSharedCode = async () => {
      const preSharedCode = await fetchPreSharedCode(codeFromEmail)
      setPreShared(preSharedCode)
    }
    getPreSharedCode()
  }, [codeFromEmail])

  useEffect(() => {
    authApi.fetchH2kUser().then((data) => {
      setUserEmail(data.email)
    })
  }, [])

  return (
    <React.Fragment>
      <div className='p2p__security_modal'>
        <div className='twoFA__title'>{t('P2PEnableGoogle2FA')}</div>
        <div className='p2p__security_container'>
          <div className='twoFA__step'>{t('P2PDownloadGoogleAuthenticator')}</div>
          <div className='twoFA__description'>{t('P2PGoogleAuthenticatorCanBe')}</div>
          <div className='twoFA__step'>{t('P2PAddCodePhrase')}</div>
          <div className='twoFA__description'>{t('P2PActivateToken')}</div>
          <div className='twoFA__description'>{t('P2PKeyPhraseRecovery')}</div>
          <div className='twoFA__step'>{t('P2PGoogleVerificationCode')}</div>

          <Input
            type='text'
            value={totpCode || ''}
            inputCallback={(e) => handleTotpCode(e)}
            addClasses={['w-100']}
            placeholder={t('P2PEnterGoogleAuthenticatorCode')}
          />
        </div>
        <div>
          <div className='twoFA__qrCode'>
            {preShared && <QRCode value={`otpauth://totp/${userEmail}?secret=${preShared}&issuer=H2k`} />}
          </div>
          {preShared && (
            <div className='twoFA__preShared'>
              {preShared}
              <div className={`copy_icon ${isClicked ? 'copied' : ''}`} onClick={handleCopyClick}>
                <SecurityIcons name={'copyIcon'} />
              </div>
            </div>
          )}
        </div>
        {isTotpCodeFailed && <div className='error_message'>{t('P2P2FAVerificationCodeFailed')}</div>}
      </div>

      <div className='modal__footer'>
        <div className='formular'>
          <div className='row justify-center'>
            <div className='col col_6 col_mob-12'>
              <div className='form-item' onClick={() => handleConfirm()}>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className={`btn btn_secondary w-100`} disabled={isLoading}>
                      <span className='btn__text'>{t('Confirm')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='form-item' onClick={() => onClose()}>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100'>
                      <span className='btn__text'>{t('Close')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
