import Main from 'pages/common/components/Main/Main.jsx'
import Sidebar from 'baseComponents/Sidebar/Sidebar.jsx'
import History from './History/History'
import { useTranslation } from 'react-i18next'

import styles from '../pages.module.scss'

export default function HistoryPage() {
  const { t } = useTranslation()
  return (
    <Main>
      <div className={styles.flow}>
        <h2 className={styles.title}>{t('PersonalCabinet')}</h2>
        <div className={styles.wrapper}>
          <Sidebar />
          <div className={styles.content}>
            <History />
          </div>
        </div>
      </div>
    </Main>
  )
}
