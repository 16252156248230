import React, { useEffect, useState } from 'react'
import ProgressIcons from './ProgressIcons'
import IconOrder from '../Order/OrderIcons'
import Tooltip from 'components/form/Tooltip/Tooltip'

const ProgressPaymentMethod = ({ t, deal }) => {
  const { isDirectionDealBuying, requisite, order } = deal
  const { id } = requisite || {}
  const { requisites } = order || []
  const [selectedSellerRequisite, setSelectedSellerRequisite] = useState({})
  const { bankName, cardNumber, paymentSystem, ownerName } = selectedSellerRequisite || {}
  const { name } = paymentSystem || {}

  const paymentMethodColor = isDirectionDealBuying ? 'rgba(0,208,158,.08)' : '#ffebf1'

  useEffect(() => {
    setSelectedSellerRequisite(requisite)
  }, [deal])

  return (
    <React.Fragment>
      <div className='group'>
        <div className='group__header'>
          <div className='wysiwyg'>
            <h2>{t('PaymentMethod')}</h2>
          </div>
        </div>
        <div className='group__main'>
          <div className='data'>
            <div className='data__list data__list_second data__list_column'>
              <div className='row'>
                <div className='col col_4 col_mob-6 col_mobp-12'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('PaymentMethod')}</span>
                    </div>
                    <div className='data__main'>
                      <div className=' tags tags_secondary'>
                        <div className='tags__list'>
                          <div className='tags__item'>
                            <span className='tags__text' style={{ backgroundColor: paymentMethodColor }}>
                              {name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col col_8 col_mob-6 col_mobp-12'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('FirstName')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>{ownerName}</span>
                      <span className='data__ico'>
                        <IconOrder name='copyIco' textToCopy={ownerName} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_4 col_mob-6 col_mobp-12'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('BankName')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>{bankName}</span>
                      <span className='data__ico'>
                        <IconOrder name='copyIco' textToCopy={bankName} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_8 col_mob-6 col_mobp-12'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <Tooltip
                        title={'AccountNumber'}
                        info={
                          [
                            // t(
                            //   'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque enim est quia quo suscipit.',
                            // ),
                            //todo
                          ]
                        }
                      />
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>{cardNumber}</span>
                      <span className='data__ico'>
                        <IconOrder name='copyIco' textToCopy={cardNumber} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgressPaymentMethod
