import moment from 'moment/moment'
import Icon from '../Order/OrderIcons'
import padStart from 'lodash/padStart'
import React, { useEffect, useState } from 'react'

const ProgressHeader = ({ t, deal }) => {
  const { displayId } = deal || {}
  const BuySellText = deal.isDirectionDealBuying ? 'Buy' : 'Sell'
  const isBuyingColor = deal.isDirectionDealBuying ? 'colorSecondary500' : 'colorTertiary500'
  const formattedDateCreated = deal?.dateCreated ? moment(deal.dateCreated).format('YYYY-MM-DD HH:mm:ss') : ''
  return (
    <React.Fragment>
      <div className='wrapper'>
        <div className='row align-center'>
          <div className='col col_8 col_tab-6 col_mob-12'>
            <div className='wysiwyg'>
              <h2>
                <span className={`datatext__text ${isBuyingColor} h2`}> {t(BuySellText)}</span>&nbsp;
                <span className='colorPrimary400'>{deal?.order?.exchangeCurrencyCode}</span>&nbsp;&nbsp;
              </h2>
            </div>
          </div>
          <div className='col col_4 col_tab-6 col_mob-12'>
            <div className='data'>
              <div className='data__list data__list_row'>
                <div className='data__item'>
                  <div className='data__header'>
                    <span className='data__title'>{t('TimeOfCreation')}</span>
                  </div>
                  <div className='data__main'>
                    <span className='data__text'>{formattedDateCreated}</span>
                  </div>
                </div>
                <div className='data__item'>
                  <div className='data__header'>
                    <span className='data__title'>{t('OrderNumber')}</span>
                  </div>
                  <div className='data__main'>
                    <span className='data__text'>{padStart(displayId, 15, '0')}</span>
                    <span className='data__ico'>
                      <Icon name='copyIco' textToCopy={padStart(displayId, 15, '0')} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgressHeader
