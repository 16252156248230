import React, { useEffect, useRef } from 'react'
const Modal = (props) => {
  const blurRef = useRef(null)
  const blockRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        blurRef.current &&
        blurRef.current.contains(event.target) &&
        blockRef.current &&
        !blockRef.current.contains(event.target)
      ) {
        props.modalCallback(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <React.Fragment>
      {props.opened && (
        <div ref={blurRef} className='p2p__modal modal'>
          <div ref={blockRef} className={`modal__layout ${props.blockStyle}`}>
            <div ref={blockRef} className='modal__action' onClick={() => props.modalCallback(false)}>
              <svg xmlns='http://www.w3.org/2000/svg' width='22' height='20' viewBox='0 0 22 20' fill='none'>
                <path d='M21 1L1 19M1 1L21 19' stroke='#253920' strokeLinecap='round' strokeLinejoin='round' />
              </svg>
            </div>
            {props.children}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
export default Modal
