import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { cssClass, calculateCommission, getCommissionInfoString } from 'helpers/helper'
import Selector from 'baseComponents/Selector/Selector'
import { useTranslation } from 'react-i18next'

import baseComponents from '../../../../baseComponents/BaseComponents.module.scss'
import styles from './CashDeposit.module.scss'
import CommissionTypes from 'data/CommissionTypes'
import api from 'api'
import PublicID from '../PublicID/PublicID'
import { toast } from 'react-toastify'
import i18n from 'i18next'

export default function CashDeposit({ currency, close }) {
  const {t} = useTranslation()
  const history = useHistory()

  const selectItems = ['CashIstanbul']
  const [method, setMethod] = useState(selectItems[0])
  const [expand, setExpand] = useState(false)
  const [stage, setStage] = useState(1)
  const [sum, setSum] = useState(0)
  const [publicID, setPublicID] = useState('')
  const [commissionArr, setCommissionArr] = useState([])

  useEffect(() => {
    setExpand(false)
  }, [method])

  useEffect(() => {
    api.fetchCommissions().then((data) => {
      setCommissionArr(data?.commissions)
    })
  }, [])

  return (
    <div className={styles.block}>
      <h2 className={styles.title}>{t('DepositTo', { currency: currency.code })}</h2>

      {t(method) === t(selectItems[0]) ? (
        <>
          {stage === 1 ? (
            <>
              <div className={styles.methodWrapper}>
                <h3 className={cssClass(styles.methodTitle, styles.title1)}>{t('TopUpMethod')}</h3>
                <h3 className={cssClass(styles.methodTitle, styles.title2)}>{t('Conditions')}</h3>
                <Selector list={selectItems} value={method} selectNewItem={(value) => setMethod(value)} />
              </div>

              <DepositStambulForm
                currency={currency.code}
                setStage={() => setStage(2)}
                onSetSum={(e) => setSum(e)}
                commissionArr={commissionArr}
                commissionInfoString={getCommissionInfoString(
                  commissionArr,
                  CommissionTypes.Deposit,
                  currency?.code,
                  null,
                )}
              />
            </>
          ) : null}

          {stage === 2 ? (
            <SecondStageStambul
              sum={sum}
              setStage={() => setStage(1)}
              publicId={publicID}
              currencyCode={currency.code}
              close={close}
            />
          ) : null}
        </>
      ) : (
        <>
          <div className={styles.methodWrapper}>
            <h3 className={cssClass(styles.methodTitle, styles.title1)}>{t('TopUpMethod')}</h3>
            <h3 className={cssClass(styles.methodTitle, styles.title2)}>{t('Conditions')}</h3>
            <Selector list={selectItems} value={method} selectNewItem={(value) => setMethod(value)} />
            <DepositInstructions />
          </div>
          <form className={styles.depositBlazarexTRYWrapper}>
            <h3 className={cssClass(styles.methodTitle, styles.firstTitle)}>{t('TopUpCode')}</h3>
            <input
              className={cssClass(baseComponents.input, styles.input, styles.firstInput)}
              value='163qwe56rty78uio89asdf123gh56hj78'
            />
            <h3
              onClick={() => history.push(`/${i18n.language}/commissions`)}
              className={cssClass(styles.methodTitle, styles.secondTitle, baseComponents.pointer)}
            >
              {t('Commission')}
            </h3>
            <input disabled className={cssClass(styles.disabledInput, styles.secondInput)} placeholder='0%' />
            <button
              onClick={(e) => {
                e.preventDefault()
              }}
              className={cssClass(
                baseComponents.buttonColored,
                baseComponents.buttonGreen,
                styles.buttonDepositStambul,
              )}
            >
              {t('Confirm')}
            </button>
          </form>
        </>
      )}

      <PublicID onPublicIDChange={(id) => setPublicID(id)} />
    </div>
  )
}

const SecondStageStambul = ({ sum, setStage, publicId, currencyCode, close }) => {
  const { t } = useTranslation()
  const [isDisabled, setDisabled] = useState(false)
  const stage1 = (e) => {
    e.preventDefault()
    setStage()
  }

  const handleCashDeposit = (e) => {
    e.preventDefault()
    setDisabled(true)
    const data = {
      publicId: publicId,
      currencyCode: currencyCode,
      amount: sum,
    }
    api
      .createCashDeposit(data)
      .then(() => {
        toast.success(t('CreateCashDeposit_Success'))
        close()
      })
      .catch(() => {
        toast.error(t('CreateCashDeposit_Error'))
      })
      .finally(() => {
        setDisabled(false)
      })
  }

  return (
    <form className={styles.depositStambul2stageWrapper}>
      <h3 className={cssClass(styles.methodTitle, styles.firstTitle)}>{t('AmountToBePaid')}</h3>
      <input
        className={cssClass(baseComponents.input, styles.input, styles.firstInput)}
        disabled
        // className={cssClass(styles.disabledInput, styles.firstInput)}
        placeholder={sum}
      />
      <h3 className={cssClass(styles.methodTitle, styles.secondTitle)}>{t('Instruction')}</h3>
      <div className={cssClass(styles.instructionsWrapper, styles.paragraph)}>
        <p className={styles.instructionsItem}>{t('DepositStambuSecondStagelInstructions')}</p>
      </div>
      <div className={styles.buttonsWrapper}>
        <button
          onClick={handleCashDeposit}
          disabled={isDisabled}
          className={cssClass(baseComponents.buttonColored, baseComponents.buttonGreen, styles.buttonDepositStambul)}
        >
          {t('Confirm')}
        </button>
        <button
          onClick={(e) => stage1(e)}
          disabled={isDisabled}
          className={cssClass(baseComponents.buttonColored, baseComponents.buttonRed, styles.buttonDepositStambul)}
        >
          {t('CancelVerb')}
        </button>
      </div>
    </form>
  )
}

const DepositStambulForm = ({
  setStage,
  currency,
  onSetSum,
  commissionArr,
  commissionInfoString,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [commission, setCommission] = useState(0)
  const [sum, setSum] = useState(0)

  const handleChangePayment = (e) => {
    setSum(e.target.value)
    onSetSum(e.target.value)
    const { commAmount } = calculateCommission(commissionArr, e.target.value, CommissionTypes.Deposit, currency, null)
    setCommission(commAmount)
  }
  const stage2 = (e) => {
    e.preventDefault()
    setStage()
  }

  return (
    <form className={styles.depositStambulWrapper}>
      <h3 className={cssClass(styles.methodTitle, styles.firstTitle)}>{t('AmountToBePaid')}</h3>
      <input
        className={cssClass(baseComponents.input, styles.input, styles.firstInput)}
        placeholder={sum}
        type={'number'}
        onChange={handleChangePayment}
      />
      <h3
        onClick={() => history.push('/commissions')}
        className={cssClass(styles.methodTitle, styles.secondTitle, baseComponents.pointer)}
      >
        {t('Commission')}
      </h3>
      <input
        disabled
        className={cssClass(styles.disabledInput, styles.secondInput)}
        style={{ overflow: 'visible' }}
        placeholder=''
        value={commissionInfoString}
      />

      <h3 className={cssClass(styles.methodTitle, styles.thirdTitle)}>{t('AmountDeposited')}</h3>
      <input
        disabled
        type={'number'}
        className={cssClass(styles.disabledInput, styles.thirdInput)}
        value={commission === null ? '' : sum - commission}
      />
      <button
        onClick={(e) => {
          stage2(e)
        }}
        className={cssClass(baseComponents.button, styles.buttonDepositStambul)}
      >
        {t('Next')}
      </button>
    </form>
  )
}

const DepositInstructions = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.instructionsWrapper}>
      <p className={styles.instructionsItem}>{t('CommissionText')}</p>
    </div>
  )
}
