import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import Tooltip from 'components/form/Tooltip/Tooltip'
import Input from 'components/form/Input/Input'
import Select from 'components/form/Select/Select'
import NavigationButtons from '../NavigationButtons'
import SelectPaymentMethodsModal from '../Modals/SelectPaymentMethodsModal'
import PostingIcon from './PostingIcons'
import plusImg from './images/plus.svg'
import closeImg from './images/close.svg'
import api from '../../../../api'
import CommonModals from '../../Progress/Modals/CommonsMadals'
import useModalState from '../../Progress/Modals/useModalState'
import { useHistory } from 'react-router-dom'
import {
  BuySellTabIndex,
  DigitType,
  GetCommissionAmount,
  GetRoundedValue,
  HandleExchangeAssetChange,
  HandlePaymentAssetChange,
} from '../PostingData'

export function Stage2({ onStage2DataSubmit, onButtonNextDisabled, stageData, onSaveStageData, paymentRequisites }) {
  const { t } = useTranslation()
  const history = useHistory()
  const { modals, openModal, closeModal } = useModalState()
  const {
    selectedBuySellTabIndex,
    userSelectedTradeRate,
    selectedPaymentAsset,
    selectedExchangeAsset,
    balanceOfSelectedPaymentAsset,
    balanceOfSelectedExchangeAsset,
    selectedPair,
    commission,
  } = stageData

  const [selectedPaymentRequisites, setSelectedPaymentRequisites] = useState(stageData.selectedPaymentRequisites || [])
  const [limitMin, setLimitMin] = useState(stageData.limitMin)
  const [limitMax, setLimitMax] = useState(stageData.amountOfSelectedExchangeAsset)

  const [selectedPaymentDuration, setSelectedPaymentDuration] = useState(stageData.selectedPaymentDuration)

  const [amountOfSelectedExchangeAsset, setAmountOfSelectedExchangeAsset] = useState(
    stageData.amountOfSelectedExchangeAsset,
  )
  const [amountOfSelectedPaymentAsset, setAmountOfSelectedPaymentAsset] = useState(
    stageData.amountOfSelectedPaymentAsset,
  )

  const [commissionAmount, setCommissionAmount] = useState(stageData.commissionAmount)

  const durationList = [15, 30, 60]
  const [isPaymentMethodsModalOpen, setIsPaymentMethodsModalOpen] = useState(stageData.isPaymentMethodsModalOpen)
  const [isLoading, setIsLoading] = useState(false)

  const [isInputValueChanged, setIsInputValueChanged] = useState(false)
  const [isPaymentMethodsSelectionAvailable, setIsPaymentMethodsSelectionAvailable] = useState(false)

  const handleFocus = (event) => {
    event.target.dataset.initialValue = event.target.value
  }

  const handleBlur = (event) => {
    if (event.target.dataset.initialValue !== event.target.value) {
      setIsInputValueChanged(true)
    }
    delete event.target.dataset.initialValue
  }

  const handleRemoveMethod = (methodId) => {
    setSelectedPaymentRequisites((currentRequisites) =>
      currentRequisites.map((method) => (method.id === methodId ? { ...method, selected: false } : method)),
    )
  }

  const goToUserPage = () => {
    history.push({
      pathname: `/${i18n.language}/${'P2P/user'}`,
    })
  }

  const checkNextButtonDisabled = () => {
    const isBuying = stageData.selectedBuySellTabIndex === BuySellTabIndex.Buy
    const exchangeBalance = parseFloat(stageData.balanceOfSelectedExchangeAsset)
    const paymentBalance = parseFloat(stageData.balanceOfSelectedPaymentAsset)
    const shouldCheckExchangeBalance =
      stageData.selectedBuySellTabIndex === BuySellTabIndex.Sell ||
      stageData?.selectedPair?.currencyTo?.availableOnExchange
    const shouldCheckPaymentBalance = isBuying && stageData.selectedPair?.currencyTo?.availableOnExchange
    const exchangeAssetAmount = parseFloat(amountOfSelectedExchangeAsset)
    const comissionAmount = GetCommissionAmount(exchangeAssetAmount, commission)

    const isTradeRateInvalid = parseFloat(userSelectedTradeRate) === 0
    const isExchangeAmountInvalid = exchangeAssetAmount === 0
    const isPaymentAmountInvalid = parseFloat(amountOfSelectedPaymentAsset) === 0
    const isPaymentAssetInvalid = !selectedPaymentAsset || selectedPaymentAsset === ''
    const isExchangeBalanceNotAvailable = shouldCheckExchangeBalance && exchangeBalance === null
    const isPaymentBalanceNotAvailable = shouldCheckPaymentBalance && paymentBalance === null
    const isExchangeAmountExceedsBalance = shouldCheckExchangeBalance && exchangeAssetAmount > exchangeBalance
    const isPaymentAmountExceedsBalance =
      shouldCheckPaymentBalance && parseFloat(amountOfSelectedPaymentAsset) > paymentBalance
    const isCommissionExceedsAmount = exchangeAssetAmount - comissionAmount <= 0
    const isSumWithCommExceedsBalance = !isBuying && exchangeAssetAmount + comissionAmount > exchangeBalance

    const isMinLimitInvalid = parseFloat(limitMin) === 0 || parseFloat(limitMin) > parseFloat(limitMax)
    const isMaxLimitInvalid = parseFloat(limitMax) === 0 || parseFloat(limitMax) < parseFloat(limitMin)

    const isInvalidPaymentMethods =
      isPaymentMethodsSelectionAvailable &&
      (!selectedPaymentRequisites ||
        selectedPaymentRequisites.length == 0 ||
        selectedPaymentRequisites.every((item) => item.selected !== true))

    return (
      isTradeRateInvalid ||
      isExchangeAmountInvalid ||
      isPaymentAmountInvalid ||
      isPaymentAssetInvalid ||
      isExchangeBalanceNotAvailable ||
      isPaymentBalanceNotAvailable ||
      isExchangeAmountExceedsBalance ||
      isPaymentAmountExceedsBalance ||
      isCommissionExceedsAmount ||
      isSumWithCommExceedsBalance ||
      isMinLimitInvalid ||
      isMaxLimitInvalid ||
      isInvalidPaymentMethods
    )
  }
  const formDataToSubmit = () => {
    const dataToSubmit = {
      // currentStageIndex,
      // paymentRequisites,
      selectedPaymentRequisites: selectedPaymentRequisites.filter((pr) => pr.selected),
      limitMin,
      limitMax,
      selectedPaymentDuration,
      amountOfSelectedExchangeAsset,
      amountOfSelectedPaymentAsset,
      // commission,
      commissionAmount,
      // selectedExchangeAsset,
      // userSelectedTradeRate,
      isPaymentMethodsModalOpen,
    }
    onStage2DataSubmit(dataToSubmit)
  }

  useEffect(() => {
    formDataToSubmit()
  }, [
    stageData.currentStageIndex,
    selectedPaymentRequisites,
    selectedPaymentDuration,
    commissionAmount,
    isPaymentMethodsModalOpen,
  ])

  useEffect(() => {
    if (!isInputValueChanged) return
    formDataToSubmit()
    setIsInputValueChanged(false)
  }, [isInputValueChanged])

  useEffect(() => {
    onButtonNextDisabled(checkNextButtonDisabled())
  }, [
    stageData.selectedBuySellTabIndex,
    selectedPaymentRequisites,
    limitMin,
    limitMax,
    amountOfSelectedExchangeAsset,
    amountOfSelectedPaymentAsset,
    isPaymentMethodsSelectionAvailable,
  ])

  useEffect(() => {
    if (!commission) return
    const commAmount = GetCommissionAmount(amountOfSelectedExchangeAsset, commission)
    setCommissionAmount(commAmount.toFixed(2))
  }, [amountOfSelectedExchangeAsset, commission])

  useEffect(() => {
    const isPaymentMethodsSelectionAvailable = selectedPair && !selectedPair.currencyTo.availableOnExchange
    setIsPaymentMethodsSelectionAvailable(isPaymentMethodsSelectionAvailable)
  }, [stageData.selectedPair])

  return (
    <React.Fragment>
      <div className='steps__main'>
        <div className='wrapper'>
          <div className='group group_secondary'>
            <div className='formular'>
              <div className='row'>
                <div className='col col_5 col_desktop-6 col_tab-8 col_mob-12'>
                  <div className='form-items nowrap'>
                    <div className='form-item w-100'>
                      {selectedBuySellTabIndex === BuySellTabIndex.Buy && (
                        <span className='form-item__title'>{t('ReceiveActive')}</span>
                      )}
                      {selectedBuySellTabIndex === BuySellTabIndex.Sell && (
                        <span className='form-item__title'>{t('GivesActive')}</span>
                      )}
                      <div className='form-item__header'>
                        <Tooltip
                          title={'Available'}
                          info={[t('SpecifyHowMuch', { currency: selectedExchangeAsset })]}
                        />
                      </div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            after={selectedExchangeAsset}
                            value={amountOfSelectedExchangeAsset}
                            pattern={'[0-9]+([.][0-9]{1,2})?'}
                            validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                            replaceSymbols={[
                              { from: /,/, to: '.' },
                              { from: /^(00)/, to: '0.0' },
                            ]}
                            inputCallback={(value) => {
                              const roundedValue = HandleExchangeAssetChange(value, stageData)
                              setAmountOfSelectedExchangeAsset(roundedValue)

                              var roundedOtcAssetAmount = HandlePaymentAssetChange(
                                roundedValue * userSelectedTradeRate,
                                stageData,
                              )
                              setAmountOfSelectedPaymentAsset(roundedOtcAssetAmount)
                              const maxLimit =
                                limitMax > amountOfSelectedExchangeAsset ? amountOfSelectedExchangeAsset : limitMax
                              setLimitMax(maxLimit)
                            }}
                            addClasses={['w-100']}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className='form-item__footer'>
                        <div className='form-item__data data'>
                          <div className='data__item data__item_before'>
                            <span className='data__text'>{t('Balance')}:</span>
                          </div>
                          <div className='data__item data__item_after'>
                            <span className='data__text'>
                              {balanceOfSelectedExchangeAsset} {selectedExchangeAsset}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-item p-0'>
                      <div className='form-item__header'></div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <div className='form-item__ico'>
                            <PostingIcon name='ico01' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-item w-100'>
                      {selectedBuySellTabIndex === BuySellTabIndex.Buy && (
                        <span className='form-item__title'>{t('GivesActive')}</span>
                      )}
                      {selectedBuySellTabIndex === BuySellTabIndex.Sell && (
                        <span className='form-item__title'>{t('ReceiveActive')}</span>
                      )}
                      <div className='form-item__header'></div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            after={selectedPaymentAsset}
                            value={amountOfSelectedPaymentAsset}
                            pattern={'[0-9]+([.][0-9]{1,2})?'}
                            validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                            replaceSymbols={[
                              { from: /,/, to: '.' },
                              { from: /^(00)/, to: '0.0' },
                            ]}
                            inputCallback={(value) => {
                              const roundedValue = HandlePaymentAssetChange(value, stageData)
                              setAmountOfSelectedPaymentAsset(roundedValue)

                              var roundedExchangeAssetAmount = HandleExchangeAssetChange(
                                roundedValue / userSelectedTradeRate,
                                stageData,
                              )
                              setAmountOfSelectedExchangeAsset(roundedExchangeAssetAmount)
                            }}
                            addClasses={['w-100']}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className='form-item__footer'>
                        <div className='form-item__data data'>
                          <div className='data__item data__item_after'>
                            <span className='data__text'>
                              {balanceOfSelectedPaymentAsset} {selectedPaymentAsset}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col col_2 col_desktop-3 col_tab-6'>
                  <div className='form-item w-100'>
                    <div className='form-item__header'>
                      <div className='form-item__header'></div>
                      <span className='form-item__title'>
                        {t('Commission')} {commission?.percent}%. ({t('Min')}. {commission?.minimum}{' '}
                        {selectedExchangeAsset})
                      </span>
                    </div>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Input
                          after={selectedExchangeAsset}
                          value={commissionAmount || ''}
                          disabled={true}
                          addClasses={['w-100']}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col col_5 col_desktop-6 col_tab-8 col_mob-12'>
                  <div className='form-items nowrap'>
                    <div className='form-item w-100'>
                      <div className='form-item__header'>
                        <Tooltip title={'Limits'} info={[t('SpecifyOnTheLeft'), t('SpecifyOnTheRight')]} />
                      </div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            after={'min ' + selectedExchangeAsset}
                            value={limitMin}
                            pattern={'[0-9]+([.][0-9]{1,2})?'}
                            validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                            replaceSymbols={[
                              { from: /,/, to: '.' },
                              { from: /^(00)/, to: '0.0' },
                            ]}
                            inputCallback={(value) => {
                              const roundedValue = GetRoundedValue(value, DigitType.Amount, stageData)
                              const limit = Number(roundedValue) <= Number(limitMax) ? roundedValue : limitMax
                              setLimitMin(limit)
                            }}
                            addClasses={['w-100']}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-item p-0'>
                      <div className='form-item__header'></div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <div className='form-item__ico'>
                            <PostingIcon name='waveIco' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-item w-100'>
                      <div className='form-item__header'></div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            after={'max ' + selectedExchangeAsset}
                            value={limitMax}
                            pattern={'[0-9]+([.][0-9]{1,2})?'}
                            validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                            replaceSymbols={[
                              { from: /,/, to: '.' },
                              { from: /^(00)/, to: '0.0' },
                            ]}
                            inputCallback={(value) => {
                              const roundedValue = GetRoundedValue(value, DigitType.Amount, stageData)
                              const maxLimit =
                                roundedValue > Number(amountOfSelectedExchangeAsset)
                                  ? Number(amountOfSelectedExchangeAsset)
                                  : roundedValue
                              setLimitMax(maxLimit)
                            }}
                            addClasses={['w-100']}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='group'>
            <div className='formular'>
              {isPaymentMethodsSelectionAvailable && (
                <div className='row'>
                  <div className='col col_3 col_tab-4 col_mob-7'>
                    <div className='form-item'>
                      <div className='form-item__header'>
                        <span className='form-item__title'>{t('PaymentMethods')}</span>
                      </div>
                      <div className='form-item__main'>
                        <div className='wysiwyg'>
                          <p>
                            <strong>{t('ChooseUpTo5Ways')}</strong>
                          </p>
                        </div>
                        <div className='form-item__data'>
                          {selectedPaymentRequisites &&
                            selectedPaymentRequisites
                              .filter((method) => method.selected)
                              .map((method) => (
                                <span className='form-item__title' key={method.id}>
                                  {method.paymentSystem.name} ({method.paymentMethodCurrencyCode})
                                  <span
                                    className='payment-method__remove'
                                    onClick={() => handleRemoveMethod(method.id)}
                                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                                  >
                                    <img className='form-item__ico' src={closeImg} alt='✖' />
                                  </span>
                                </span>
                              ))}
                          <button
                            className='btn'
                            disabled={!paymentRequisites}
                            onClick={() => {
                              setIsPaymentMethodsModalOpen(true)
                            }}
                          >
                            {!paymentRequisites ? (
                              <div>{t('Loading')}</div>
                            ) : selectedPaymentRequisites.length === 0 ? (
                              <span className='btn__text'>{t('PaymentMethods')}</span>
                            ) : (
                              <img className='form-item__ico' src={plusImg} alt='+' />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='row'>
                <div className='col col_2 col_tab-4 col_mob-7'>
                  <div className='form-item'>
                    <div className='form-item__header'>
                      <span className='form-item__title'>{t('PaymentTerm')}</span>
                    </div>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Select
                          list={durationList.map((key) => t('P2PMins', { mins: key }))}
                          value={t('P2PMins', { mins: selectedPaymentDuration })}
                          addClasses={['w-100']}
                          filter={true}
                          multiple={false}
                          selectCallback={(value) => {
                            const mins = parseInt(value, 10)
                            if (!isNaN(mins)) {
                              setSelectedPaymentDuration(mins)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPaymentMethodsModalOpen && (
        <SelectPaymentMethodsModal
          opened={isPaymentMethodsModalOpen}
          setOpened={setIsPaymentMethodsModalOpen}
          paymentRequisites={paymentRequisites}
          selectedPaymentRequisites={selectedPaymentRequisites}
          selectedPaymentAsset={selectedPaymentAsset}
          onPaymentMethodChange={setSelectedPaymentRequisites}
          onSaveStageData={() => {
            onSaveStageData()
          }}
        />
      )}
      <CommonModals
        modals={modals}
        closeModal={closeModal}
        openModal={openModal}
        onConfirmCommonModals={(actionType, payload) => {
          onProcessBottomActions(actionType, payload)
        }}
      />
    </React.Fragment>
  )
}
